import { Button, type ButtonProps } from '@virginexperiencedays/components-v2/src/layout/Button';

import { slugToName } from '@virginexperiencedays/search/utils/category';
import { refinementList, priceRangeLabels } from '@virginexperiencedays/search/constants';

import { RatingsFilterLabel } from '../RatingsWidget/RatingsFilterLabel';

type PillProps = { isMain: boolean; label: string; onClick: () => void; attribute: string };

export const Pill = ({ isMain, label, onClick, attribute }: PillProps) => {
  if (isMain) return null;

  const dataTestId = 'refinement-pill';

  if (attribute === refinementList.RATING)
    return (
      <StyledPill data-testid={dataTestId} onClick={onClick} variant="primary">
        <RatingsFilterLabel value={label} />
        <RemoveFacetIcon />
      </StyledPill>
    );

  if (attribute === refinementList.PRICE_RANGE)
    return (
      <StyledPill data-testid={dataTestId} onClick={onClick} variant="primary">
        {priceRangeLabels[label]}
        <RemoveFacetIcon />
      </StyledPill>
    );

  return (
    <StyledPill data-testid={dataTestId} onClick={onClick} variant="primary">
      {slugToName(label)}
      <RemoveFacetIcon />
    </StyledPill>
  );
};

const RemoveFacetIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00476 3.00472C3.13602 2.87349 3.31404 2.79977 3.49966 2.79977C3.68527 2.79977 3.86329 2.87349 3.99455 3.00472L6.99966 6.00982L10.0048 3.00472C10.0693 2.93786 10.1466 2.88453 10.232 2.84785C10.3174 2.81116 10.4092 2.79185 10.5022 2.79104C10.5951 2.79023 10.6873 2.80795 10.7733 2.84314C10.8594 2.87834 10.9375 2.93032 11.0032 2.99604C11.069 3.06177 11.1209 3.13992 11.1561 3.22595C11.1913 3.31198 11.209 3.40415 11.2082 3.4971C11.2074 3.59004 11.1881 3.6819 11.1514 3.7673C11.1147 3.8527 11.0614 3.92994 10.9946 3.99452L7.98945 6.99962L10.9946 10.0047C11.1221 10.1367 11.1926 10.3136 11.191 10.4971C11.1894 10.6806 11.1158 10.8562 10.986 10.986C10.8562 11.1158 10.6807 11.1894 10.4971 11.191C10.3136 11.1926 10.1368 11.122 10.0048 10.9945L6.99966 7.98942L3.99455 10.9945C3.86253 11.122 3.68571 11.1926 3.50217 11.191C3.31864 11.1894 3.14307 11.1158 3.01328 10.986C2.8835 10.8562 2.80988 10.6806 2.80828 10.4971C2.80669 10.3136 2.87724 10.1367 3.00476 10.0047L6.00986 6.99962L3.00476 3.99452C2.87353 3.86325 2.7998 3.68523 2.7998 3.49962C2.7998 3.314 2.87353 3.13599 3.00476 3.00472Z"
        fill="white"
      />
    </svg>
  );
};

const StyledPill = ({ children, ...rest }: ButtonProps) => (
  <Button
    className="flex w-fit items-center gap-2 rounded bg-background-primary p-2 text-white"
    {...rest}
  >
    {children}
  </Button>
);
