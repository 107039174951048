import { cn } from '@virginexperiencedays/tailwind';

import type { HTMLAttributes } from 'react';

export const Divider = ({ className, ...props }: HTMLAttributes<HTMLHRElement>) => (
  <hr
    {...props}
    className={cn('bg-background-neutral-faded my-4 h-2 border-none p-0 md:h-px', className)}
  />
);
