import { refinementList } from '@virginexperiencedays/search/constants';

import { getReviewRangeNumber } from '@virginexperiencedays/components-v2/src/pages/search/filters/SingleSelectMenu/RatingOption';
import { ReviewSummary as StarRatingSummary } from '@virginexperiencedays/components-v2/src/pages/search/StarRatingSummary';
import { IconStar } from '@virginexperiencedays/components-v2/src/icons/IconStar';

import { RefineButton } from '../FacetsDrawer/RefineButton';

export const RatingsFilter = ({ items, onClick }) => (
  <div className="flex flex-col gap-3">
    {items.map(({ value, count }, index) => {
      const dataInsightsFilter = `${refinementList.RATING}:${value}`;
      return (
        <RefineButton
          key={index}
          className="flex cursor-pointer"
          data-testid="rating-filter-option"
          data-insights-filter={dataInsightsFilter}
          onClick={() => onClick(value)}
        >
          <StarRatingSummary
            Icon={({ ...rest }) => (
              <IconStar
                className="h-4 w-4 [&>path]:-translate-x-[2px] [&>path]:-translate-y-[2px]"
                {...rest}
              />
            )}
            averageRating={getReviewRangeNumber(value)}
            count={count}
          />
        </RefineButton>
      );
    })}
  </div>
);
