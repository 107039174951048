import type { MouseEvent } from 'react';
import { useRouter } from 'next/compat/router';

import { categoryTypes } from '@virginexperiencedays/search/constants';

import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import { usePagination } from '../../../../../libs/algolia/hooks/usePagination';
import { isDesktopFilter } from '../../../../../libs/algolia/isDesktopFilter';

import {
  FilterInteraction,
  FilterSource,
  filter as track,
} from '../../../../../libs/tracking/filters';
import { mapAttributeToFilterType } from '../../../../../libs/tracking/mapAttributeToFilterType';
import { mapPathnameToPageType } from '../../../../../libs/tracking/mapPathnameToPageType';

import { DynamicWidgetsFactory } from '../Widgets/DynamicWidgetsFactory';

import { FilterDrawer } from '../FilterOverlay/FilterDrawer';
import { FilterDropdown } from '../FilterOverlay/FilterDropdown';
import { FilterModal } from '../FilterOverlay/FilterModal';
import { Override } from '@virginexperiencedays/categories/src/types';

export const FilterContent = ({
  filterType,
  text,
  filterIndex,
  serverSlug,
  categoryType,
  attribute,
  optionsAction,
  onReset,
  activeCount,
  open,
  setOpen,
  dropdownClassName,
  onOptionClick,
  currentCategoryAttributes,
  overridesSlugInfo,
}: {
  filterType: 'modal-drawer' | 'dropdown-drawer';
  text: string;
  filterIndex: number;
  serverSlug: string[];
  categoryType: categoryTypes;
  attribute: AlgoliaAttribute;
  optionsAction: {
    text: string;
    onClick: () => void;
  };
  onReset: () => void;
  activeCount: number;
  open: boolean;
  setOpen: (id: string, value: boolean) => void;
  dropdownClassName?: string;
  onOptionClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  currentCategoryAttributes: AlgoliaAttribute[];
  overridesSlugInfo: Override['slugInfo'];
}) => {
  const router = useRouter();
  const pathname = router?.pathname;
  const { nbHits: nbOfHits } = usePagination();
  const isLocationWidget = attribute.startsWith('facets.regionHierarchies');
  const trackFilter = ({
    source,
    interaction,
  }: {
    source: FilterSource;
    interaction: FilterInteraction;
  }) => {
    track({
      title: text,
      type: mapAttributeToFilterType(attribute),
      pageType: mapPathnameToPageType(pathname),
      position: filterIndex + 1,
      source,
      interaction,
      qfTotalProductCount: nbOfHits,
    });
  };

  switch (filterType) {
    case 'dropdown-drawer': {
      if (isDesktopFilter()) {
        return (
          <FilterDropdown
            className={dropdownClassName}
            onReset={() => {
              optionsAction.onClick();

              trackFilter({
                source: FilterSource.FilterDropdown,
                interaction: FilterInteraction.ClickReset,
              });
            }}
            resetText={optionsAction.text}
            open={open}
            setOpen={(attribute, open) => {
              setOpen(attribute, open);

              trackFilter({
                source: FilterSource.FilterDropdown,
                interaction: open ? FilterInteraction.ClickFilter : FilterInteraction.FilterClosed,
              });
            }}
            attribute={attribute}
            activeCount={activeCount}
          >
            <DynamicWidgetsFactory
              filterIndex={filterIndex}
              renderType="list"
              text={text}
              serverSlug={serverSlug}
              categoryType={categoryType}
              attribute={attribute}
              min={null}
              onClick={onOptionClick}
              currentCategoryAttributes={currentCategoryAttributes}
              overridesSlugInfo={overridesSlugInfo}
            />
          </FilterDropdown>
        );
      }

      return (
        <FilterDrawer
          text={text}
          open={open}
          setOpen={(attribute, open) => {
            setOpen(attribute, open);

            trackFilter({
              source: FilterSource.FilterDrawer,
              interaction: open ? FilterInteraction.ClickFilter : FilterInteraction.FilterClosed,
            });
          }}
          attribute={attribute}
          activeCount={activeCount}
          onReset={() => {
            onReset();

            trackFilter({
              source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterDrawer,
              interaction: FilterInteraction.ClickReset,
            });
          }}
          onApply={(attribute) => {
            setOpen(attribute, false);

            trackFilter({
              source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterDrawer,
              interaction: FilterInteraction.ClickApplyFilter,
            });
          }}
        >
          <DynamicWidgetsFactory
            filterIndex={filterIndex}
            categoryType={categoryType}
            serverSlug={serverSlug}
            attribute={attribute}
            text={text}
            renderType="pill"
            min={null}
            onClick={onOptionClick}
            currentCategoryAttributes={currentCategoryAttributes}
            overridesSlugInfo={overridesSlugInfo}
          />
        </FilterDrawer>
      );
    }

    case 'modal-drawer': {
      if (isDesktopFilter()) {
        return (
          <FilterModal
            attribute={attribute}
            open={open}
            setOpen={(attribute, open) => {
              setOpen(attribute, open);
              trackFilter({
                source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterModal,
                interaction: open ? FilterInteraction.ClickFilter : FilterInteraction.FilterClosed,
              });
            }}
            text={text}
            resetText={optionsAction.text}
            disabledReset={activeCount === 0}
            onReset={() => {
              optionsAction.onClick();

              trackFilter({
                source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterModal,
                interaction: FilterInteraction.ClickReset,
              });
            }}
            applyText={isLocationWidget ? 'Apply Location' : 'Apply Filter'}
            disabledApply={activeCount === 0}
            onApply={(attribute) => {
              setOpen(attribute, false);
              trackFilter({
                source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterModal,
                interaction: FilterInteraction.ClickApplyFilter,
              });
            }}
          >
            <DynamicWidgetsFactory
              filterIndex={filterIndex}
              categoryType={categoryType}
              serverSlug={serverSlug}
              attribute={attribute}
              text={text}
              renderType="list"
              min={null}
              onClick={onOptionClick}
              currentCategoryAttributes={currentCategoryAttributes}
              overridesSlugInfo={overridesSlugInfo}
            />
          </FilterModal>
        );
      }

      return (
        <FilterDrawer
          open={open}
          setOpen={(attribute, open) => {
            setOpen(attribute, open);
            trackFilter({
              source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterDrawer,
              interaction: open ? FilterInteraction.ClickFilter : FilterInteraction.FilterClosed,
            });
          }}
          text={text}
          attribute={attribute}
          activeCount={activeCount}
          onReset={() => {
            onReset();

            trackFilter({
              source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterDrawer,
              interaction: FilterInteraction.ClickReset,
            });
          }}
          onApply={(attribute) => {
            setOpen(attribute, false);

            trackFilter({
              source: isLocationWidget ? FilterSource.LocationFilter : FilterSource.FilterDrawer,
              interaction: FilterInteraction.ClickApplyFilter,
            });
          }}
        >
          <DynamicWidgetsFactory
            filterIndex={filterIndex}
            categoryType={categoryType}
            serverSlug={serverSlug}
            attribute={attribute}
            text={text}
            renderType="pill"
            min={null}
            onClick={onOptionClick}
            currentCategoryAttributes={currentCategoryAttributes}
            overridesSlugInfo={overridesSlugInfo}
          />
        </FilterDrawer>
      );
    }

    default:
      throw new Error('Invalid filter type');
  }
};
