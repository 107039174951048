import { useRouter } from 'next/compat/router';
import { useEffect } from 'react';
import { useCurrentRefinements, usePagination } from 'react-instantsearch-core';

import { useScrollLock } from '@virginexperiencedays/hooks';

import { categoryTypes } from '@virginexperiencedays/search/constants';

import { useDrawer } from '@virginexperiencedays/components-v2/src/layout/SideDrawer/useDrawer';
import { ListingControls as ListingControlsComponent } from '@virginexperiencedays/components-v2/src/ListingControls';

import { removeQueryStringFromPath } from '../../../utils/routing/paths';

import { sort as trackSorting } from './tracking/interactions';
import { useCategoryPageContext } from './CategoryListingPage/CategoryPageContext';

import { formatRefinements } from './CurrentRefinementsWidget/utils';
import { SortingDrawer } from './SortingDrawer';
import { FacetsDrawer } from './FacetsDrawer';

type ListingControlsProps = {
  categoryType: categoryTypes;
  hasResults?: boolean;
  numberOfHits?: number;
};

export const ListingControls = ({
  categoryType,
  hasResults = true,
  numberOfHits,
}: ListingControlsProps) => {
  const isSRP = categoryType === categoryTypes.SEARCH;
  // Facets Drawer
  const { isOpen: isFacetsDrawerOpen, toggleDrawer: toggleFacetsDrawer } = useDrawer();
  // Sorting Drawer
  const { isOpen: isSortingDrawerOpen, toggleDrawer: toggleSortingDrawer } = useDrawer();
  // Current Refinements
  const { items: currentRefinements } = useCurrentRefinements({
    excludedAttributes: [],
  });
  // Pagination
  const { nbHits } = usePagination();
  // Scroll Lock
  const { unlockScroll, lockScroll } = useScrollLock();
  // SEO Overrides
  const { seoOverridesUrl } = useCategoryPageContext();
  const router = useRouter();

  const path = removeQueryStringFromPath(router?.asPath, ' / ');
  const filterCount = formatRefinements(currentRefinements).length;
  const nbOfActiveFilters = isSRP ? filterCount : filterCount - 1;

  useEffect(() => {
    if (isFacetsDrawerOpen) {
      toggleFacetsDrawer();
    }

    if (isSortingDrawerOpen) {
      toggleSortingDrawer();
    }
  }, [router?.asPath]);

  useEffect(() => {
    isFacetsDrawerOpen || isSortingDrawerOpen ? lockScroll() : unlockScroll();
    // Make sure to clear the hook itself, once it gets unmounted
    return () => {
      unlockScroll();
    };
  }, [isFacetsDrawerOpen, isSortingDrawerOpen, lockScroll, unlockScroll]);

  return (
    <div className={hasResults ? 'block' : 'hidden'}>
      <ListingControlsComponent
        productCount={numberOfHits ?? nbHits}
        filterCount={seoOverridesUrl ? 0 : nbOfActiveFilters} // don't show active filters once user is on SEO Override, its purely just a landing page and we're taking user away from it to regular PLP on any action taken
        handleFilterClick={toggleFacetsDrawer}
        handleSortByClick={toggleSortingDrawer}
      />
      <FacetsDrawer
        categoryType={categoryType}
        currentRefinements={currentRefinements}
        isFacetsDrawerOpen={isFacetsDrawerOpen}
        toggleFacetsDrawer={toggleFacetsDrawer}
      />
      <SortingDrawer
        isSortingDrawerOpen={isSortingDrawerOpen}
        toggleSortingDrawer={toggleSortingDrawer}
        onSubmit={(label: string) => trackSorting({ slug: path, label })}
        categoryType={categoryType}
      />
    </div>
  );
};
