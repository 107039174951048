//@TODO: this should be ideally defined per app
export const sortOptions = [
  {
    value: 'most-popular',
    label: 'Most Popular',
  },
  {
    value: 'price-asc',
    label: 'Price (low to high)',
  },
  {
    value: 'price-desc',
    label: 'Price (high to low)',
  },
  {
    value: 'newest',
    label: 'Newest',
  },
  {
    value: 'rating',
    label: 'Rating',
  },
  {
    value: 'most-reviewed',
    label: 'Most Reviewed',
  },
];

export const displayOptions = [
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'detailed',
    label: 'Detailed',
  },
];

export const resultsPerPageOptions = [
  {
    value: 40,
    label: '40',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 120,
    label: '120',
  },
];

export const defaultOptionGroups = [
  {
    title: 'Sort',
    key: 'sortBy',
    options: sortOptions,
    checkedValue: sortOptions[0].value,
  },
  {
    title: 'Display',
    key: 'display',
    options: displayOptions,
    checkedValue: displayOptions[0].value,
  },
  {
    title: 'Results Per Page',
    key: 'perPage',
    options: resultsPerPageOptions,
    checkedValue: resultsPerPageOptions[0].value,
  },
];
