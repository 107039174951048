/*
 * Wrapper around original usePagination from instantsearch, with swapped logic for `refine` and `createURL` methods
 */
import { useRouter } from 'next/compat/router';
import { usePagination as risUsePagination, type UsePaginationProps } from 'react-instantsearch';

import { categoryPrefixes } from '@virginexperiencedays/search/constants';

import { queryToString } from '../queryToString';
import { isQueryFaceted } from '../isQueryFaceted';
import { removeParams } from '../../../utils/routing/removeParams';

export const usePagination = (
  props: UsePaginationProps = {
    padding: 3,
  }
) => {
  const { refine: risRefine, ...rest } = risUsePagination(props);
  const router = useRouter();

  const refine = (value: Parameters<typeof risRefine>[0]) => {
    const query = router.query;
    const nextQuery = { ...query, page: `${value}` };
    const isFirstPage = value === 1;
    const queryString = decodeURIComponent(queryToString(nextQuery, [isFirstPage ? 'page' : '']));

    return router.push(
      {
        pathname: createPathname(router.pathname, isFirstPage, removeParams(nextQuery, ['page'])),
        query: {
          ...nextQuery,
          page: isFirstPage ? null : value,
        },
      },
      router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
      {
        shallow: true,
      }
    );
  };

  // This method needs to support server environment
  const createURL = (page: number, fallbackPath: string): string => {
    // fallback path to support SSR
    // router.asPath on the server sometimes just returns path based on the fs, ie. `/c/driving` but in the browser it will just show as /driving - UGLY
    const pathSegments = typeof router?.asPath === 'string' ? router.asPath.split('?') : '';
    const asPath = pathSegments.length ? pathSegments[0] : fallbackPath;
    const stablePath = cleanPath(asPath);

    const queryString = queryToString({ ...(router?.query || {}), page: `${page}` }, [
      page === 1 ? 'page' : '',
    ]);
    const query = queryString ? `?${queryString}` : '';
    const url = `${stablePath}${query}`;

    return url;
  };

  return {
    ...rest,
    refine,
    createURL,
  };
};

/**
 * Cleans the given path by removing specific segments.
 *
 * This function performs the following transformations on the input path:
 * 1. Removes category prefixes defined in `categoryPrefixes`.
 * 2. Removes the `/plp/` segment.
 * 3. Removes pagination segments in the form of `/page/[page]/`.
 *
 * @param path - The input path to be cleaned.
 * @returns The cleaned path string with specified segments removed.
 */
export const cleanPath = (path: string): string => {
  // match category prefixes
  let newPath = path.replace(
    new RegExp(`/(${Object.values(categoryPrefixes).join('|')})/`, 'i'),
    '/'
  );

  // match and remove PLP - /plp/
  newPath = newPath.replace(/\/plp\//i, '/');

  // match and remove pagination - /page/[page]/
  newPath = newPath.replace(/\/page\/\d+\//i, '/');

  return newPath;
};

/**
 * Creates a pathname string based on the provided parameters.
 * The function works only with singular slugs e.g /c/driving, but not with
 * multiple slugs e.g /c/driving/supercars as it's not supported by the current routing.
 *
 * @param pathname - The original pathname string.
 * @param isFirstPage - A boolean indicating if the current page is the first page.
 * @param query - An object representing the query parameters.
 * @returns The constructed pathname string.
 */
export const createPathname = (
  pathname: string,
  isFirstPage: boolean,
  query: Record<string, string | string[]>
): string => {
  const pathParts = pathname.split('/');
  const isSearch = pathParts?.[1] === 'search';
  const isOrShouldBePLP = pathParts?.includes('plp') || (!isSearch && isQueryFaceted(query));

  const paginationSegment = isFirstPage || isOrShouldBePLP || isSearch ? '' : 'page/[page]';
  const PLPSegment = isOrShouldBePLP ? 'plp' : '';
  const slugSegment = isSearch ? '' : '[...slug]';

  return `/${[pathParts[1], PLPSegment, paginationSegment, slugSegment].filter(Boolean).join('/')}`;
};
