import { useRouter } from 'next/compat/router';
import { useState } from 'react';
import type {
  HierarchicalMenuItem,
  HierarchicalMenuRenderState,
} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';

import { categoryPrefixes, categoryTypes } from '@virginexperiencedays/search/constants';
import { slugToName } from '@virginexperiencedays/search/utils/category';
import { RouteParams } from '@virginexperiencedays/search/types';

import { useRouterPush } from '../../../../utils/routing/useRouterPush';
import { removeQueryStringFromPath } from '../../../../utils/routing/paths';
import { getQueryParamAttribute } from '../../../../utils/algolia/getQueryParamAttribute';

import { RefineButton, LabelText, NumberOfItems } from '../FacetsDrawer/RefineButton';
import { useCategoryPageContext } from '../CategoryListingPage/CategoryPageContext';
import { ShowMoreButton } from '../FacetsDrawer/ShowMoreButton';
import { filter as track } from '../tracking/interactions';

type HierarchicalMenuProps = {
  title: string;
  data: HierarchicalMenuItem[] | null;
  isMaster?: boolean;
  /**
   * this property is used to distinguish whether the hierarchical menu should push value as query or slug
   * */
  isMain?: boolean;
  queryParam?: keyof RouteParams;
  categoryType: categoryTypes;
  trackingLabel: string;
  sendEvent: HierarchicalMenuRenderState['sendEvent'];
};

export const HierarchicalMenu = ({
  title,
  data,
  isMaster = false,
  isMain,
  queryParam,
  categoryType,
  trackingLabel,
  sendEvent,
}: HierarchicalMenuProps) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const { refine } = useRouterPush();
  const router = useRouter();
  const { seoOverridesUrl } = useCategoryPageContext();

  const slug = removeQueryStringFromPath(router?.asPath, ' / ');

  const handleClick = ({
    showMore,
    facetName,
    facetValue,
  }: {
    showMore: boolean;
    facetName: string;
    facetValue: string;
  }) => {
    showMore && setIsShowMore(false);

    track({ slug, label: trackingLabel });
    sendEvent('click', facetValue, `[INSIGHTS] ${facetName} clicked`);
  };

  const handleData = () => {
    if (isShowMore && data.length > 5) {
      return data;
    } else {
      return data.slice(0, 5);
    }
  };
  // This function handles cases where we're on /f, /o etc. and click on primary category, eg. driving
  // The purpose of this function is to use it only for master category
  const masterCategoryPush = (categoryType: categoryTypes, value: string): void => {
    if (isMain && isMaster) {
      refine({
        slug: value,
        shallow: false,
        // we pass category prefix manually to support legacy URLs where there is no prefix in the pathname, otherwise it tries to grab it from router.asPath
        prefix: categoryPrefixes.CATEGORY,
      });
    } else {
      // If we're on /o /f pages and we click on master category, we want to take current slug and hand it over as query param
      // That's why we're grabbing it here from the router directl
      //  Filter out PLP or category categoryPrefixes from slugs coming in router
      //  check if the actual action is taken on Overrides if so utilise seooverrides url pathname instead
      const normalizedSlugs = Array.isArray(router.query.slug)
        ? router.query.slug
        : router.query.slug.split('/');

      const routerSlugs = normalizedSlugs.filter(
        (slug) =>
          !Object.values(categoryPrefixes).includes(slug as categoryPrefixes) && slug !== 'plp'
      );

      const overrideSlugs = seoOverridesUrl
        ? new URL(seoOverridesUrl).pathname
            .split('/')
            .filter(
              (slug) =>
                slug &&
                !Object.values(categoryPrefixes).includes(slug as categoryPrefixes) &&
                slug !== 'plp'
            )
        : null;

      const slugs = overrideSlugs ?? routerSlugs;

      refine({
        prefix: categoryPrefixes.CATEGORY,
        query: {
          [categoryType]: slugs.join('/'),
        },
        slug: value,
        shallow: false,
      });
    }
  };
  return (
    <div data-testid="hierarchical-menu">
      {data && (
        <>
          <div className="flex flex-col gap-6" data-testid="menu-links">
            {handleData().map(({ count, value, label }) => {
              const labels = label.split('/');
              const attribute = getQueryParamAttribute(isMaster ? 'category' : queryParam);
              const dataInsightsFilter = `${attribute}:${value}`;

              // Master category
              if (isMaster) {
                return (
                  <RefineButton
                    key={`${count}/${value}`}
                    data-testid="menu-item"
                    data-insights-filter={dataInsightsFilter}
                    onClick={() => {
                      handleClick({
                        showMore: isShowMore,
                        facetName: label,
                        facetValue: value,
                      });
                      masterCategoryPush(categoryType, value);
                    }}
                  >
                    <LabelText>{slugToName(labels[labels.length - 1])}</LabelText>
                    <NumberOfItems count={count} />
                  </RefineButton>
                );
              }
              // facet / main category
              return (
                <RefineButton
                  key={`${count}/${value}`}
                  data-testid="menu-item"
                  data-insights-filter={dataInsightsFilter}
                  onClick={() => {
                    handleClick({ showMore: isShowMore, facetName: label, facetValue: value });
                    if (isMain) {
                      refine({
                        slug: value,
                        shallow: false,
                      });
                      return;
                    }
                    refine({
                      query: {
                        [queryParam]: value,
                      },
                    });
                  }}
                >
                  <LabelText>{slugToName(labels[labels.length - 1])}</LabelText>
                  <NumberOfItems count={count} />
                </RefineButton>
              );
            })}
          </div>
          {data.length > 5 && (
            <ShowMoreButton
              onClick={() => setIsShowMore(!isShowMore)}
              isShowMore={isShowMore}
              title={title}
            />
          )}
        </>
      )}
    </div>
  );
};
