import { type CSSProperties, type HTMLAttributes, useCallback } from 'react';

import { cn } from '@virginexperiencedays/tailwind';
import { defaultGiftCardImage } from '@virginexperiencedays/cms/constants';

import type { ProductCardProps } from '@virginexperiencedays/components-v2/src/cards/Product';
import { Image } from '@virginexperiencedays/components-v2/src/layout/Image';
import { LinkWrap } from '@virginexperiencedays/components-v2/src/navigation/LinkWrap';

import { addToDataLayer } from '../../../tracking/gtm/utils';

import { getColumnWidth } from './utils/getColumnWidth';
import { BREAKPOINTS, BREAKPOINTS_TO_FILL, Breakpoint, BreakpointToFill } from './types';
import { ProductGridGiftCard as ProductCard, useIsProductCardsV2 } from '../ProductCard';

type OffsetValue = number;

type SharedProps = {
  offsetValue: OffsetValue;
  isMinimal: boolean;
  productsLength: number;
  featuredProductsAlreadyInGrid?: number;
};

type GiftCardProps = {
  offsets: { desktop: OffsetValue; mobile: OffsetValue };
  lastSlug: string;
  titleClassName?: string;
  /** Flag whether to prefetch the link */
  prefetch?: boolean;
} & Omit<SharedProps, 'offsetValue'>;

/**
 * A component that renders out the gift card filler in embedded product grids.
 *
 * The widthToFill for each screen size will be calculated in the css and styling will be allocated accordingly.
 *
 * If the widthToFill equates to a value between 2 and 4, the Gift card filler banner will be displayed, otherwise a value of 1 will instead display a hardcoded gift card product.
 *
 * The decision to hardcode this product has been done with hesitation, however all alternatives seem to add complexity that isn't worth the value we're looking to get out of this.
 *
 * @prop offsets An object containing offset counts for both desktop and mobile views.
 * @prop isMinimal A boolean that specifies whether the grid display is standard (minimal) or detailed.
 * @prop productsLength Product data length, used for various css grid calculations.
 */

export const GiftCard = ({
  offsets,
  isMinimal,
  productsLength,
  featuredProductsAlreadyInGrid,
  lastSlug,
  titleClassName,
  prefetch,
}: GiftCardProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const handleTracking = useCallback(
    () =>
      addToDataLayer({
        event: 'userInteraction',
        eventCategory: 'Featured Product',
        eventAction: `Clicked Gift Card | ${lastSlug}`,
        eventLabel: 'Gift Card',
      }),
    [lastSlug]
  );

  const { src, alt, height, width } = defaultGiftCardImage;

  return (
    <>
      <GiftCardWrapper
        offsets={offsets}
        isMinimal={isMinimal}
        productsLength={productsLength}
        data-testid="gift-card-wrapper"
      >
        <div
          style={
            {
              '--matrix': 'matrix(0.88, -0.47, -0.47, -0.88, 0, 0)',
            } as CSSProperties
          }
          className={cn(
            'relative flex w-full justify-between overflow-hidden rounded-[10px] bg-background-primary-faded p-[4%]',
            'before:absolute before:-left-[13%] before:top-[49%] before:h-[144%] before:w-3/5 before:-rotate-[13.51deg] before:rounded-[155px] before:bg-background-primary-faded-hover before:opacity-40',
            'after:absolute after:-right-[19%] after:-top-[90%] after:h-[120%] after:w-3/5 after:rounded-[98px] after:bg-background-primary-faded-hover after:opacity-40 after:[transform:var(--matrix)]'
          )}
        >
          <CardShape
            isMinimal={isMinimal}
            productsLength={productsLength}
            offsetValue={offsets.desktop}
          >
            <Image src={src} alt={alt} height={height} width={width} />
          </CardShape>
          <ContentWrapper
            isMinimal={isMinimal}
            productsLength={productsLength}
            offsetValue={offsets.desktop}
          >
            <p className="my-1 text-[22px] font-bold leading-7 text-neutral-strong">
              The world of experiences in one little card
            </p>
            <GiftCardBodyWrapper
              isMinimal={isMinimal}
              productsLength={productsLength}
              offsetValue={offsets.desktop}
            >
              <GiftCardBody
                isMinimal={isMinimal}
                productsLength={productsLength}
                offsetValue={offsets.desktop}
              >
                There’s an experience to suit all tastes and occasion. And all wrapped in a single
                gift card to let them choose!
              </GiftCardBody>
              <GiftCardBody
                inverse
                isMinimal={isMinimal}
                productsLength={productsLength}
                offsetValue={offsets.desktop}
              >
                There’s an experience to suit all tastes and occasion.
              </GiftCardBody>
            </GiftCardBodyWrapper>
          </ContentWrapper>
          <LinkWrap
            className="[whitespace-nowrap] mb-2.5 mr-2.5 select-none self-end rounded border-2 border-transparent bg-white px-5 py-3 text-center align-middle font-semibold text-link shadow-lg"
            href="/gift-cards"
            onClick={handleTracking}
          >
            Shop Now
          </LinkWrap>
        </div>
      </GiftCardWrapper>
      <ProductGiftCard
        contentClassName={cn('gap-1', isMinimal ? 'p-0' : 'px-2 pb-2 md:p-0')}
        titleClassName={
          isProductCardsV2 ? titleClassName : 'text-sm leading-normal font-[500] md:font-[500]'
        }
        isMinimal={isMinimal}
        productsLength={productsLength}
        featuredProductsAlreadyInGrid={featuredProductsAlreadyInGrid}
        offsetValue={offsets.desktop}
        title="Gift Card £100"
        slug="gift-card-100-"
        src="https://vedcdn.imgix.net/images/product/main/virgin-experience-days-gift-15123106.jpg"
        displayPrice={100}
        sku="PECRD4"
        onClick={handleTracking}
        productPromo={null}
        prefetch={prefetch}
      />
    </>
  );
};

type ContentWrapperProps = SharedProps & HTMLAttributes<HTMLDivElement>;

const ContentWrapper = ({
  children,
  isMinimal,
  productsLength,
  offsetValue,
  ...rest
}: ContentWrapperProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const values = BREAKPOINTS_TO_FILL.map((breakpoint) => {
    const value = getWidthToFillByBreakpoint(
      breakpoint,
      isMinimal,
      productsLength,
      offsetValue,
      isProductCardsV2
    );
    switch (value) {
      case 2:
        return contentVariants[breakpoint][2];
      case 3:
        return contentVariants[breakpoint][3];
      case 4:
        return contentVariants[breakpoint][4];

      default:
        return null;
    }
  });

  return (
    <div className={cn('z-[1] flex h-full w-2/5 flex-col justify-center', values)} {...rest}>
      {children}
    </div>
  );
};

type GiftCardBodyWrapperProps = SharedProps & HTMLAttributes<HTMLDivElement>;

const GiftCardBodyWrapper = ({
  children,
  isMinimal,
  productsLength,
  offsetValue,
  ...rest
}: GiftCardBodyWrapperProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const values = BREAKPOINTS_TO_FILL.map((breakpoint) => {
    if (
      getWidthToFillByBreakpoint(
        breakpoint,
        isMinimal,
        productsLength,
        offsetValue,
        isProductCardsV2
      ) === 2
    ) {
      return bodyVariants[breakpoint][2];
    }

    return bodyVariants[breakpoint][3];
  });

  return (
    <div className={cn('mt-1 hidden text-neutral-strong', values)} {...rest}>
      {children}
    </div>
  );
};

type GiftCardBodyProps = {
  // Visibilty is inversed block / hidden
  inverse?: boolean;
} & SharedProps &
  HTMLAttributes<HTMLParagraphElement>;

const GiftCardBody = ({
  children,
  isMinimal,
  productsLength,
  offsetValue,
  inverse = false,
  ...rest
}: GiftCardBodyProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const values = BREAKPOINTS_TO_FILL.map((breakpoint) => {
    switch (
      getWidthToFillByBreakpoint(
        breakpoint,
        isMinimal,
        productsLength,
        offsetValue,
        isProductCardsV2
      )
    ) {
      case 4:
        return inverse ? bodyVariants[breakpoint][2] : bodyVariants[breakpoint][3];
      default:
        return inverse ? bodyVariants[breakpoint][3] : bodyVariants[breakpoint][2];
    }
  });

  return (
    <p className={cn('m-0 text-lg leading-normal', values)} {...rest}>
      {children}
    </p>
  );
};

type CardShapeProps = SharedProps & HTMLAttributes<HTMLDivElement>;

const CardShape = ({
  children,
  isMinimal,
  productsLength,
  offsetValue,
  ...rest
}: CardShapeProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const values = BREAKPOINTS_TO_FILL.map((breakpoint) => {
    const value = getWidthToFillByBreakpoint(
      breakpoint,
      isMinimal,
      productsLength,
      offsetValue,
      isProductCardsV2
    );
    switch (value) {
      case 2:
        return cardShapeVariants[breakpoint][2];
      case 3:
        return cardShapeVariants[breakpoint][3];
      case 4:
        return cardShapeVariants[breakpoint][4];

      default:
        return null;
    }
  });

  return (
    <div
      className={cn(
        'aspect-[3/2] w-[30%] -rotate-[5.96deg] self-center rounded-[24px] bg-background-primary',
        values
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

type ProductGiftCardProps = ProductCardProps & SharedProps;

const ProductGiftCard = ({
  isMinimal,
  offsetValue,
  productsLength,
  featuredProductsAlreadyInGrid,
  titleClassName,
  prefetch,
  ...rest
}: ProductGiftCardProps) => {
  const isProductCardsV2 = useIsProductCardsV2();

  const values = BREAKPOINTS_TO_FILL.map((breakpoint) => {
    const value = getWidthToFillByBreakpoint(
      breakpoint,
      isMinimal,
      productsLength,
      offsetValue,
      isProductCardsV2
    );
    if (value === 1) {
      return productCardStyle[breakpoint][1];
    }

    return productCardStyle[breakpoint][2];
  });

  /**
   * Featured products are shown in full width.
   * In the old view, all products are shown in one full column / list view.
   * With the new mobile two column view, we need to show the gift card filler
   * if the number of featured products that is part of the grid is odd number
   */
  const hasMobileTwoColumnFiller = isProductCardsV2 && featuredProductsAlreadyInGrid % 2 !== 0;

  return (
    <ProductCard
      className={cn(hasMobileTwoColumnFiller ? 'flex' : 'hidden', values)}
      titleClassName={titleClassName}
      item={rest as ProductCardProps}
      prefetch={prefetch}
      index={productsLength + offsetValue - 1}
    />
  );
};

type GiftCardWrapperProps = { offsets: GiftCardProps['offsets'] } & Omit<
  SharedProps,
  'offsetValue'
> &
  HTMLAttributes<HTMLLIElement>;

const GiftCardWrapper = ({
  children,
  isMinimal,
  productsLength,
  offsets,
  ...rest
}: GiftCardWrapperProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const getOffset = (breakpoint: Breakpoint): OffsetValue =>
    ['mobile', 'largeMobile'].includes(breakpoint) ? offsets.mobile : offsets.desktop;

  const values = BREAKPOINTS.map((breakpoint: BreakpointToFill | 'mobile') => {
    const columnWidth = getColumnWidth(breakpoint, isMinimal, isProductCardsV2) as number;
    const offset = getOffset(breakpoint);

    const widthToFill = getWidthToFillByBreakpoint(
      breakpoint as BreakpointToFill,
      isMinimal,
      productsLength,
      offset,
      isProductCardsV2
    );

    if ((offset + productsLength) % columnWidth === 0 || widthToFill === 1) {
      return wrapperStyles[breakpoint].hide;
    }

    return wrapperStyles[breakpoint].show;
  });

  const variables = Object.fromEntries(
    BREAKPOINTS.map((breakpoint) => [
      `--${breakpoint}-lastPosition`,
      `${getLastPosition(
        getColumnWidth(breakpoint, isMinimal, isProductCardsV2) as number,
        productsLength,
        getOffset(breakpoint)
      )}`,
    ])
  );

  // it has to be li as its rendered as part of grid
  return (
    <li
      style={variables as unknown as CSSProperties}
      className={cn('col-end-[-1] hidden', values)}
      {...rest}
    >
      {children}
    </li>
  );
};

/* Move them if they are ever needed in another file */
const cardShapeVariants = {
  largeMobile: {
    2: 'sm:w-1/2 sm:top-[10%] sm:left-[45%] sm:absolute sm:z-[1]',
    3: 'sm:w-[44%] sm:top-0 sm:-left-[42px] sm:relative',
    4: 'sm:w-[30%] sm:relative sm:left-[14px]',
  },

  tablet: {
    2: 'md:w-1/2 md:top-[10%] md:left-[45%] md:absolute md:z-[1]',
    3: 'md:w-[44%] md:top-0 md:-left-[42px] md:relative',
    4: 'md:w-[30%] md:relative md:left-[14px]',
  },

  desktop: {
    2: 'lg:w-1/2 lg:top-[10%] lg:left-[45%] lg:absolute lg:z-[1]',
    3: 'lg:w-[44%] lg:top-0 lg:-left-[42px] lg:relative',
    4: 'lg:w-[30%] lg:relative lg:left-[14px]',
  },

  wide: {
    2: 'xl:w-1/2 xl:top-[10%] xl:left-[45%] xl:absolute xl:z-[1]',
    3: 'xl:w-[44%] xl:top-0 xl:-left-[42px] xl:relative',
    4: 'xl:w-[30%] xl:relative xl:left-[14px]',
  },
};

const bodyVariants = {
  largeMobile: {
    2: 'sm:hidden',
    3: 'sm:block',
  },
  tablet: {
    2: 'md:hidden',
    3: 'md:block',
  },
  desktop: {
    2: 'lg:hidden',
    3: 'lg:block',
  },

  wide: {
    2: 'xl:hidden',
    3: 'xl:block',
  },
};

const contentVariants = {
  largeMobile: {
    2: 'sm:justify-end sm:w-1/2 sm:pb-1 sm:pl-4',
    3: 'sm:justify-center sm:pr-3.5',
    4: 'sm:justify-center sm:relative sm:-right-[10px]',
  },
  tablet: {
    2: 'md:justify-end md:w-1/2 md:pb-1 md:pl-4',
    3: 'md:justify-center md:pr-3.5',
    4: 'md:justify-center md:relative md:-right-[10px]',
  },
  desktop: {
    2: 'lg:justify-end lg:w-1/2 lg:pb-1 lg:pl-4',
    3: 'lg:justify-center lg:pr-3.5',
    4: 'lg:justify-center lg:relative lg:-right-[10px]',
  },
  wide: {
    2: 'xl:justify-end xl:w-1/2 xl:pb-1 xl:pl-4',
    3: 'xl:justify-center xl:pr-3.5',
    4: 'xl:justify-center xl:relative xl:-right-[10px]',
  },
};

const productCardStyle = {
  largeMobile: {
    1: 'sm:flex',
    2: 'sm:hidden',
  },
  tablet: {
    1: 'md:flex',
    2: 'md:hidden',
  },
  desktop: {
    1: 'lg:flex',
    2: 'lg:hidden',
  },

  wide: {
    1: 'xl:flex',
    2: 'xl:hidden',
  },
};

const wrapperStyles = {
  mobile: { show: 'flex col-start-[--mobile-lastPosition]', hide: 'hidden' },
  largeMobile: { show: 'sm:flex sm:col-start-[--largeMobile-lastPosition]', hide: 'sm:hidden' },
  tablet: { show: 'md:flex md:col-start-[--tablet-lastPosition]', hide: 'md:hidden' },
  desktop: { show: 'lg:flex lg:col-start-[--desktop-lastPosition]', hide: 'lg:hidden' },
  wide: { show: 'xl:flex xl:col-start-[--wide-lastPosition]', hide: 'xl:hidden' },
};

const getWidthToFill = (total: number, modulus: number) =>
  Math.ceil(total / modulus) * modulus - total;

const getWidthToFillByBreakpoint = (
  breakpoint: BreakpointToFill,
  isMinimal: SharedProps['isMinimal'],
  productsLength: SharedProps['productsLength'],
  offsetValue: OffsetValue,
  isProductCardsV2: boolean
) => {
  const columnWidth = getColumnWidth(breakpoint, isMinimal, isProductCardsV2) as number;
  const totalLength = productsLength + offsetValue;
  return getWidthToFill(totalLength, columnWidth);
};

// returned position is 1-based as it is used in css property grid-column-start
const getLastPosition = (columnWidth: number, gridLength: number, offsetValue: OffsetValue) => {
  const totalLength = gridLength + offsetValue;
  const widthToFill = getWidthToFill(totalLength, columnWidth);
  return columnWidth - widthToFill + 1;
};
