/*
 * converts next router query object into string
 * @returns string
 */
export function queryToString(query: Record<string, string | string[]>, paramsToRemove = ['page']) {
  return Object.entries(query)
    .reduce((acc, [key, value]) => {
      if (key !== 'slug' && value != null && value.length > 0 && !paramsToRemove.includes(key)) {
        const queryString = Array.isArray(value) ? `${key}=${value.join(',')}` : `${key}=${value}`;
        acc.push(queryString);
      }

      return acc;
    }, [])
    .join('&');
}
