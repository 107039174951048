// Core
import { useEffect, useState } from 'react';
import { useRouter } from 'next/compat/router';

// Search
import { categoryPrefixes, categoryTypes, facets } from '@virginexperiencedays/search/constants';
import type { RouteParams } from '@virginexperiencedays/search/types';

// Internal
import { removeParams, removeSearchParams, removeUndefinedParams } from '../removeParams';
import { useCategoryPageContext } from '../../../components/pages/search/CategoryListingPage/CategoryPageContext';
import { getAsPathnameValue } from './getAsPathnameValue';
import { getShallowValue } from './getShallowValue';
import { getPathnameValue } from './getPathnameValue';

/**
 *
 * Utility to generalise router.push behaviour for pages that are using algolia, in order to properly notify and forward these to next router.
 *
 */

export type RefineOpts = {
  query?: Partial<RouteParams>;
  shallow?: boolean;
  slug?: string;
  prefix?: string;
  paramsToRemove?: string[];
  slugRemoved?: boolean;
  // It's for future prefix compatibility
};

export const useRouterPush = () => {
  const router = useRouter();
  const [isReady, setReady] = useState(false);
  const { seoOverridesUrl, categoryType } = useCategoryPageContext();
  // Calling use effect to make sure hook rerenders whenever router gets ready
  useEffect(() => {
    if (router?.isReady) {
      setReady(true);
    }
  }, [router?.isReady]);

  /**
   * This method should be used on CSR for user events only. Primary of it is to attach queries whilst respecting the current URL
   * We can control whether we want to call getStaticProps or not via shallow
   * We can pass a slug if we want this method to look into a different subpath under the same category
   * There should be always either slug or query passed
   */
  const refine = ({
    query,
    shallow = true,
    slug,
    prefix,
    paramsToRemove = [],
    slugRemoved = false,
  }: RefineOpts) => {
    if (isReady) {
      // do nothing if no query or slug is passed
      if ((!query || !Object.keys(query).length) && !paramsToRemove?.length && !slug) {
        return;
      }

      const allParamsToRemove: RefineOpts['paramsToRemove'] = paramsToRemove;
      const isSRP = router?.asPath.startsWith('/search');
      const isPaginated = !!router?.query?.page;

      // Define different behaviour for SRP
      if (isSRP) {
        router.push(
          {
            pathname: '/search',
            query: {
              ...removeParams(router.query, ['page', ...allParamsToRemove]),
              ...query,
            },
          },
          undefined,
          {
            shallow,
          }
        );

        return;
      }

      const isNewURL = getPathSegments(router?.asPath).some((part) =>
        Object.values(categoryPrefixes).some((prefix) => prefix === part)
      );
      const isPLP = facets.some(
        (facet) => Object.keys(router.query).includes(facet) && facet !== 'page'
      );
      const routerSlugs = Array.isArray(router?.query.slug)
        ? router.query.slug
        : router.query.slug.split('/');

      // SEO overrides
      const overrideURL = seoOverridesUrl ? new URL(seoOverridesUrl) : null;
      const overrideSlugs = overrideURL
        ? getPathSegments(overrideURL.pathname).slice(1) // cut prefix
        : null;

      // cut current category prefix
      const routerPrefix = getPathSegments(router?.pathname)[0];
      const currentPrefix = getPrefix(
        prefix as categoryPrefixes,
        routerPrefix as categoryPrefixes,
        categoryType
      );

      const slugs = slug?.split('/') ?? overrideSlugs ?? routerSlugs;
      const finalQuery = createQueryObj({ query, url: seoOverridesUrl });

      const urlObj = {
        pathname: getPathnameValue({
          isPLP,
          slugToPush: slug,
          slugs,
          currentPrefix: currentPrefix as categoryPrefixes,
          query: finalQuery,
        }),
        query: {
          ...removeParams(router.query, ['page', ...allParamsToRemove]),
          ...removeParams(finalQuery, ['page', ...allParamsToRemove]),
          slug: slugs,
        },
      };

      // This object defines what's visible in the user browser (URL)
      const asObj = {
        pathname: getAsPathnameValue({
          slugToPush: slug,
          slugs,
          query: finalQuery,
          currentPrefix: currentPrefix as categoryPrefixes,
          isNewURL,
          isPLP,
        }),
        // always ensure variation is removed from asURL object (split-testing) -- this is whats visible in the browser, not how next router talks underneath the hood
        query: {
          ...removeParams(router?.query, ['variation', 'page', 'slug', ...allParamsToRemove]),
          ...removeParams(finalQuery ?? {}, ['variation', 'page', 'slug']),
        },
      };

      const opts = {
        shallow: getShallowValue({
          isPaginated,
          slugRemoved,
          paramsToRemove: allParamsToRemove,
          isPLP,
          seoOverridesUrl,
          defaultValue: shallow,
          slugToPush: slug,
        }),
      };

      router.push(
        opts.shallow
          ? {
              query: urlObj.query,
            }
          : urlObj,
        asObj,
        opts
      );
      return;
    }

    return;
  };

  /**
   * This util creates next URL based on given args, respecting current query string
   * @param slug - Note slug should start with `/`
   * @param page - to handle pagination links
   * @param fallback - Used on SSR before the hook gets loaded into the browser
   * @returns string starting with `/`
   */

  const createURL = ({
    slug,
    page,
    queryToPush,
    fallback,
  }: {
    slug?: string;
    page?: number;
    queryToPush?: Record<string, string>;
    fallback: string;
  }): string => {
    if (isReady) {
      const filteredQuery = Object.entries(router?.query).reduce((acc, [key, value]) => {
        if (key !== 'page' && key !== 'slug') {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {});

      const query = {
        ...filteredQuery,
        ...queryToPush,
        slug: undefined, // we won't pass the slug, as its defined via pathname
        page: page && page !== 1 ? page : undefined,
      };
      const queryParams = removeUndefinedParams(query);
      const hasQueryParams = Object.entries(queryParams).length !== 0;

      if (!slug && !hasQueryParams) {
        // router.asPath always starts with "/"
        return removeSearchParams(router.asPath);
      }

      if (!slug && hasQueryParams) {
        const searchParams = new URLSearchParams(queryParams);
        searchParams?.delete('variation');
        const queryURL = searchParams.toString();

        const path = removeSearchParams(router.asPath);
        // router.asPath always starts with "/", no need to normalize

        if (!queryURL) return path;
        return `${path}?${queryURL}`;
      }

      if (slug && hasQueryParams) {
        const searchParams = new URLSearchParams(queryParams);
        searchParams?.delete('variation');
        const queryURL = searchParams.toString();

        const normalizedPath = slug.startsWith('/') ? `${slug}` : `/${slug}`;

        if (!queryURL) return normalizedPath;
        return `${normalizedPath}?${queryURL}`;
      }

      // only slug passed
      return slug.startsWith('/') ? slug : `/${slug}`;
    }
    // Fallback for SSR (initial load when browser JS is not loaded yet)
    return fallback;
  };

  return {
    refine,
    createURL,
  };
};

function getPrefix(
  prefix: categoryPrefixes,
  routerPrefix: categoryPrefixes,
  categoryType: categoryTypes
): string {
  if (prefix) {
    return prefix;
  }

  if (routerPrefix === 'cor') {
    switch (categoryType) {
      case categoryTypes.CATEGORY:
        return categoryPrefixes.CATEGORY;

      case categoryTypes.OCCASION:
        return categoryPrefixes.OCCASION;
    }
  }

  return routerPrefix;
}

const createQueryObj = ({ query, url }) => {
  const queryObj = {
    ...query,
  };
  const urlObj = url ? new URL(url) : null;

  // hella ugly
  facets.forEach((facet) => {
    /**
     * Don't clobber the query provided by the consumer!
     *
     * E.g. if we're on /cor/driving?location=east-midland/derbyshire/derby but
     * the consumer has dictated location=east-midland/derbyshire, assume that
     * this was user-intended via CurrentRefinements.
     */
    if (urlObj?.searchParams.has(facet) && !query?.[facet]) {
      queryObj[facet] = urlObj.searchParams.get(facet);
    }
  });
  return queryObj;
};

function getPathSegments(path: string) {
  return path.split('/').filter((part) => part);
}
