import { categoryPrefixes, facets } from '@virginexperiencedays/search/constants';
import { RouteParams } from '@virginexperiencedays/search/types';

// This logic should be extended to handle isOverride case aswell
export function getPathnameValue({
  currentPrefix,
  slugs,
  slugToPush,
  isPLP,
  query,
}: {
  slugToPush: string;
  slugs: string[];
  currentPrefix: categoryPrefixes;
  isPLP: boolean;
  query: Partial<RouteParams>;
}): string {
  // If:
  // - slug will be passed when calling refine method and we're on PLP, it means we wanna jump between into subcategory plp
  // - query contains facets it means we wanna go to PLP
  if (
    (query && facets.some((facet) => Object.keys(query).includes(facet) && facet !== 'page')) ||
    (isPLP && slugs.some((slug) => slug !== slugToPush))
  ) {
    return `/${currentPrefix}/plp/[...slug]`;
  }
  return `/${currentPrefix}/[...slug]`;
}
