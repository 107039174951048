import type { RenderOptions } from 'instantsearch.js/es/types';

type Geoposition = {
  lat: number;
  lng: number;
};
const getGeoposition = (aroundLatLng: string): Geoposition => {
  const [lat, lng] = aroundLatLng.split(',');
  return { lat: Number(lat), lng: Number(lng) };
};

/**
 * This is a minimal Geosearch widget connector, patterned after the
 * connectGeoSearch connector from InstantSearch.js. The crucial difference is
 * that this supports radius searching using aroundLatLng and aroundRadius.
 *
 * {@see https://github.com/algolia/instantsearch.js/blob/master/packages/instantsearch.js/src/connectors/geo-search/connectGeoSearch.ts}
 * {@see https://www.algolia.com/doc/api-reference/search-api-parameters/}
 *
 * Only the parts we absolutely need are implemented.
 */
export const connectRadiusGeoSearch = (renderFn) => {
  return (widgetParams?: any) => {
    return {
      // mimic the $$type from connectGeoSearch
      $$type: 'ais.geoSearch',
      /**
       * init, render, and getRenderState are defined in a very similar way to
       * connectGeoSearch, and are in charge of registering our geoSearch key
       * into uiState, as well as provide state for each render.
       */
      init(initArgs) {
        renderFn({
          ...this.getWidgetRenderState(initArgs),
          instantSearchInstance: initArgs.instantSearchInstance,
        });
      },
      render(renderArgs) {
        renderFn({
          ...this.getWidgetRenderState(renderArgs),
          instantSearchInstance: renderArgs.instantSearchInstance,
        });
      },
      getRenderState(renderState, renderOptions) {
        return {
          ...renderState,
          geoSearch: this.getWidgetRenderState(renderOptions),
        };
      },

      /**
       * getWidgetRenderState is ultimately what defines the return value of the
       * hook for each render:
       *
       * const returnValue = useGeoSearch();
       *       ^
       *       `-- this return value
       *
       * Our widget render state is much more sparse compared to
       * connectGeoSearch, as we only really need a few keys.
       */
      getWidgetRenderState({ helper: { state }, results }: RenderOptions) {
        return {
          items: results?.hits?.filter((hit) => hit._geoloc) ?? [],
          currentRefinement: state.aroundLatLng && getGeoposition(state.aroundLatLng),
          widgetParams,
        };
      },

      /**
       * getWidgetSearchParameters translates our uiState.geoSearch keys into
       * the actual query parameters sent to the Algolia API.
       */
      getWidgetSearchParameters(searchParameters, { uiState }) {
        if (!uiState?.geoSearch) {
          // remove all query parameters
          return searchParameters
            .setQueryParameter('getRankingInfo', undefined)
            .setQueryParameter('aroundLatLng', undefined)
            .setQueryParameter('aroundRadius', undefined)
            .setQueryParameter('enableReRanking', undefined)
            .setQueryParameter('enableRules', undefined);
        }

        return (
          searchParameters
            // getRankingInfo allows each hit to receive their distance from the center
            .setQueryParameter('getRankingInfo', 'true')
            // disable arbitrary re-ordering and pinning when geoSearch params are defined
            // to make sure only products within the area are returned
            .setQueryParameter('enableReRanking', 'false')
            .setQueryParameter('enableRules', 'false')
            // aroundLatLng defines the center of the search
            .setQueryParameter('aroundLatLng', uiState.geoSearch.aroundLatLng)
            // aroundRadius defines a maximum radius
            .setQueryParameter('aroundRadius', uiState.geoSearch.aroundRadius)
        );
      },

      /**
       * getWidgetUiState does the inverse of {@link getWidgetSearchParameters}:
       * it translates Algolia API query parameters to the corresponding
       * uiState.
       */
      getWidgetUiState(uiState, { searchParameters }) {
        const aroundLatLng = searchParameters.aroundLatLng;
        const aroundRadius = searchParameters.aroundRadius;

        if (!aroundLatLng) return uiState;

        return {
          ...uiState,
          geoSearch: { aroundLatLng, aroundRadius },
        };
      },

      /**
       * dispose will clean up the query parameters used, and is called when the
       * connector is unmounted (or the entire Instantsearch app is destroyed).
       */
      dispose({ state }) {
        return state
          .setQueryParameter('aroundLatLng', undefined)
          .setQueryParameter('aroundRadius', undefined)
          .setQueryParameter('enableReRanking', undefined);
      },
    };
  };
};
