export const getEmbedWidth = (columnStart: number, columnEnd: number, columnWidth: number) => {
  const min = Math.min(columnStart, columnWidth);

  const start = !min ? 1 : min;

  // Case: no columnEnd
  if (!columnEnd) return { start, end: 'auto' };
  // Case: max width
  if (columnEnd === -1) return { start, end: columnEnd };
  return {
    start,
    end: Math.min(columnEnd, columnWidth) + 1,
  };
};
