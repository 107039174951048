import { type InputHTMLAttributes, type ChangeEvent, useState } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { DisplayHeading } from '../../typography/DisplayHeading';

export type RadioOption = {
  value: string | number;
  label: string | number;
};

export type RadioProps = {
  title: string;
  options: RadioOption[];
  checked?: string | number;
  disabled?: boolean;
  /**
   * function to determine whether an option is disabled
   */
  getDisabled?: (value: string | number) => boolean;
  onChange: (value: string | number) => void;
  className?: string;
};

export const Radio = ({
  className,
  title,
  options,
  checked,
  disabled = false,
  getDisabled,
  onChange,
}: RadioProps) => {
  const [selected, setSelected] = useState<RadioProps['checked']>(checked);

  const handleSelectedChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelected(value);
    onChange(value);
  };

  return (
    <div className={className}>
      <DisplayHeading className="text-neutral-strong mb-4" size="4">
        {title}
      </DisplayHeading>
      <div>
        {options.map(({ value, label }, index) => {
          const isChecked = selected === value;
          const isDisabled = disabled || getDisabled?.(value);
          return (
            <label
              className="relative mt-2 flex cursor-pointer items-center py-2 text-base leading-none first:mt-0 sm:py-1 md:text-sm md:leading-none"
              key={value}
            >
              <StyledRadio
                className="mr-2"
                checked={isChecked}
                disabled={isDisabled}
                data-testid={`radio-${index}`}
                value={value}
                onChange={handleSelectedChange}
              />
              <span className={cn('text-neutral flex-1', isDisabled && 'text-grey-200')}>
                {label}
              </span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export const StyledRadio = ({
  className,
  checked,
  disabled,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => (
  <>
    <input
      {...props}
      className="absolute left-0 top-0 z-0 h-full w-full cursor-pointer opacity-0"
      type="radio"
      disabled={disabled}
      checked={checked}
      readOnly
    />
    <div
      className={cn(
        'border-neutral relative h-6 w-6 rounded-full border border-solid transition-colors',
        'md:h-4 md:w-4',
        'after:absolute after:inset-0 after:h-6 after:w-6 after:-translate-x-px after:-translate-y-px after:scale-[0.65] after:rounded-full after:transition-colors',
        'md:after:h-4 md:after:w-4 md:after:scale-[0.6]',
        checked && 'border-border-primary after:bg-background-primary',
        disabled && 'border-border-neutral cursor-not-allowed opacity-50',
        disabled && checked && 'after:bg-background-disabled',
        className
      )}
    />
  </>
);
