import { Divider } from '@virginexperiencedays/components-v2/src/pages/search/filters/Divider';
import { Heading } from '@virginexperiencedays/components-v2/src/pages/search/filters/Heading';

import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';

import { categoryTypes } from '@virginexperiencedays/search/constants';

import { useRouter } from 'next/compat/router';
import { useGeoSearch } from '../../../../../libs/algolia/hooks/useGeoSearch';
import { usePagination } from '../../../../../libs/algolia/hooks/usePagination';
import { useCurrentRefinements } from '../../../../../libs/algolia/hooks/useCurrentRefinements';

import {
  filter as track,
  FilterInteraction,
  FilterSource,
} from '../../../../../libs/tracking/filters';
import { mapAttributeToFilterType } from '../../../../../libs/tracking/mapAttributeToFilterType';
import { mapPathnameToPageType } from '../../../../../libs/tracking/mapPathnameToPageType';

import { MultiSelectWidget } from './MultiselectWidget';
import { isDesktopFilter } from '../../../../../libs/algolia/isDesktopFilter';
import { GeoSearchFilter } from '../../GeoSearchWidget/GeoSearchFilter';
import { useRefinementList } from '../../../../../libs/algolia/hooks/useRefinementList';
import { useCallback } from 'react';
import {
  filterItemLabels,
  sortItemLabels,
  transformLabels,
} from '../../../../../libs/algolia/transformLabels';
import { OptionBadge } from '@virginexperiencedays/components-v2/src/layout/Options';
import type { Override } from '@virginexperiencedays/categories/src/types';

type FilterLocationsProps = {
  currentCategoryAttributes: AlgoliaAttribute[];
  className?: string;
  label?: string;
  min?: number;
  attribute: AlgoliaAttribute;
  serverSlug: string[];
  categoryType: categoryTypes;
  renderType: 'pill' | 'list';
  filterIndex: number;
  onClick?: () => void;
  attributeActiveCount?: number;
  overridesSlugInfo: Override['slugInfo'];
};

// Location widget with the location search box and location options
export const LocationWidget = ({
  className,
  label = 'Regions',
  min = 5,
  attribute,
  serverSlug,
  categoryType,
  renderType,
  filterIndex,
  attributeActiveCount,
  currentCategoryAttributes,
  onClick,
  overridesSlugInfo,
}: FilterLocationsProps) => {
  const router = useRouter();
  const pathname = router?.pathname;
  const hasGeoSearch = !!router?.query?.geoSearchFormatted && !!router?.query?.geoSearch;
  const { nbHits: nbOfHits } = usePagination();
  const { refine } = useGeoSearch();
  const { refine: clearRefinement } = useCurrentRefinements();

  const { items } = useRefinementList({
    attribute,
    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);

      if (
        categoryType !== categoryTypes.LOCATION &&
        !overridesSlugInfo?.some(({ type }) => type === categoryTypes.LOCATION)
      ) {
        return transformLabels(sortedItems, attribute);
      }

      const filtered = filterItemLabels(sortedItems, attribute, serverSlug, overridesSlugInfo);
      return transformLabels(filtered, attribute);
    }, []),
  });

  return (
    <div className={className}>
      <GeoSearchFilter
        isSearchIconNew
        onClearLocation={() => {
          clearRefinement({ attribute: 'geoSearch' });

          track({
            title: label,
            type: mapAttributeToFilterType('geoSearch'),
            pageType: mapPathnameToPageType(pathname),
            position: filterIndex + 1,
            source: FilterSource.LocationFilter,
            interaction: FilterInteraction.LocationSearchClear,
            qfTotalProductCount: nbOfHits,
          });

          onClick?.();
        }}
        onOptionSelect={({ geoSearch, geoSearchFormatted, interactionType }) => {
          refine({
            geoSearch,
            geoSearchFormatted,
          });

          track({
            title: label,
            type: mapAttributeToFilterType('geoSearch'),
            pageType: mapPathnameToPageType(pathname),
            position: filterIndex + 1,
            source: isDesktopFilter() ? FilterSource.FilterDropdown : FilterSource.FilterDrawer,
            interaction: interactionType,
            qfTotalProductCount: nbOfHits,
          });

          onClick?.();
        }}
      />

      {!currentCategoryAttributes.includes(attribute) && !!items.length && (
        <>
          <Divider className="md:h-2" />
          <Heading className="flex gap-2">
            Regions
            {attributeActiveCount > 0 && !hasGeoSearch && (
              <OptionBadge checked>{attributeActiveCount}</OptionBadge>
            )}
          </Heading>
          <MultiSelectWidget
            overridesSlugInfo={overridesSlugInfo}
            className="[&>ul]:grid-cols-2"
            filterIndex={filterIndex}
            renderType={renderType}
            attribute={attribute}
            serverSlug={serverSlug}
            categoryType={categoryType}
            widgetCategoryType={categoryTypes.LOCATION}
            label="Regions"
            showIcon={false}
            min={min}
            onClick={onClick}
          />
        </>
      )}
    </div>
  );
};
