import { refinementList } from '@virginexperiencedays/search/constants';
import { AlgoliaAttribute } from '../algolia/stateToRoute';
import { FilterType } from './filters';

export const mapAttributeToFilterType = (
  attribute: refinementList | AlgoliaAttribute
): FilterType => {
  switch (attribute) {
    case refinementList.CATEGORY:
    case refinementList.OCCASION:
    case refinementList.FEATURE:
    case refinementList.PRICE_RANGE:
    case 'facets.categoryHierarchies.lvl0':
    case 'facets.categoryHierarchies.lvl1':
    case 'facets.categoryHierarchies.lvl2':
    case 'facets.occasionHierarchies.lvl0':
    case 'facets.occasionHierarchies.lvl1':
    case 'facets.occasionHierarchies.lvl2':
    case 'facets.featureHierarchies.lvl0':
    case 'facets.featureHierarchies.lvl1':
    case 'facets.featureHierarchies.lvl2':
    case 'facets.regionHierarchies.lvl0':
    case 'facets.regionHierarchies.lvl1':
    case 'facets.regionHierarchies.lvl2':
    case refinementList.LOCATION:
      return FilterType.MultiSelect;
    case refinementList.RATING:
      return FilterType.SingleSelect;
    case 'geoSearch':
      return FilterType.LocationSearch;

    default:
      throw new Error(`Unknown attribute: ${attribute}`);
  }
};
