import { useRouter } from 'next/compat/router';
import {
  useCurrentRefinements as risUseCurrentRefinements,
  type UseCurrentRefinementsProps,
} from 'react-instantsearch';
import { removeParams } from '../../../utils/routing/removeParams';
import { queryToString } from '../queryToString';
import { STATE_TO_ROUTE } from '../stateToRoute';
import { isQueryFaceted } from '../isQueryFaceted';

// Object that has attribute key  at least (normally full refinement should be given but its fine for our case)
type ClearRefinementType = Record<string, unknown> & {
  attribute: keyof typeof STATE_TO_ROUTE;
};

/*
 * Wrapper around original useCurrentRefinements from instantsearch, with swapped logic for `refine` method
 */
export const useCurrentRefinements = (props: UseCurrentRefinementsProps = {}) => {
  const api = risUseCurrentRefinements(props);
  const router = useRouter();
  const pathname = router?.pathname;
  const isSRP = pathname?.includes('search');
  const isPLP = pathname?.includes('/plp/');

  const refine = (value: ClearRefinementType, clearAll = false) => {
    if (clearAll) {
      const nextQuery = {
        slug: router.query?.slug,
        ...(isSRP && { query: router?.query.query }),
      };

      const queryString = decodeURIComponent(queryToString(nextQuery));

      router.push(
        {
          query: {
            ...nextQuery,
            page: 1,
          },
        },
        router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
        {
          shallow: isSRP || !isPLP,
        }
      );

      return;
    }

    const param = STATE_TO_ROUTE[value.attribute];

    const nextQuery = removeParams(router.query, [
      'page',
      'sort',
      param,
      param === 'geoSearch' ? 'geoSearchFormatted' : '',
    ]);

    const isNextQueryFaceted = isQueryFaceted(nextQuery);
    const queryString = decodeURIComponent(queryToString(nextQuery));

    router.push(
      {
        query: {
          ...nextQuery,
          page: 1,
        },
      },
      router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
      {
        shallow: isPLP ? isNextQueryFaceted : true,
      }
    );
  };

  // order matters, override refine with our own
  return {
    ...api,
    refine,
  };
};
