import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';

type CTAProps = { onClick: () => void; nbHits: number };

export const ViewProductsCTA = ({ onClick, nbHits }: CTAProps) => {
  if (nbHits) {
    const productText = nbHits > 1 ? 'Products' : 'Product';

    return (
      <Button
        className="mt-6 flex w-full items-center justify-center whitespace-nowrap rounded-sm bg-background-primary py-4 text-white"
        onClick={onClick}
        variant="primary"
      >
        View {nbHits} {productText}
      </Button>
    );
  }
  return null;
};
