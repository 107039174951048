import { ValidQueryParams } from './types';

export type AlgoliaAttribute =
  | 'facets.categoryHierarchies.lvl0'
  | 'facets.categoryHierarchies.lvl1'
  | 'facets.categoryHierarchies.lvl2'
  | 'facets.occasionHierarchies.lvl0'
  | 'facets.occasionHierarchies.lvl1'
  | 'facets.occasionHierarchies.lvl2'
  | 'facets.featureHierarchies.lvl0'
  | 'facets.featureHierarchies.lvl1'
  | 'facets.featureHierarchies.lvl2'
  | 'facets.regionHierarchies.lvl0'
  | 'facets.regionHierarchies.lvl1'
  | 'facets.regionHierarchies.lvl2'
  | 'facets.priceRange'
  | 'facets.reviewRanges'
  | 'geoSearch'
  | 'sortBy';

/*
 * This dictionary helps to distingiush what attribute should look over what query param
 * It is used exclusively in refine method for each hook we made a wrapper for
 * For example see `useRefinementList` in `hooks/useRefinementList`
 */

export const STATE_TO_ROUTE: Record<AlgoliaAttribute, keyof ValidQueryParams> = {
  'facets.categoryHierarchies.lvl0': 'category',
  'facets.categoryHierarchies.lvl1': 'category',
  'facets.categoryHierarchies.lvl2': 'category',
  'facets.occasionHierarchies.lvl0': 'occasion',
  'facets.occasionHierarchies.lvl1': 'occasion',
  'facets.occasionHierarchies.lvl2': 'occasion',
  'facets.featureHierarchies.lvl0': 'feature',
  'facets.featureHierarchies.lvl1': 'feature',
  'facets.featureHierarchies.lvl2': 'feature',
  'facets.regionHierarchies.lvl0': 'location',
  'facets.regionHierarchies.lvl1': 'location',
  'facets.regionHierarchies.lvl2': 'location',
  'facets.priceRange': 'price',
  'facets.reviewRanges': 'rating',
  geoSearch: 'geoSearch',
  sortBy: 'sort',
};
