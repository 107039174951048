import { useState, useCallback, type MouseEvent } from 'react';
import { cn } from '@virginexperiencedays/tailwind';
import { useRouter } from 'next/compat/router';

import {
  OptionPillItem,
  OptionListItem,
  OptionText,
  OptionBadge,
  Options,
  OptionsGroup,
  OptionToggle,
} from '@virginexperiencedays/components-v2/src/layout/Options';
import RenderIcon from '@virginexperiencedays/header-2024/src/components/common/icons/RenderIcon';
import { useMenu } from '../../../../../libs/algolia/hooks/useMenu';
import { categoryTypes, refinementList } from '@virginexperiencedays/search/constants';
import { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import {
  filter as track,
  FilterInteraction,
  FilterSource,
} from '../../../../../libs/tracking/filters';
import { mapAttributeToFilterType } from '../../../../../libs/tracking/mapAttributeToFilterType';
import { mapPathnameToPageType } from '../../../../../libs/tracking/mapPathnameToPageType';
import { usePagination } from '../../../../../libs/algolia/hooks/usePagination';
import { isDesktopFilter } from '../../../../../libs/algolia/isDesktopFilter';
import { MenuItem } from '@virginexperiencedays/search/types';
import {
  filterItemLabels,
  sortItemLabels,
  transformLabels,
} from '../../../../../libs/algolia/transformLabels';
import type { Override } from '@virginexperiencedays/categories/src/types';

export type OptionProps = {
  value: string;
  label: string;
  isRefined?: boolean;
  count?: number;
  icon?: string;
};

type SingleSelectWidgetProps = {
  attribute: AlgoliaAttribute;
  categoryType: categoryTypes;
  widgetCategoryType: categoryTypes;
  serverSlug: string[];
  label: string;
  renderType: 'pill' | 'list';
  filterIndex: number;
  showIcon?: boolean;
  min?: number;
  listItemIcon?: string;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  overridesSlugInfo: Override['slugInfo'];
};

// Single select widget that renders the option in pills or radio via isListItems flag
export const SingleSelectWidget = ({
  attribute,
  categoryType,
  widgetCategoryType,
  serverSlug,
  label,
  showIcon = true,
  min = 5,
  renderType,
  filterIndex,
  listItemIcon,
  className,
  onClick,
  overridesSlugInfo,
}: SingleSelectWidgetProps) => {
  const router = useRouter();
  const pathname = router?.pathname;
  const [expanded, setExpanded] = useState<boolean>(false);
  const { nbHits: nbOfHits } = usePagination();
  const { refine, items } = useMenu({
    attribute,
    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);
      const filtered = filterItemLabels(sortedItems, attribute, serverSlug, overridesSlugInfo);

      if (categoryType !== widgetCategoryType) {
        if (attribute === refinementList.RATING) {
          return transformLabels(filtered, attribute);
        }

        return transformLabels(sortedItems, attribute);
      }

      return transformLabels(filtered, attribute);
    }, []),
  });

  const handleExpandCollapse = () => {
    setExpanded((prev) => !prev);

    track({
      title: label,
      type: mapAttributeToFilterType(attribute),
      pageType: mapPathnameToPageType(pathname),
      position: filterIndex + 1,
      source: isDesktopFilter() ? FilterSource.FilterDropdown : FilterSource.FilterDrawer,
      interaction: FilterInteraction.ClickedSeeMore,
      qfTotalProductCount: nbOfHits,
    });
  };

  if (!items?.length) return null;

  const showExpandCollapseToggle = !!min && !expanded && items.length > min;

  return (
    <Options className={cn('gap-0', className)}>
      <OptionsGroup isColumn={renderType === 'list'}>
        {items.map((item, index) => (
          <li key={item.value}>
            <SingleSelectOptionsItem
              item={item}
              className={!expanded && !!min && index >= min && 'hidden'}
              onClick={({ e, value, checked }) => {
                refine(value);

                track({
                  title: label,
                  type: mapAttributeToFilterType(attribute),
                  pageType: mapPathnameToPageType(pathname),
                  position: filterIndex + 1,
                  source: isDesktopFilter()
                    ? FilterSource.FilterDropdown
                    : FilterSource.FilterDrawer,
                  interaction: checked
                    ? FilterInteraction.DeselectFilterOption
                    : FilterInteraction.SelectFilterOption,
                  qfTotalProductCount: nbOfHits,
                  optionNumberOfProducts: item.count,
                  optionPosition: index + 1,
                  optionTitle: item.label,
                });

                onClick?.(e);
              }}
              showIcon={showIcon}
              renderType={renderType}
              icon={listItemIcon}
            />
          </li>
        ))}
      </OptionsGroup>

      {showExpandCollapseToggle && (
        <OptionToggle
          className="py-4 md:py-2"
          onClick={handleExpandCollapse}
          aria-expanded={expanded}
        >
          See more {label}
        </OptionToggle>
      )}
    </Options>
  );
};

type SingleSelectItemClickHandler = {
  e: MouseEvent<HTMLButtonElement>;
  value: string;
  checked: boolean;
};

const SingleSelectOptionsItem = ({
  item,
  className,
  onClick,
  showIcon,
  renderType,
  icon,
}: {
  item: MenuItem;
  className?: string;
  onClick?: ({ e, value, checked }: SingleSelectItemClickHandler) => void;
  showIcon?: boolean;
  renderType: 'pill' | 'list';
  icon?: string;
}) => {
  const { value, label, count, isRefined: checked } = item;
  const Component = renderType === 'list' ? OptionListItem : OptionPillItem;
  return (
    <Component
      key={value}
      checked={checked}
      className={className}
      onClick={(e) => {
        onClick?.({ e, value, checked });
      }}
      aria-pressed={checked}
      aria-label={`${label}, ${count || 0} items`}
      title={label}
      {...(renderType === 'list' && { isSingleSelect: true })}
    >
      {icon && showIcon && (
        <RenderIcon
          className={cn('h-4 w-4 min-w-4', checked ? 'stroke-brand' : 'stroke-neutral')}
          shadedColor={checked ? 'var(--text-brand)' : 'var(--text-neutral)'} // for star icon
          name={icon ?? 'photo'}
        />
      )}
      <OptionText>{label}</OptionText>
      {!!count && <OptionBadge checked={checked}>{count || 0}</OptionBadge>}
    </Component>
  );
};
