import type { IconProps as Props } from './types';

export const IconFilter = ({
  className,
  title = 'Filter Icon',
  color = 'var(--text-neutral-strong)',
  ...rest
}: Props) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <title>{title}</title>
      <path d="M7.99967 3.00065V1.33398M7.99967 3.00065C7.55765 3.00065 7.13372 3.17625 6.82116 3.48881C6.5086 3.80137 6.33301 4.22529 6.33301 4.66732C6.33301 5.10934 6.5086 5.53327 6.82116 5.84583C7.13372 6.15839 7.55765 6.33398 7.99967 6.33398M7.99967 3.00065C8.4417 3.00065 8.86562 3.17625 9.17818 3.48881C9.49075 3.80137 9.66634 4.22529 9.66634 4.66732C9.66634 5.10934 9.49075 5.53327 9.17818 5.84583C8.86562 6.15839 8.4417 6.33398 7.99967 6.33398M7.99967 6.33398V14.6673M2.99967 13.0007C3.4417 13.0007 3.86563 12.8251 4.17819 12.5125C4.49075 12.1999 4.66634 11.776 4.66634 11.334C4.66634 10.892 4.49075 10.468 4.17819 10.1555C3.86563 9.84291 3.4417 9.66732 2.99967 9.66732M2.99967 13.0007C2.55765 13.0007 2.13372 12.8251 1.82116 12.5125C1.5086 12.1999 1.33301 11.776 1.33301 11.334C1.33301 10.892 1.5086 10.468 1.82116 10.1555C2.13372 9.84291 2.55765 9.66732 2.99967 9.66732M2.99967 13.0007V14.6673M2.99967 9.66732V1.33398M12.9997 13.0007C13.4417 13.0007 13.8656 12.8251 14.1782 12.5125C14.4907 12.1999 14.6663 11.776 14.6663 11.334C14.6663 10.892 14.4907 10.468 14.1782 10.1555C13.8656 9.84291 13.4417 9.66732 12.9997 9.66732M12.9997 13.0007C12.5576 13.0007 12.1337 12.8251 11.8212 12.5125C11.5086 12.1999 11.333 11.776 11.333 11.334C11.333 10.892 11.5086 10.468 11.8212 10.1555C12.1337 9.84291 12.5576 9.66732 12.9997 9.66732M12.9997 13.0007V14.6673M12.9997 9.66732V1.33398" />
    </svg>
  );
};
