import { categoryPrefixes } from '@virginexperiencedays/search/constants';

export function getLastPathSegment(url: string) {
  //Fallback when client side url is null (on server / initially / for SEO)
  const strippedPathnames = stripCategoryPrefix(url);

  return strippedPathnames[strippedPathnames.length - 1];
}

export function stripCategoryPrefix(url: string) {
  const urlObject = new URL(url);
  const pathnames = urlObject.pathname.split('/').filter((parts) => parts);
  return pathnames.filter(
    (part) => !Object.values(categoryPrefixes).some((prefix) => part === prefix)
  );
}

// Transform the path by removing the query string from each part, and join the parts into a single string
export function removeQueryStringFromPath(
  path: string | undefined,
  delimiter = '/'
): string | undefined {
  if (!path) return undefined;

  return path.split('/').reduce((acc, part) => {
    if (part === 'plp' || Object.values(categoryPrefixes).some((prefix) => part === prefix)) {
      return acc;
    }

    if (part?.length) {
      return acc.length ? `${acc}${delimiter}${part.split('?')[0]}` : `${part.split('?')[0]}`;
    }
    return acc;
  }, '');
}
