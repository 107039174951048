import { useRouter } from 'next/compat/router';
import { useMenu as risUseMenu, type UseMenuProps } from 'react-instantsearch';
import { queryToString } from '../queryToString';
import { STATE_TO_ROUTE } from '../stateToRoute';

/*
 * Wrapper around original useMenu from instantsearch, with swapped logic for `refine` method
 */
export const useMenu = ({
  attribute,
  ...restProps
}: {
  attribute: keyof typeof STATE_TO_ROUTE;
} & UseMenuProps) => {
  const { refine: risRefine, ...rest } = risUseMenu({
    attribute,
    ...restProps,
  });
  const router = useRouter();

  const refine = (value: Parameters<typeof risRefine>[0]) => {
    const query = router.query;
    const param = STATE_TO_ROUTE[attribute];
    const valueAtParam = query[param];

    const nextQuery = {
      ...query,
      [param]: valueAtParam === value ? null : value,
    };

    const queryString = decodeURIComponent(queryToString(nextQuery));

    router.push(
      {
        query: {
          ...nextQuery,
          page: 1, // do not set to 0 to prevent crashing on paginated clp (page/[page]/[...slug] ) - if page null this will crash -- non visible to the user anyway
        },
      },
      router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
      {
        shallow: true,
        scroll: false,
      }
    );
  };

  const createURL = () => {
    throw new Error('Not implemented');
  };

  return {
    ...rest,
    refine,
    createURL,
  };
};
