import type { CurrentRefinementsConnectorParamsItem } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';

import type { Refinement } from '@virginexperiencedays/search/types';
import { categorySeparator, indexNames } from '@virginexperiencedays/search/constants';

/**
 * This function formats the Algolia current refinement array, separating hierarchical refinements for use in rendering the refinement pills.
 * @param initialRefs Algolia current refinements array
 * @returns A formatted array, with hierarchical refinements extracted into individual objects and unnecessary data removed.
 */

export const formatRefinements = (initialRefs: CurrentRefinementsConnectorParamsItem[]) => {
  const refs: Refinement[] = [];

  initialRefs.forEach((obj) => {
    if (obj.indexName === indexNames.QUERY_SUGGESTIONS) return;

    const { value, attribute } = obj.refinements[0];

    const hierarchyLvl = typeof value === 'string' && value.split(categorySeparator);

    hierarchyLvl.forEach((lvl: string, i: number) =>
      refs.push({
        label: lvl,
        value: hierarchyLvl.slice(0, i + 1).join('/'),
        attribute,
      })
    );
  });
  return refs;
};
