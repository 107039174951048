import { cn } from '@virginexperiencedays/tailwind';

import { IconCheckPlain } from '../../icons/IconCheck';

import type { InputHTMLAttributes } from 'react';

export const StyledCheckbox = ({
  className,
  checked,
  disabled,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => (
  <>
    <input
      {...props}
      className="absolute left-0 top-0 z-0 h-full w-full cursor-pointer opacity-0"
      type="checkbox"
      disabled={disabled}
      checked={checked}
      readOnly
    />
    <div
      className={cn(
        'border-neutral relative h-6 w-6 rounded-sm border border-solid transition-colors md:h-4 md:w-4',
        checked && 'border-primary-700 bg-background-primary',
        disabled && 'border-border-neutral cursor-not-allowed opacity-50',
        disabled && checked && 'bg-background-disabled',
        className
      )}
    >
      {checked && <IconCheckPlain className="m-1 h-3.5 w-3.5 md:m-0.5 md:h-3 md:w-2.5" />}
    </div>
  </>
);
