import {
  categoriesHierarchyAttributeArray,
  categoryTypes,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  occasionsHierarchyAttributeArray,
  refinementList,
} from '@virginexperiencedays/search/constants';
import type { AlgoliaAttribute } from './stateToRoute';
import type { Override } from '@virginexperiencedays/categories/src/types';

export const getCurrentCategoryAlgoliaAttribute = (
  categoryType: categoryTypes,
  serverSlug: string[],
  overridesSlugInfo: Override['slugInfo']
): AlgoliaAttribute[] | null => {
  if (overridesSlugInfo) {
    // if override, then both current category type and seo override will be part of slug info
    return overridesSlugInfo.map((override) => {
      return getCurrentAttributeLvl(override.type as categoryTypes, override.hierarchy.length - 1);
    });
  }
  return [getCurrentAttributeLvl(categoryType, serverSlug.length - 1)];
};

const getCurrentAttributeLvl = (categoryType: categoryTypes, currentLvl: number) => {
  switch (categoryType) {
    case categoryTypes.CATEGORY:
      return categoriesHierarchyAttributeArray[currentLvl] as AlgoliaAttribute;

    case categoryTypes.OCCASION:
      return occasionsHierarchyAttributeArray[currentLvl] as AlgoliaAttribute;

    case categoryTypes.FEATURE:
      return featuresHierarchyAttributeArray[currentLvl] as AlgoliaAttribute;

    case categoryTypes.LOCATION:
      return locationsHierarchyAttributeArray[currentLvl] as AlgoliaAttribute;

    case categoryTypes.RATING:
      return refinementList.RATING;

    case categoryTypes.PRICE_RANGE:
      return refinementList.PRICE_RANGE;

    case categoryTypes.SEARCH:
      return null;

    default:
      throw new Error(`Category type ${categoryType} is not supported`);
  }
};
