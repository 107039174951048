import { cn } from '@virginexperiencedays/tailwind';
import { IconFilter } from '@virginexperiencedays/components-v2/src/icons/IconFilter';
import { ReactNode } from 'react';
import { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import { FilterPill } from '../FilterTriggers/FilterPill';

type AllFiltersProps = {
  children: ReactNode;
  activeCount: number;
  onClick: (attribute: AlgoliaAttribute) => void;
  onMouseEnter: (attribute: AlgoliaAttribute) => void;
  onMouseLeave: (attribute: AlgoliaAttribute) => void;
  iconClass?: string;
};

export const AllFilters = ({ children, iconClass, ...rest }: AllFiltersProps) => {
  return (
    <FilterPill
      className="px-3 xl:px-2"
      textClassName="hidden xl:flex dark:md:group-hover/pill:text-brand"
      attribute={'all_filters' as any}
      text="All Filters"
      icon={
        <IconFilter
          height="16"
          className={cn(
            'text-neutral dark:md:group-hover/pill:text-persistent-text-dark',
            iconClass
          )}
          stroke="currentColor"
        />
      }
      hasArrow={false}
      {...rest}
    >
      {children}
    </FilterPill>
  );
};
