import { useWindowWidth } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';
import type { RichTextField } from '@prismicio/types';

import { PROMO_BANNER_MASK_IMAGE_MOBILE } from '../../constants/images';
import { IconAngleRight } from '../../icons/IconAngleRight';
import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { DisplayHeading } from '../../typography/DisplayHeading';
import { RichTextWrap } from '../../typography/RichTextWrap';

export type PromoBannerProps = {
  variation: 'default' | 'htmlEmbed';
  title: string;
  body: RichTextField[] | string;
  ctaText: string;
  url?: string;
  image: { url: string; alt?: string };
  mobileImage?: { url: string; alt?: string };
  prefetchImage?: boolean;
  htmlOverride?: string;
  onClick?: () => void;
};

export const PromoBanner = ({
  variation,
  title,
  body,
  ctaText,
  url,
  image,
  mobileImage,
  prefetchImage,
  htmlOverride,
  onClick,
}: PromoBannerProps) => {
  const { width: windowWidth } = useWindowWidth();

  switch (variation) {
    case 'default':
      if (!url) return null;
      return (
        <section>
          <LinkWrap
            className={cn(
              'flex shrink grow basis-auto flex-row justify-between self-center overflow-hidden rounded no-underline',
              'bg-background-primary-faded text-neutral relative m-0 min-h-[120px] w-full',
              'sm:text-grey-700 sm:bg-[#ffedee]'
            )}
            data-testid="promo-banner"
            href={url}
            onClick={onClick}
          >
            <div className="w-full shrink grow basis-0 self-center text-balance p-4 leading-normal sm:shrink-0 sm:basis-1/2 sm:px-6 sm:py-4 sm:[text-wrap:unset]">
              <DisplayHeading
                data-testid="promo-heading"
                className="leading-[1.4] sm:text-xl sm:leading-tight"
                as="h2"
                size="4"
              >
                {title}
              </DisplayHeading>
              <div
                data-testid="promo-body"
                className="text-base leading-[1.4] sm:leading-normal [&_p]:m-0 sm:[&_p]:mt-2"
              >
                <RichTextWrap field={body as any} />
              </div>
              {!!ctaText && (
                <span
                  data-testid="promo-CTA-mobile"
                  className="text-brand mt-2 inline-flex items-center whitespace-nowrap text-sm font-semibold leading-[1.4] underline underline-offset-2 sm:hidden"
                >
                  {ctaText}
                  <IconAngleRight className="ml-2" height="10px" width="10px" />
                </span>
              )}
            </div>
            <div className="h-auto w-32 sm:w-full">
              {!!mobileImage?.url && (
                <Image
                  dataTestId="promo-image-mobile"
                  className={cn('block', styles.image, image?.url ? 'sm:hidden' : 'sm:block')}
                  imageClassName="object-cover object-center"
                  style={styles.maskImage(windowWidth)}
                  src={mobileImage.url}
                  alt={mobileImage?.alt || ''}
                  priority={prefetchImage}
                  fill
                />
              )}
              {!!image?.url && (
                <Image
                  dataTestId="promo-image-main"
                  className={cn(mobileImage?.url ? 'hidden' : 'block', styles.image, 'sm:block')}
                  imageClassName="object-cover object-center"
                  style={styles.maskImage(windowWidth)}
                  src={image.url}
                  alt={image?.alt || ''}
                  priority={prefetchImage}
                  fill
                />
              )}
            </div>
            {!!ctaText && (
              <span
                data-testid="promo-CTA-main"
                className="mt-4 hidden rounded bg-white px-6 py-4 text-sm font-semibold leading-none shadow-md sm:absolute sm:right-[24px] sm:top-1/2 sm:z-10 sm:mt-0 sm:inline-block sm:-translate-y-1/2"
              >
                {ctaText}
              </span>
            )}
          </LinkWrap>
        </section>
      );

    case 'htmlEmbed':
      return (
        <section>
          <div data-testid="promo-html-embed" dangerouslySetInnerHTML={{ __html: htmlOverride }} />
        </section>
      );

    default:
      return null;
  }
};

export const styles = {
  image: 'h-full sm:[clip-path:_ellipse(70%_270%_at_72%_66%)]',
  maskImage: (windowWidth: number) => {
    if (windowWidth >= 576) return {};
    return {
      WebkitMaskImage: `url(${PROMO_BANNER_MASK_IMAGE_MOBILE})`,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'left',
    };
  },
};
