import { useCallback } from 'react';

import { OptionPillItem } from '@virginexperiencedays/components-v2/src/layout/Options';
import {
  categoriesHierarchyAttributeArray,
  categoryTypes,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  occasionsHierarchyAttributeArray,
  refinementList,
} from '@virginexperiencedays/search/constants';

import { useMenu } from '../../../../../libs/algolia/hooks/useMenu';
import { useRefinementList } from '../../../../../libs/algolia/hooks/useRefinementList';
import { getAttributeLvl } from '../../../../../libs/algolia/getAttributeLvl';
import { transformLabels } from '../../../../../libs/algolia/transformLabels';

import type { Override } from '@virginexperiencedays/categories/src/types';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import { IconCloseModal } from '@virginexperiencedays/components-v2/src/icons/IconCloseModal';

export const CurrentRefinementsWidget = ({
  categoryType,
  serverSlug,
  overridesSlugInfo,
  attribute,
  onClick,
}: {
  categoryType: categoryTypes;
  serverSlug: string[];
  overridesSlugInfo: Override['slugInfo'];
  attribute: string;
  onClick: (data: {
    attribute: AlgoliaAttribute;
    value: string;
    count: number;
    position: number;
  }) => void;
}) => {
  const currentLvl = serverSlug.length - 1;

  const regionAttribute = locationsHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.LOCATION, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;
  const categoryAttribute = categoriesHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.CATEGORY, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;
  const occasionAttribute = occasionsHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.OCCASION, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;
  const featureAttribute = featuresHierarchyAttributeArray[
    getAttributeLvl(categoryType, categoryTypes.FEATURE, currentLvl, overridesSlugInfo)
  ] as AlgoliaAttribute;

  switch (attribute) {
    case categoryAttribute:
      return <CurrentRefinementPillMulti attribute={categoryAttribute} onClick={onClick} />;
    case occasionAttribute:
      return <CurrentRefinementPillMulti attribute={occasionAttribute} onClick={onClick} />;
    case featureAttribute:
      return <CurrentRefinementPillMulti attribute={featureAttribute} onClick={onClick} />;
    case regionAttribute:
      return <CurrentRefinementPillMulti attribute={regionAttribute} onClick={onClick} />;
    case refinementList.PRICE_RANGE:
      return (
        <CurrentRefinementPillMulti attribute={refinementList.PRICE_RANGE} onClick={onClick} />
      );
    case refinementList.RATING:
      return <CurrentRefinementPillSingle attribute={refinementList.RATING} onClick={onClick} />;
    default:
      return null;
  }
};

/**
 * These components return exactly same UI, but to avoid introducing react anti-pattern of conditionally calling hooks, we have two separate components that call different hooks, although work exactly same
 * We also render them within an empty react fragment to allow the parent to control their styles from a single root
 *
 * Whenever using this, make sure that the `refine` method from the hook supports toggle feature because this UI will be used to actually untoggle existing values
 *
 * Also important to transform items to only return a subset of active items rather than a whole list
 */

const CurrentRefinementPillMulti = ({ attribute, onClick }) => {
  // only active items
  const { refine, items } = useRefinementList({
    attribute,
    transformItems: useCallback(
      (items) =>
        transformLabels(
          items.filter((item) => item.isRefined),
          attribute
        ),
      []
    ),
  });

  return (
    <>
      {items.map((item) => (
        <CurrentRefinementPillItem
          key={item.value}
          label={item.label}
          onClick={() => {
            refine(item.value);
            onClick(item);
          }}
        />
      ))}
    </>
  );
};

const CurrentRefinementPillSingle = ({ attribute, onClick }) => {
  // only active items
  const { refine, items } = useMenu({
    attribute,
    transformItems: useCallback(
      (items) =>
        transformLabels(
          items.filter((item) => item.isRefined),
          attribute
        ),
      []
    ),
  });

  return (
    <>
      {items.map((item) => (
        <CurrentRefinementPillItem
          key={item.value}
          label={item.label}
          onClick={() => {
            refine(item.value);
            onClick(item);
          }}
        />
      ))}
    </>
  );
};

export const CurrentRefinementPillItem = ({ label, onClick }) => (
  <li>
    <OptionPillItem onClick={onClick} checked>
      {label}
      <IconCloseModal className="h-2.5 w-2.5" />
    </OptionPillItem>
  </li>
);
