// This logic should be extended to handle isOverride case aswell
export function getShallowValue({
  isPaginated,
  isPLP,
  seoOverridesUrl,
  defaultValue,
  slugToPush,
  slugRemoved,
  paramsToRemove,
}): boolean {
  // If jumping between CLP => PLP always block shallow
  // Cases:
  // if on CLP and clicking facets to browse children CLPs => `shallow = false` to fetch CMS data
  // if on CLP and clicking facets (that moves us to PLP) => `shallow = true` -- IMPORTANT: URL needs to match PLP pathname, in case user performs a hard page refresh
  // if on Paginated CLP and clicking facets (that moves us to PLP) => `shallow = false` -- IMPORTANT: URL needs to match PLP pathname, in case user performs a hard page refresh
  // if on PLP and clicking facets (CSR calls) PLP => `shallow = true` to talk directly to algolia from a browser (network call)
  // cannot jump from PLP to a CLP via facets

  // Note that below early returns are for cases where shallow = false means you do not want to use CSR, but only SSG or SSR.
  if (seoOverridesUrl || isPaginated) {
    return false;
  }

  // If its a PLP or there is no slug to push or if not slug have been removed do CSR calls
  if (isPLP && !slugToPush && !slugRemoved && !paramsToRemove) {
    return true;
  }

  return defaultValue;
}
