import React from 'react';
import { useRouter } from 'next/compat/router';
import { useCurrentRefinements, useMenu } from 'react-instantsearch';
import { Accordion } from '@virginexperiencedays/components-v2/src/layout/Accordion';
import { refinementList } from '@virginexperiencedays/search/constants';

import { PriceRangeFilter } from './PriceRangeFilter';
import { transformItems } from './transformItems';

import { removeQueryStringFromPath } from '../../../../utils/routing/paths';

// Tracking
import { filter as track } from '../tracking/interactions';
import { useRouterPush } from '../../../../utils/routing/useRouterPush';

export const PriceRangeWidget = () => {
  const { items: currentRefinements } = useCurrentRefinements();
  const { items, sendEvent } = useMenu({
    attribute: refinementList.PRICE_RANGE,
    transformItems,
  });
  const { refine } = useRouterPush();

  const router = useRouter();
  const slug = removeQueryStringFromPath(router?.asPath, ' / ');

  const isRefined = currentRefinements.filter(
    ({ attribute }) => attribute == refinementList.PRICE_RANGE
  ).length;

  if (!items?.length || isRefined) return null;

  return (
    <Accordion heading={'Price Range'} open dataTestId="accordion-price-range">
      <PriceRangeFilter
        items={items}
        onClick={(value) => {
          refine({
            query: {
              price: value,
            },
          });

          track({ slug, label: 'Add Price' });
          sendEvent('click', value, '[INSIGHTS] Price Facet Clicked');
        }}
      />
    </Accordion>
  );
};
