import { PromoService } from './promo-service';
import type { ProductPromo, ValidDyProduct } from '../types';

/**
 * A service for handling promos for DY products (specifically for use with DY recommendations API)
 */
export class DyPromoService extends PromoService {
  /**
   *  Return promo object for the DY product (specifically for use with DY recommendations API)
   *
   * @param product
   * @returns
   */
  public getProductPromo(product: unknown): ProductPromo | null {
    if (!this.isValidDyProduct(product)) return null;

    const promosFromProduct = this.getCurrentPromosFromDyProduct(product);
    return this.generateProductPromo(promosFromProduct);
  }

  /**
   * Get the current promos from the DY product object
   *
   * @param product
   * @returns
   */
  private getCurrentPromosFromDyProduct(product: ValidDyProduct): string[] {
    const currentPromos: string[] = [];

    for (const [key, config] of this.promosConfig.entries()) {
      const keywordIdentifiers = [key, ...(config.facetIdentifiers ?? [])];
      const keywordsArr = product.keywords;

      if (!Array.isArray(keywordsArr) || !keywordsArr.length) continue;

      for (const identifier of keywordIdentifiers) {
        if (keywordsArr.includes(identifier)) {
          currentPromos.push(key);
        }
      }
    }

    return currentPromos;
  }

  /**
   * Check if the product is a valid product object based on the ValidDyProduct type
   *
   * @param product
   * @returns
   */
  private isValidDyProduct(product: unknown): product is ValidDyProduct {
    return (
      typeof product === 'object' &&
      product !== null &&
      'keywords' in product &&
      Array.isArray((product as any).keywords)
    );
  }
}
