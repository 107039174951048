import { cn } from '@virginexperiencedays/tailwind';

import { IconCloseModal } from '@virginexperiencedays/components-v2/src/icons/IconCloseModal';
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@virginexperiencedays/components-v2/src/layout/Drawer';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import { nameToSlug } from '@virginexperiencedays/search/utils/category';
import { usePagination } from '../../../../../libs/algolia/hooks/usePagination';

import type { ReactNode } from 'react';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';

type FilterDrawerProps = {
  open: boolean;
  setOpen: (attribute: AlgoliaAttribute | 'sortBy', value: boolean) => void;
  text: string;
  children: ReactNode;
  attribute: AlgoliaAttribute | 'sortBy';
  activeCount: number;
  onReset?: (attribute: AlgoliaAttribute | 'sortBy') => void;
  resetText?: string;
  onApply?: (attribute: AlgoliaAttribute | 'sortBy') => void;
  applyText?: string;
};

export const FilterDrawer = ({
  open,
  setOpen,
  text,
  children,
  resetText = 'Reset',
  onReset,
  onApply,
  applyText,
  attribute,
  activeCount,
}: FilterDrawerProps) => {
  const { nbHits: nbOfHits } = usePagination();
  return (
    <Drawer
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(attribute, isOpen);
      }}
      noBodyStyles
      disablePreventScroll
    >
      <DrawerContent hasHandle>
        <DrawerDescription className="sr-only">{text} filter content:</DrawerDescription>
        <FilterDrawerHeader>{text}</FilterDrawerHeader>
        <DrawerBody>
          <div
            className={cn(
              'overflow-y-auto !pr-5 mega-nav-scrollbar',
              // 90dvh less heights of drawer handle, drawer heading, drawer body padding bottom respectively
              'max-h-[calc(90dvh_-_(20px_+_56px_+_70px))]'
            )}
          >
            {children}
          </div>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex w-full space-x-4">
            {!!onReset && (
              <DrawerClose asChild>
                <Button
                  data-testid={`reset-${nameToSlug(text)}-filter`}
                  disabled={!activeCount}
                  className="text-nowrap disabled:border-0 disabled:bg-background-disabled disabled:text-[#9CA3AF]"
                  variant="outline"
                  onClick={() => {
                    onReset(attribute);
                  }}
                >
                  {resetText}
                </Button>
              </DrawerClose>
            )}
            <DrawerClose asChild>
              {!!onApply && (
                <Button
                  data-testid={`apply-${nameToSlug(text)}-filter`}
                  disabled={!activeCount}
                  className="flex-1 text-nowrap text-white disabled:border-0 disabled:bg-background-disabled disabled:text-[#9CA3AF]"
                  onClick={() => {
                    onApply(attribute);
                  }}
                  variant="primary"
                >
                  {applyText || `Apply Filter (${nbOfHits} results)`}
                </Button>
              )}
            </DrawerClose>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const FilterDrawerHeader = ({ children }) => {
  return (
    <DrawerHeader className="flex items-center">
      <DrawerTitle className="flex-1">{children}</DrawerTitle>
      <DrawerClose className="px-2">
        <IconCloseModal />
      </DrawerClose>
    </DrawerHeader>
  );
};
