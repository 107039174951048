import { addToDataLayer } from '../../components/tracking/gtm/utils';
import { TrackingEventName } from './types';

/**
 * Filter interactions
 *
 * from Quick Filter Bar
 * - Swipe to more
 * - Swipe back
 * - Click on all filters
 * - Click on sort
 * - Click on filter
 *
 * from Filter Modal/Drawer (e.g. All Filters, Location)
 * - Filter closed
 * - Click apply filter
 * - Deselect applied filter
 * - Location search committed
 * - Location search cleared
 * - Location search suggestions selected
 * - Current location selected
 *
 * from Both
 * - Scroll
 * - Select filter option
 * - Deselect filter option
 * - Click reset filter
 */
export function filter({
  pageType,
  title,
  position,
  optionTitle,
  optionPosition,
  optionNumberOfProducts,
  qfTotalProductCount,
  type,
  source,
  interaction,
}: FilterInteractionProps) {
  addToDataLayer({
    event: TrackingEventName.filterInteraction,
    filter_page_type: pageType,
    filter_title: title,
    filter_position: position,
    filter_option_title: optionTitle,
    filter_option_position: optionPosition,
    filter_option_number_of_products: optionNumberOfProducts,
    filter_qf_total_product_count: qfTotalProductCount,
    filter_type: type,
    filter_source: source,
    filter_interaction: interaction,
  });
}

export type FilterInteractionProps = {
  pageType: string;
  title: string;
  position: number;
  qfTotalProductCount: number;
  source: FilterSource;
  interaction: FilterInteraction;
  optionTitle?: string;
  optionPosition?: number;
  optionNumberOfProducts?: number;
  type?: FilterType;
};

export type FilterInteractionData = {
  event: TrackingEventName;
  filter_page_type: string;
  filter_title: string;
  filter_position: number;
  filter_option_title?: string;
  filter_option_position?: number;
  filter_option_number_of_products?: number;
  filter_qf_total_product_count: number;
  filter_type?: FilterType;
  filter_source: FilterSource;
  filter_interaction: FilterInteraction;
};

export enum FilterType {
  SingleSelect = 'single_select',
  MultiSelect = 'multi_select',
  LocationSearch = 'location_search',
}

export enum FilterSource {
  QuickFilterBar = 'Quick Filter Bar',
  // mobile=drawer, desktop=modal
  AllFilters = 'All Filters',
  // mobile=drawer, desktop=modal
  Sort = 'Sort',
  // mobile=drawer, desktop=modal
  LocationFilter = 'Location Filter',
  // mobile quick filter
  FilterDrawer = 'Filter Drawer',
  // desktop quick filter
  FilterDropdown = 'Filter Dropdown',
  FilterModal = 'Filter Modal',
}

export enum FilterInteraction {
  // Quick Filter
  SwipeToMore = 'Swipe to more',
  SwipeBack = 'Swipe back',
  ClickAllFilters = 'Click on all filters',
  ClickSort = 'Click on sort',
  // (for mobile only) mobile clicks to drawer (desktop hovers to dropdown)
  ClickFilter = 'Click on filter',

  // Filter Modal (e.g. All Filters, Location)
  FilterClosed = 'Filter closed',
  SortClosed = 'Sort closed',
  ClickApplyFilter = 'Click apply filter',
  DeselectAppliedFilter = 'Deselect applied filter',
  LocationSearchSubmit = 'Location search committed',
  LocationSearchClear = 'Location search cleared',
  LocationSearchSuggestionSelected = 'Location search suggestions selected',
  CurrentLocationSelected = 'Current location selected',

  ClickedSeeMore = 'Clicked see more',

  // Shared
  OptionsScroll = 'Scroll',
  SelectSortOption = 'Select sort option',
  SelectFilterOption = 'Select filter option',
  DeselectFilterOption = 'Deselect filter option',
  ClickReset = 'Click reset filter',
}
