import { useDynamicWidgets } from 'react-instantsearch';
import { useRouter } from 'next/compat/router';

import { categoryTypes, MAX_VALUES_PER_FACET } from '@virginexperiencedays/search/constants';

import { removeQueryStringFromPath } from '../../../../../utils/routing/paths';

import { getAttributesToRender } from '../../../../../libs/algolia/optionsToRender';
import { DynamicWidgetsFactory } from './DynamicWidgetsFactory';

type DynamicWidgetMapProps = {
  categoryType: categoryTypes;
};

export const DynamicWidgetMap = ({ categoryType }: DynamicWidgetMapProps) => {
  const router = useRouter();
  const { attributesToRender: defaultAttributes } = useDynamicWidgets({
    maxValuesPerFacet: MAX_VALUES_PER_FACET, // needs to be same as in hierarchical menu
  });

  const slug = removeQueryStringFromPath(router?.asPath, ' / ');
  const attributesToRender = getAttributesToRender(slug, defaultAttributes);

  return (
    <div data-testid="dynamic-widgets">
      {attributesToRender.map((attribute) => (
        <DynamicWidgetsFactory key={attribute} attribute={attribute} categoryType={categoryType} />
      ))}
    </div>
  );
};
