import { cn } from '@virginexperiencedays/tailwind';
import { DisplayHeading, type DisplayHeadingProps } from '../../../typography/DisplayHeading';

// Sample usage: pills/radio/check options
export const Heading = ({ children, className, ...props }: DisplayHeadingProps) => (
  <DisplayHeading
    className={cn('mb-4 text-base font-semibold leading-none md:text-sm md:leading-4', className)}
    size="3"
    {...props}
  >
    {children}
  </DisplayHeading>
);
