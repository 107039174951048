import { categoryTypes } from '@virginexperiencedays/search/constants';

// Components
import { SideDrawer } from '@virginexperiencedays/components-v2/src/layout/SideDrawer';
import { VisualHeading } from '@virginexperiencedays/components-v2/src/typography/VisualHeading';

// Internal
import { CurrentRefinementsWidget } from '../CurrentRefinementsWidget';
import { DynamicWidgetMap } from './DynamicWidgetMap';

// Hooks
import { useRouter } from 'next/compat/router';
import { useRouterPush } from '@virginexperiencedays/web/utils/routing/useRouterPush';
import { usePagination } from 'react-instantsearch-core';
import { cn } from '@virginexperiencedays/tailwind';
import { useCurrentRefinements } from '../../../../libs/algolia/hooks/useCurrentRefinements';
import useFeatureFlags from '@virginexperiencedays/feature-flags';

type FacetsDrawerProps = {
  toggleFacetsDrawer: () => void;
  isFacetsDrawerOpen: boolean;
  currentRefinements: any[];
  categoryType: categoryTypes;
};

export const FacetsDrawer = ({
  categoryType,
  toggleFacetsDrawer,
  isFacetsDrawerOpen,
  currentRefinements,
}: FacetsDrawerProps) => {
  // Hooks are passed down into the current refinements to facilitate writing tests
  const [isEnabled] = useFeatureFlags();
  const { refine } = useRouterPush();
  const { refine: clearRefinements } = useCurrentRefinements();
  const router = useRouter();
  const { nbHits } = usePagination();

  return (
    <SideDrawer
      drawerTitle={DrawerTitle}
      headerAlign="sticky"
      hideDrawer={toggleFacetsDrawer}
      isOpen={isFacetsDrawerOpen}
    >
      <div>
        <DynamicWidgetMap categoryType={categoryType} />
      </div>
      <CurrentRefinementsWidget
        nbHits={nbHits}
        hideDrawer={toggleFacetsDrawer}
        categoryType={categoryType}
        router={router}
        refine={isEnabled('FF_new_filters') ? clearRefinements : refine}
        currentRefinements={currentRefinements}
      />
    </SideDrawer>
  );
};

const DrawerTitle = () => (
  <VisualHeading as="h2" size="4" className={cn('text-[1.75rem] sm:text-[2.125rem]')}>
    Filters
  </VisualHeading>
);
