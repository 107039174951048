import { useRouter } from 'next/compat/router';
import { useContext, useEffect, useState } from 'react';

import { default as GTMContext } from '../../../tracking/gtm/context';
import { hitImpression } from '.';
import { removeQueryStringFromPath } from '../../../../utils/routing/paths';
import { postfix } from './util';

/**
 * A custom hook that tracks the current path and sends impression data to GTM based on the Algolia results and the page position offset.
 */
export default function ({
  url,
  positionOffset = 0,
  hits,
}: {
  url: string;
  positionOffset: number;
  hits: any[];
}) {
  const gtm = useContext(GTMContext);
  const router = useRouter();

  // Initialise path with router.asPath - The actual impressions are fired on the client only when javascript is enabled, what means router should be mounted by then, but for double safety
  // we use server state url as fallback (this path contains `/c` but gets filtered out in `removeQueryStringFromPath`)
  const [path, setPath] = useState(router?.asPath ?? url);

  // Keeps track of the current path and updates the state if the path changes
  useEffect(() => {
    function handleRouteChange() {
      setPath(router.asPath);
    }

    // Detect completed route change
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router?.asPath]);

  // Tracks impressions of the results and sends data to GTM
  useEffect(() => {
    let list = removeQueryStringFromPath(path, ' / ');

    list = postfix(list, path);

    impressions(hits, list, positionOffset).forEach((impressions) => {
      gtm.track([
        { ecommerce: null }, // clear before every product grid impressions
        {
          event: 'ec:impressions',
          ecommerce: {
            currencyCode: 'GBP',
            impressions,
          },
        },
      ]);
    });

    // Trigger every time path changes -- Every path change means new results so new impressions
  }, [path]);
}

/**
 * Break impressions into chunks to avoid hitting limits
 */
const hitsPerChunk = 15;
function impressions(hits: any[], list: string, positionOffset = 0) {
  if (!hits?.length) return [];

  return hits.reduce((reduced, hit, index) => {
    const chunkIndex = Math.floor(index / hitsPerChunk);

    if (!reduced[chunkIndex]?.length) {
      reduced[chunkIndex] = []; // start a new chunk
    }

    reduced[chunkIndex].push(hitImpression(hit, positionOffset + index + 1, list));

    return reduced;
  }, []);
}
