import { cn } from '@virginexperiencedays/tailwind';

import { StarRating, type StarRatingProps } from '../../../StarRating';
import { BodyText } from '../../../typography/BodyText';

export type ReviewSummaryProps = {
  className?: string;
  averageRating?: number;
  count?: number;
  Icon?: StarRatingProps['Icon'];
};

export const ReviewSummary = ({ className, averageRating, count, Icon }: ReviewSummaryProps) => {
  if (!averageRating || !count) return null;

  return (
    <div
      className={cn('flex items-center justify-start whitespace-nowrap', className)}
      data-testid="reviewSummary"
    >
      <StarRating Icon={Icon} rating={averageRating} data-testid="star-rating" hasResponsiveGap />
      <BodyText
        className="text-neutral-faded ml-2 text-base leading-none sm:text-sm"
        data-testid="rating-reviews"
      >
        {averageRating < 5 && <span className="text-neutral mr-1">& up</span>}
        <span>({count})</span>
      </BodyText>
    </div>
  );
};
