export function mapPathnameToPageType(pathname: string): string {
  if (pathname.includes('plp')) {
    return 'Product Listing Page';
  }
  if (pathname.includes('search')) {
    return 'Search';
  }

  if (!pathname.includes('plp') && pathname.includes('[...slug]')) {
    return 'Category Listing Page';
  }
}
