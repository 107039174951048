// Core
import { type MouseEvent, type MutableRefObject, useCallback, useMemo } from 'react';
import { useRouter } from 'next/compat/router';
import { usePagination } from '../../../../libs/algolia/hooks/usePagination';

// Components
import { Pagination as PaginationComponent } from '@virginexperiencedays/components-v2/src/layout/Pagination';
import { removeQueryStringFromPath } from '../../../../utils/routing/paths';

// Tracking
import { paginate as track } from '../tracking/interactions';

type PaginationWidgetProps = {
  url: string; // This prop is used as a fallback, to createURL when the pages are initially rendered to keep SEO
  numberOfHits: number;
  refElementToScroll?: MutableRefObject<HTMLElement>;
};

export const PaginationWidget = ({
  url,
  numberOfHits,
  refElementToScroll,
}: PaginationWidgetProps) => {
  const router = useRouter();
  const { currentRefinement, nbHits, createURL, refine } = usePagination();

  const hitsNb = useMemo(() => numberOfHits ?? nbHits, [numberOfHits, nbHits]);

  const path = removeQueryStringFromPath(router?.asPath, ' / ');

  const handleCreateURL = useCallback((page: number) => createURL(page as any, url), [hitsNb, url]);

  const handleAnchorScrollRedirect = async ({
    e,
    element,
    page,
    label,
  }: {
    e: MouseEvent<HTMLElement>;
    element: HTMLElement | undefined;
    page: number;
    label: string;
  }) => {
    e.preventDefault();

    track({ slug: path, label });

    // refine on click to utilise CSR algolia call (super fast pagination change) compared to regular router.push
    // always make sure createURL has the same output to avoid surprises
    await refine(page);

    if (!element) return;

    window.scrollTo({
      top: element?.offsetTop || 0,
    });
  };

  return (
    <div>
      <PaginationComponent
        currentPage={currentRefinement + 1}
        withPrefetch={false}
        itemsCount={hitsNb}
        perPage={40}
        renderLink={handleCreateURL}
        onClick={async (e, page, label) => {
          await handleAnchorScrollRedirect({
            e,
            element: refElementToScroll?.current,
            page,
            label,
          });
        }}
      />
    </div>
  );
};
