import { categoryPrefixes } from '@virginexperiencedays/search/constants';

export const normalizeSlug = (slug: string | string[]): string[] => {
  // Filter out category prefixes or plp from slugs
  // Revisit that to make it well defined
  const slugArray = Array.isArray(slug) ? slug : slug?.split('/');
  const filteredSlug = slugArray?.filter(
    (slug) => !Object.values(categoryPrefixes).includes(slug as categoryPrefixes) && slug !== 'plp'
  );
  return filteredSlug;
};
