export function removeUndefinedParams<TRouteParams>(params: TRouteParams) {
  return Object.entries(params).reduce((queries, [key, value]) => {
    if (typeof value !== 'undefined') {
      return { ...queries, [key]: value };
    }
    return queries;
  }, {});
}

export function removeSearchParams(url: string) {
  return url.replace(/(\?.+)/, '');
}

export function removeParams<TRouteParams>(params: TRouteParams, keysToRemove: string | string[]) {
  return Object.entries(params).reduce((queries, [key, value]) => {
    if (!keysToRemove.includes(key) && !!value) {
      return { ...queries, [key]: value };
    }
    return queries;
  }, {});
}
