import { useRouter } from 'next/compat/router';
import { type MouseEvent, useCallback, useState } from 'react';
import type { UseRefinementListProps } from 'react-instantsearch-core';

import {
  OptionBadge,
  OptionListItem,
  OptionPillItem,
  Options,
  OptionsGroup,
  OptionText,
  OptionToggle,
} from '@virginexperiencedays/components-v2/src/layout/Options';
import RenderIcon from '@virginexperiencedays/header-2024/src/components/common/icons/RenderIcon';
import { cn } from '@virginexperiencedays/tailwind';
import { categoryTypes } from '@virginexperiencedays/search/constants';

import type { RefinementListItem } from '@virginexperiencedays/search/types';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';

import { isDesktopFilter } from '../../../../../libs/algolia/isDesktopFilter';
import { usePagination } from '../../../../../libs/algolia/hooks/usePagination';
import { useRefinementList } from '../../../../../libs/algolia/hooks/useRefinementList';
import {
  filter as track,
  FilterInteraction,
  FilterSource,
} from '../../../../../libs/tracking/filters';
import { mapAttributeToFilterType } from '../../../../../libs/tracking/mapAttributeToFilterType';
import { mapPathnameToPageType } from '../../../../../libs/tracking/mapPathnameToPageType';

import {
  filterItemLabels,
  sortItemLabels,
  transformLabels,
} from '../../../../../libs/algolia/transformLabels';
import type { Override } from '@virginexperiencedays/categories/src/types';

type MultiSelectWidgetProps = {
  attribute: AlgoliaAttribute;
  categoryType: categoryTypes;
  widgetCategoryType: categoryTypes;
  serverSlug: string[];
  label: string;
  operator?: UseRefinementListProps['operator'];
  showIcon?: boolean;
  min?: number;
  renderType: 'pill' | 'list';
  filterIndex: number;
  listItemIcon?: string;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  overridesSlugInfo: Override['slugInfo'];
};

export const MultiSelectWidget = ({
  attribute,
  categoryType,
  widgetCategoryType,
  serverSlug,
  label,
  operator = 'or',
  showIcon = true,
  min = 5,
  renderType,
  filterIndex,
  listItemIcon,
  className,
  onClick,
  overridesSlugInfo,
}: MultiSelectWidgetProps) => {
  const router = useRouter();
  const pathname = router?.pathname;
  const [expanded, setExpanded] = useState<boolean>(false);
  const { nbHits: nbOfHits } = usePagination();
  const { refine, items } = useRefinementList({
    attribute,
    operator,
    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);

      if (
        categoryType !== widgetCategoryType &&
        !overridesSlugInfo?.some(({ type }) => type === widgetCategoryType)
      ) {
        return transformLabels(sortedItems, attribute);
      }

      const filtered = filterItemLabels(sortedItems, attribute, serverSlug, overridesSlugInfo);
      return transformLabels(filtered, attribute);
    }, []),
  });

  const handleExpandCollapse = () => {
    setExpanded((prev) => !prev);

    track({
      title: label,
      type: mapAttributeToFilterType(attribute),
      pageType: mapPathnameToPageType(pathname),
      position: filterIndex + 1,
      source: isDesktopFilter() ? FilterSource.FilterDropdown : FilterSource.FilterDrawer,
      interaction: FilterInteraction.ClickedSeeMore,
      qfTotalProductCount: nbOfHits,
    });
  };

  const showExpandCollapseToggle = !!min && items.length > min && !expanded;

  return (
    <Options className={cn('gap-0', className)}>
      <OptionsGroup isColumn={renderType === 'list'}>
        {items
          .filter((el) => !!el.value) // gotta filter out manually as geo search inserts an empty value and cannot be filtered using transformItems
          .map((item, index) => {
            const handleClick = ({ e, value, checked }: MultiselectWidgetItemClickHandler) => {
              refine(value);

              track({
                title: label,
                type: mapAttributeToFilterType(attribute),
                pageType: mapPathnameToPageType(pathname),
                position: filterIndex + 1,
                source: isDesktopFilter() ? FilterSource.FilterDropdown : FilterSource.FilterDrawer,
                interaction: checked
                  ? FilterInteraction.DeselectFilterOption
                  : FilterInteraction.SelectFilterOption,
                qfTotalProductCount: nbOfHits,
                optionNumberOfProducts: item.count,
                optionPosition: index + 1,
                optionTitle: item.label,
              });

              onClick?.(e);
            };

            return (
              <li key={item.value}>
                <MultiselectWidgetItem
                  item={item}
                  className={!expanded && !!min && index >= min && 'hidden'}
                  onClick={handleClick}
                  showIcon={showIcon}
                  renderType={renderType}
                  icon={listItemIcon}
                />
              </li>
            );
          })}
      </OptionsGroup>
      {showExpandCollapseToggle && (
        <OptionToggle
          className="py-4 md:py-2"
          onClick={handleExpandCollapse}
          aria-expanded={expanded}
        >
          See more {label}
        </OptionToggle>
      )}
    </Options>
  );
};

type MultiselectWidgetItemClickHandler = {
  e: MouseEvent<HTMLButtonElement>;
  value: string;
  checked: boolean;
};

const MultiselectWidgetItem = ({
  item,
  className,
  onClick,
  showIcon,
  renderType,
  icon,
}: {
  item: RefinementListItem;
  icon?: string;
  className: string;
  onClick: ({ e, value, checked }: MultiselectWidgetItemClickHandler) => void;
  showIcon: boolean;
  renderType: 'pill' | 'list';
}) => {
  const { value, label, count, isRefined: checked } = item;
  const Component = renderType === 'pill' ? OptionPillItem : OptionListItem;
  return (
    <Component
      key={value}
      checked={checked}
      className={className}
      onClick={(e) => {
        onClick?.({ e, value, checked });
      }}
      aria-pressed={checked}
      aria-label={`${label}, ${count || 0} items`}
      title={label}
    >
      {/* NOTE: Will be changed once we get the final list of icons to use */}
      {icon && showIcon && <RenderIcon className="h-4 w-4 min-w-4" name={icon ?? 'photo'} />}
      <OptionText>{label}</OptionText>
      {!!count && <OptionBadge checked={checked}>{count || 0}</OptionBadge>}
    </Component>
  );
};
