import type { ReactNode } from 'react';

import { PillMenu } from '@virginexperiencedays/components-v2/src/layout/PillMenu';
import { cn } from '@virginexperiencedays/tailwind';
import { locationsHierarchyAttributeArray } from '@virginexperiencedays/search/constants';

import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import { nameToSlug } from '@virginexperiencedays/search/utils/category';

export const FilterPill = ({
  attribute,
  children,
  text,
  activeCount,
  icon,
  hasArrow,
  onMouseEnter,
  onMouseLeave,
  onClick,
  className,
  textClassName,
}: {
  attribute: AlgoliaAttribute;
  children: ReactNode;
  text: string;
  activeCount: number;
  icon: ReactNode;
  hasArrow: boolean;
  onMouseEnter: (attribute: AlgoliaAttribute) => void;
  onMouseLeave: (attribute: AlgoliaAttribute) => void;
  onClick: (attribute: AlgoliaAttribute) => void;
  className?: string;
  textClassName?: string;
}) => {
  return (
    <>
      <PillMenu
        data-testid={`filter-${nameToSlug(text)}-trigger`}
        className={cn('group relative', className)}
        checked={!!activeCount}
        text={text}
        count={activeCount}
        icon={icon}
        hasArrow={hasArrow}
        textClassName={cn(
          // truncate at 7-8 characters
          'block whitespace-nowrap text-sm leading-none truncate max-w-[70px]',
          textClassName
        )}
        // add title for location in case value is too long for geo search
        title={locationsHierarchyAttributeArray.includes(attribute) ? text : null}
        onClick={() => onClick(attribute)}
        onMouseEnter={() => onMouseEnter(attribute)}
        onMouseLeave={() => onMouseLeave(attribute)}
      />
      {children}
    </>
  );
};
