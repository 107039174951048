import { IconSortBy } from '@virginexperiencedays/components-v2/src/icons/IconSortBy';
import {
  OptionListItem,
  OptionPillItem,
  OptionsGroup,
} from '@virginexperiencedays/components-v2/src/layout/Options';
import {
  MenuDropdown,
  MenuDropdownContent,
  PillMenu,
} from '@virginexperiencedays/components-v2/src/layout/PillMenu';
import { cn } from '@virginexperiencedays/header-2024';
import { useState } from 'react';

import { sortingOptions } from '../../../../../libs/algolia/constants/sortingOptions';
import { useSortBy } from '../../../../../libs/algolia/hooks/useSortBy';
import { mapIndexToRoute } from '../../../../../libs/algolia/sortMapping';
import { indexNames } from '@virginexperiencedays/search/constants';

import { FilterDrawer } from '../FilterOverlay/FilterDrawer';
import { useGeoSearch } from '../../../../../libs/algolia/hooks/useGeoSearch';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import { IconInfo } from '@virginexperiencedays/components-v2/src/icons/IconInfo';
import { useCurrentRefinements } from '../../../../../libs/algolia/hooks/useCurrentRefinements';

// Mobile as Drawer
export const SortWidget = ({
  className,
  iconClass,
  onClick,
  onOpenClose,
}: {
  className?: string;
  iconClass?: string;
  onClick?: (item: { value: string; position: number }) => void;
  onOpenClose?: (value: boolean) => void;
}) => {
  const { options: sortOptions, refine, currentRefinement } = useSortBy({ items: sortingOptions });
  const { currentRefinement: currentGeoSearchRefinement } = useGeoSearch();
  const { refine: clearRefinement } = useCurrentRefinements();
  const [open, setOpen] = useState(false);

  const modifiedSortOptions =
    !!currentGeoSearchRefinement && Object?.values(currentGeoSearchRefinement).length > 0
      ? sortOptions.map((option) => ({
          ...option,
          label: option.value === indexNames.DEFAULT ? 'Nearest' : option.label,
        }))
      : sortOptions;

  return (
    <div className={cn('relative', className)}>
      <PillMenu
        text="Sort by"
        icon={
          <IconSortBy
            height="16"
            color="currentColor"
            className={cn(
              'text-neutral dark:md:group-hover/pill:text-persistent-text-dark',
              iconClass
            )}
          />
        }
        className="border-none p-0 hover:bg-transparent"
        textClassName="text-sm leading-none"
        onClick={() => setOpen(true)}
        data-testid="filter-sort-by-trigger"
      />
      {open && (
        <FilterDrawer
          open={open}
          setOpen={(_, value) => {
            // callback
            onOpenClose?.(value);

            setOpen(value);
          }}
          text="Sort by"
          attribute="sortBy"
          activeCount={0}
        >
          <OptionsGroup>
            {modifiedSortOptions.map((option, index) => (
              <OptionPillItem
                checked={currentRefinement === option.value}
                key={option.value}
                onClick={() => {
                  // callback
                  onClick?.({ value: option.value, position: index + 1 });

                  refine(mapIndexToRoute(option.value));
                  setOpen(false);
                }}
                disabled={!!currentGeoSearchRefinement && option.value !== indexNames.DEFAULT}
              >
                {option.label}
              </OptionPillItem>
            ))}
          </OptionsGroup>
          {!!currentGeoSearchRefinement && (
            <div className="mt-4 flex items-center space-x-2 border-[#FEF08A] bg-[#FEF9C3] p-2">
              <IconInfo width="16" height="16" />
              <div>
                <span className="text-sm text-neutral">Clear location to change sort order.</span>
                <Button
                  onClick={() => clearRefinement({ attribute: 'geoSearch' })}
                  variant="invisible"
                  className="ml-0.5 p-0 font-normal underline"
                >
                  Clear now
                </Button>
              </div>
            </div>
          )}
        </FilterDrawer>
      )}
    </div>
  );
};

// Desktop as Dropdown
export const SortWidgetDesktop = ({
  onClick,
  className,
  iconClass,
}: {
  onClick?: (item: { value: string; position: number }) => void;
  className?: string;
  iconClass?: string;
}) => {
  const { options: sortOptions, refine, currentRefinement } = useSortBy({ items: sortingOptions });
  const { currentRefinement: currentGeoSearchRefinement } = useGeoSearch();
  const { refine: clearRefinement } = useCurrentRefinements();

  const modifiedSortOptions = currentGeoSearchRefinement
    ? sortOptions.map((option) => ({
        ...option,
        label: option.value === indexNames.DEFAULT ? 'Nearest' : option.label,
      }))
    : sortOptions;

  return (
    <div className={cn('group relative')}>
      <PillMenu
        text="Sort by"
        className={className}
        textClassName="hidden xl:block whitespace-nowrap text-sm leading-none"
        count={0}
        icon={
          <IconSortBy
            height="16"
            color="currentColor"
            className={cn(
              'text-neutral dark:md:group-hover/pill:text-persistent-text-dark',
              iconClass
            )}
          />
        }
        hasArrow={false}
        data-testid="filter-sort-by-trigger"
      />
      <MenuDropdown
        className={cn('absolute right-0 top-full z-10 w-[300px]', 'hidden md:group-hover:block')}
      >
        <MenuDropdownContent>
          {modifiedSortOptions.map((option, index) => {
            return (
              <OptionListItem
                checked={currentRefinement === option.value}
                isSingleSelect
                key={option.value}
                onClick={() => {
                  // callback
                  onClick?.({ value: option.value, position: index + 1 });

                  refine(mapIndexToRoute(option.value));
                }}
                disabled={!!currentGeoSearchRefinement && option.value !== indexNames.DEFAULT}
              >
                {option.label}
              </OptionListItem>
            );
          })}
          {!!currentGeoSearchRefinement && (
            <div className="mt-2 flex space-x-2 border-[#FEF08A] bg-[#FEF9C3] p-2">
              <IconInfo viewBox="0 0 16 16" width="20" height="20" />
              <div>
                <span className="text-sm text-neutral">Clear location to change sort order.</span>
                <Button
                  onClick={() => clearRefinement({ attribute: 'geoSearch' })}
                  variant="invisible"
                  className="ml-0.5 p-0 font-normal underline"
                >
                  Clear now
                </Button>
              </div>
            </div>
          )}
        </MenuDropdownContent>
      </MenuDropdown>
    </div>
  );
};
