import { PrismicRichText } from '@prismicio/react';
import type { RichTextField } from '@prismicio/types';
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { LinkWrap } from '../../navigation/LinkWrap';

const COMPONENT_MAP = {
  /**
   * Catch links with our own LinkWrap instead of PrismicLink.  Annoyingly,
   * PrismicLink's default handling for links has its own external URL detection
   * that trumps the user input for "Open in New Tab".
   *
   * See: https://github.com/prismicio/prismic-react/blob/master/src/PrismicLink.tsx#L187-L194
   *
   * Make sure to pass in node.data.target as is, with no fallback values, to
   * allow the user control over that decision. Otherwise, open it in the
   * current tab (for Documents and Media, for example).
   */
  hyperlink: ({ node, children, key }) => (
    <LinkWrap
      key={key}
      href={getPrismicLinkPath(node.data)}
      target={
        node.data.link_type !== 'Document' && !!node.data?.target ? node.data.target : '_self'
      }
      isExternal={node.data.link_type !== 'Document'}
    >
      {children}
    </LinkWrap>
  ),
};

type RichTextWrapProps = {
  field: RichTextField;
};

/**
 * This is a wrapper of PrismicRichText with some of our modifications to the
 * components it uses to render individual elements such as hyperlinks.
 */
export const RichTextWrap = ({ field }: RichTextWrapProps) => {
  return <PrismicRichText field={field} components={COMPONENT_MAP} />;
};
