import { createPortal } from 'react-dom';
import { ReactNode, useEffect } from 'react';

import { Modal } from '@virginexperiencedays/components-v2/src/layout/Modal';
import { DisplayHeading } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { cn } from '@virginexperiencedays/tailwind';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import { useScrollLock } from '@virginexperiencedays/hooks';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import { nameToSlug } from '@virginexperiencedays/search/utils/category';

/*
 * Generic filter modal used for displaying filters in a modal
 */
export const FilterModal = ({
  open,
  setOpen,
  attribute,
  text,
  children,
  className,
  onReset,
  resetText,
  disabledReset,
  onApply,
  applyText,
  disabledApply,
}: {
  open: boolean;
  setOpen: (attribute: AlgoliaAttribute, value: boolean) => void;
  text: string;
  children: ReactNode;
  onReset?: (attribute: AlgoliaAttribute) => void;
  resetText?: string;
  disabledReset?: boolean;
  onApply?: (attribute: AlgoliaAttribute) => void;
  applyText?: string;
  disabledApply?: boolean;
  attribute?: AlgoliaAttribute;
  className?: string;
}) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
    return () => unlockScroll();
  }, [open]);

  // For portal to work properly it needs to be rendered on CSR
  // So it has to be rendered conditionally triggered on a local state ideally
  // like open && <FilterModal> ...
  return createPortal(
    <Modal
      isOpen={open}
      hideModal={() => {
        setOpen(attribute, false);
      }}
      className={cn('max-w-[490px]', className)}
    >
      <FilterModalHeader>{text}</FilterModalHeader>
      <div className="flex flex-col p-4">
        <FilterModalContent>{children}</FilterModalContent>
      </div>

      <FilterModalFooter>
        {!!onReset && !!resetText && (
          <Button
            data-testid={`reset-${nameToSlug(text)}-filter`}
            disabled={disabledReset}
            onClick={() => onReset(attribute)}
            className="text-nowrap px-4 py-3 leading-4 disabled:border-0 disabled:bg-background-disabled disabled:text-[#9CA3AF]"
            variant="outline"
          >
            {resetText}
          </Button>
        )}

        {!!onApply && applyText && (
          <Button
            data-testid={`apply-${nameToSlug(text)}-filter`}
            disabled={disabledApply}
            fullWidth
            onClick={() => onApply(attribute)}
            variant="primary"
            className="flex-1 text-nowrap px-4 py-3 leading-4 text-white disabled:border-0 disabled:bg-background-disabled disabled:text-[#9CA3AF]"
          >
            {applyText}
          </Button>
        )}
      </FilterModalFooter>
    </Modal>,
    document.body
  );
};

export const FilterModalHeader = ({ children }: { children: ReactNode }) => {
  return <DisplayHeading className="p-4 pb-0 text-[22px] leading-tight">{children}</DisplayHeading>;
};

export const FilterModalContent = ({ children }: { children: ReactNode }) => {
  return <div className="max-h-[65dvh] overflow-y-auto mega-nav-scrollbar">{children}</div>;
};

export const FilterModalFooter = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-between gap-2 p-4">{children}</div>;
};
