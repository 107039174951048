/*
 * Wrapper around our useGeoSearch hook, hook itself is custom crafted but sits cloesly to react-instantsearch, this is a wrapper with added logic for `refine` method. */

import { useRouter } from 'next/compat/router';
import { queryToString } from '../queryToString';
import type { AlgoliaHit } from '../types';
import { useConnector } from 'react-instantsearch-core';
import { connectRadiusGeoSearch } from '@virginexperiencedays/search/src/hooks/useGeoSearch/connectRadiusGeoSearch';

type ReturnUseGeoSearchType = {
  currentRefinement: {
    lat: number;
    lng: number;
  };
  items: AlgoliaHit[];
};

export const useGeoSearch = () => {
  const data = useConnector(connectRadiusGeoSearch as any) as ReturnUseGeoSearchType;
  const router = useRouter();

  const refine = ({
    geoSearchFormatted,
    geoSearch,
  }: {
    geoSearchFormatted: string;
    geoSearch: string;
  }) => {
    const query = router.query;

    const nextQuery = {
      ...query,
      geoSearchFormatted,
      geoSearch,
    };

    // we remove location when using geoSearch as we only allow either location or geoSearch not both at the same time.
    const queryString = decodeURIComponent(queryToString(nextQuery, ['page', 'sort', 'location']));

    router.push(
      {
        query: {
          ...nextQuery,
          sort: null,
          location: null,
          page: 1,
        },
      },
      router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
      {
        shallow: true,
        scroll: false,
      }
    );
  };

  const createURL = (): string => {
    throw new Error('Method not implemented.');
  };

  return {
    ...data,
    refine,
    createURL,
  };
};
