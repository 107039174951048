import { IconAngleLeft } from '../../icons/IconAngleLeft';
import { LinkWrap } from '../../navigation/LinkWrap';

import { usePagination } from './usePagination';

import { Button } from '../Button';
import { cn } from '@virginexperiencedays/tailwind';

export type PaginationProps = {
  perPage?: number;
  /**
   * Defines total items count
   */
  itemsCount: number;
  currentPage: number;
  className?: string;
  wrapperClassName?: string;
  /**
   * Callback fn that returns url for the page
   */
  renderLink: (page?: number) => string;
  /**
   * Used to perform another actions with links (such as algolia refine function)
   */
  onClick?: (e, page?: number, trackingLabel?: string) => void;
  /**
   * Applies prefetch to the underlying links. No need to specify `true`
   * explicitly, as next/link by default infers whether to prefetch based on
   * viewport.
   *
   * {@see https://nextjs.org/docs/messages/prefetch-true-deprecated}
   */
  withPrefetch?: boolean;
};

const Icon = ({ ...rest }) => <IconAngleLeft className="text-neutral h-3 w-3" {...rest} />;

export const Pagination = ({
  className,
  wrapperClassName,
  itemsCount,
  currentPage,
  renderLink,
  onClick,
  withPrefetch,
  perPage = 9,
}: PaginationProps) => {
  const { currentPagesArray, totalPages, prevButtonDisabled, nextButtonDisabled } = usePagination(
    currentPage,
    itemsCount,
    perPage
  );

  if (itemsCount > perPage) {
    return (
      <div className={cn('flex justify-center', wrapperClassName)}>
        <div className={cn('flex justify-center overflow-hidden rounded shadow-sm', className)}>
          {currentPage - 1 < 1 ? (
            <Button
              className="bg-background-elevation-base hover:bg-background-elevation-base flex h-10 w-10 items-center justify-center p-0"
              data-testid="pagination-prev-button"
              disabled={prevButtonDisabled}
            >
              <Icon />
            </Button>
          ) : (
            <LinkWrap
              className="hover:bg-background-primary-faded flex h-10 w-10 items-center justify-center rounded-sm bg-inherit hover:text-white focus:text-white"
              dataTestId="pagination-prev-button"
              href={renderLink(currentPage - 1)}
              prefetch={withPrefetch}
              onClick={onClick ? (e) => onClick(e, currentPage - 1, 'Prev Page') : null}
            >
              <Icon />
            </LinkWrap>
          )}

          <div className="flex" data-testid="pagination-desktop-container">
            {currentPagesArray.map((page) => {
              if (page === '...') {
                return (
                  <span
                    className="flex h-10 w-10 items-center justify-center"
                    key={page}
                    data-testid="pagination-dotts"
                  >
                    {page}
                  </span>
                );
              }
              return currentPage === page ? (
                <Button
                  className="bg-background-primary flex h-10 w-10 items-center justify-center rounded-sm text-center text-sm font-semibold text-white"
                  size="small"
                  key={page}
                  data-testid={`pagination-page-button-${page}`}
                >
                  {page}
                </Button>
              ) : (
                <LinkWrap
                  className="text-neutral hover:bg-background-primary-faded focus:bg-background-primary-faded flex h-10 w-10 items-center justify-center rounded-sm px-4 py-2 text-sm font-semibold"
                  dataTestId={`pagination-page-button-${page}`}
                  href={renderLink(Number(page))}
                  prefetch={withPrefetch}
                  onClick={onClick ? (e) => onClick(e, Number(page), 'Specific Page') : null}
                  key={page}
                >
                  {page}
                </LinkWrap>
              );
            })}
          </div>
          {currentPage === totalPages ? (
            <Button
              className="bg-background-elevation-base hoveR:bg-background-elevation-base flex h-10 w-10 rotate-180 items-center justify-center p-0"
              data-testid="pagination-next-button"
              disabled={nextButtonDisabled}
            >
              <Icon />
            </Button>
          ) : (
            <LinkWrap
              className="hover:bg-background-primary-faded flex h-10 w-10 rotate-180 items-center justify-center rounded-sm bg-inherit hover:text-white focus:text-white"
              dataTestId="pagination-next-button"
              href={renderLink(currentPage + 1)}
              prefetch={withPrefetch}
              onClick={onClick ? (e) => onClick(e, currentPage + 1, 'Next Page') : null}
            >
              <Icon />
            </LinkWrap>
          )}
        </div>
      </div>
    );
  }
  return null;
};
