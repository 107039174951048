/**
 * A Promisified version of Navigator.geolocation.getCurrentPosition, so we
 * avoid nested callbacks.
 *
 * {@see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition}
 */
export const getCurrentPosition = (geolocation: Geolocation) =>
  new Promise<GeolocationPosition>((resolve, reject) =>
    geolocation.getCurrentPosition(resolve, reject)
  );

// create minimal types, as Google does not provide types for its APIs
type GeocodeCallback = (results: any[], status: string) => void;
type Geocoder = {
  geocode: (options: any, callback: GeocodeCallback) => void;
};

/**
 * A Promisified version of Google Maps Geocoder's geocode method, so we avoid
 * nested callbacks. The statuses are also handled nicely as resolve and reject
 * cases.
 *
 * {@see https://developers.google.com/maps/documentation/javascript/geocoding}
 */
export const geocode = (geocoder: Geocoder, options?: any) =>
  new Promise<any[]>((resolve, reject) =>
    geocoder.geocode(options, (results, status) => {
      switch (status) {
        case 'ZERO_RESULTS': // falls through
        case 'OK':
          return resolve(results);
        case 'OVER_QUERY_LIMIT': // falls through
        case 'REQUEST_DENIED': // falls through
        case 'INVALID_REQUEST': // falls through
        case 'UNKNOWN_ERROR': // falls through
        case 'ERROR':
          return reject(status);
        default:
          return reject(new Error(`Returned unknown status: ${status}`));
      }
    })
  );
