export const IconSearch = ({
  className = null,
  width = '1em',
  height = '1em',
  fill = '#121826',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6 2.8a4.8 4.8 0 100 9.6 4.8 4.8 0 000-9.6zM.4 7.6a7.2 7.2 0 1113.068 4.172l5.78 5.78a1.2 1.2 0 01-1.696 1.697l-5.78-5.779A7.2 7.2 0 01.4 7.6z"
        fill={fill}
      />
    </svg>
  );
};

export const IconSearchNew = ({
  className = null,
  width = '18',
  height = '18',
  fill = 'var(--text-neutral-strong)',
  strokeWidth = '1.5',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.4997 16.5002L12.1688 12.1693M12.1688 12.1693C13.341 10.9972 13.9995 9.40741 13.9995 7.74975C13.9995 6.09208 13.341 4.50231 12.1688 3.33016C10.9967 2.15802 9.40692 1.49951 7.74926 1.49951C6.09159 1.49951 4.50182 2.15802 3.32967 3.33016C2.15753 4.50231 1.49902 6.09208 1.49902 7.74975C1.49902 9.40741 2.15753 10.9972 3.32967 12.1693C4.50182 13.3415 6.09159 14 7.74926 14C9.40692 14 10.9967 13.3415 12.1688 12.1693Z"
        stroke={fill}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
