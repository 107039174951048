import React from 'react';
import type { MenuItem } from 'instantsearch.js/es/connectors/menu/connectMenu';

import { refinementList } from '@virginexperiencedays/search/constants';
import { LabelText, NumberOfItems, RefineButton } from '../FacetsDrawer/RefineButton';

type PriceRangeFilterProps = {
  items: MenuItem[];
  onClick: (value: string) => void;
};

export const PriceRangeFilter = ({ items, onClick }: PriceRangeFilterProps) => {
  return (
    <div className="flex flex-col gap-4" data-testid="price-range-list">
      {items.map(({ count, value, label }) => {
        const dataInsightsFilter = `${refinementList.PRICE_RANGE}:${value}`;
        return (
          <RefineButton
            key={`${count}/${value}`}
            data-testid="price-range-item"
            data-insights-filter={dataInsightsFilter}
            onClick={() => onClick(value)}
          >
            <LabelText>{label}</LabelText>
            <NumberOfItems count={count} />
          </RefineButton>
        );
      })}
    </div>
  );
};
