import { cn } from '@virginexperiencedays/tailwind';

import { OptionBadge, OptionItemProps, OptionPillItem, OptionText } from '../Options';
import { IconAngleDownNew } from '../../icons/IconAngleDown';

import type { HTMLAttributes, ReactNode } from 'react';

type PillMenuProps = OptionItemProps & {
  /**
   * Pill Text
   */
  text: string;
  /**
   * Count Badge
   */
  count?: number;
  /**
   * Icon
   */
  icon?: ReactNode;
  /**
   * Flag for applying active styles-- pink button, red text, red badge
   */
  checked?: boolean;
  /**
   * Flag for adding down arrow icon. If `hasArrow` is true, and `count` has value, count badge will take place of the down arrow
   */
  hasArrow?: boolean;
  /**
   * text class name
   */
  textClassName?: string;
};

export const PillMenu = ({
  text,
  count,
  checked,
  className,
  textClassName,
  onClick,
  icon,
  hasArrow = false,
  ...rest
}: PillMenuProps) => (
  <OptionPillItem
    {...rest}
    checked={checked}
    className={cn(
      'group/pill gap-1 px-2 py-3 text-sm leading-4',
      'md:hover:text-persistent-text-dark md:hover:border-persistent-background-primary-faded-hover md:hover:bg-persistent-background-primary-faded',
      className
    )}
    onClick={onClick}
  >
    {icon}
    <OptionText className={textClassName}>{text}</OptionText>
    {!!count && <OptionBadge checked>{count}</OptionBadge>}
    {hasArrow && <IconAngleDownNew stroke="currentColor" strokeWidth="1.4" />}
  </OptionPillItem>
);

type MenuDropdownProps = {
  className?: string;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const MenuDropdown = ({ className, children, ...rest }: MenuDropdownProps) => {
  return (
    <div
      className={cn(
        'bg-background-page p-4 shadow-lg',
        'dark:border-border-neutral rounded-lg border border-solid border-transparent',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export const MenuDropdownContent = ({ className, children, ...rest }: MenuDropdownProps) => {
  return (
    <div className={cn('mega-nav-scrollbar max-h-[40dvh] overflow-y-auto', className)} {...rest}>
      {children}
    </div>
  );
};

export const MenuDropdownFooter = ({ className, children, ...rest }: MenuDropdownProps) => {
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};
