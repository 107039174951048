import { facets } from '@virginexperiencedays/search/constants';

/**
 * Checks if the given query object contains any faceted search parameters.
 *
 * A faceted search parameter is determined by checking if the key is included
 * in the predefined list of facets and if the corresponding value is non-empty.
 *
 * @param query - The query object to check, where keys are parameter names and
 *                values are either strings or arrays of strings.
 * @returns `true` if the query contains any faceted search parameters, otherwise `false`.
 */
export const isQueryFaceted = (query: Record<string, string | string[]>): boolean =>
  Object.entries(query).some(([k, v]) => facets.includes(k) && !!(v?.length > 0));
