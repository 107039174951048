import { facets } from '@virginexperiencedays/search/constants';

/**
postfix - A function that takes in a list name and a URL and returns the list name with a postfix indicating whether filtering and/or sorting is applied to the list.
@param {string} list - The name of the list.
@param {string} url - The URL used to filter and sort the list.
@returns {string} The list name with a postfix indicating whether filtering and sorting are applied to the list.

Example:
postfix("Driving Experiences", "/driving?occasion=birthdays&sort=price") 
returns "Driving Experiences | filtered and sorted"

postfix("Driving Experiences", "/driving?sort=price") 
returns "Driving Experiences | sorted"

postfix("Driving", "/driving") 
returns "Driving Experiences"
*/
export function postfix(list: string, asPath: string) {
  if (asPath.startsWith('/cor/')) return list;

  const urlObj = new URL(asPath, 'http://somedummyurl.huh/');
  let postfix = '';
  if (
    facets
      .filter((facet) => facet !== 'page')
      .some((facet) => urlObj.searchParams.has(facet) && facet !== 'sort')
  )
    postfix = 'filtered';
  if (urlObj.searchParams.has('sort')) postfix += (postfix.length > 0 ? ' and ' : '') + 'sorted';
  return postfix.length ? `${list} | ${postfix}` : list;
}
