import { cn } from '@virginexperiencedays/tailwind';

import { Button } from '../layout/Button';
import { BodyText } from '../typography/BodyText';
import { IconFilter } from '../icons/IconFilter';
import { IconSortBy } from '../icons/IconSortBy';

type ListingControlsProps = {
  handleFilterClick: () => void;
  handleSortByClick: () => void;
  className?: string;
  dataTestId?: string;
  productCount?: number;
  filterCount?: number;
};

export const ListingControls = ({
  className,
  productCount,
  handleFilterClick,
  handleSortByClick,
  filterCount = 0,
  dataTestId = 'listing-controls',
}: ListingControlsProps) => {
  return (
    <div
      className={cn(
        'border-border-neutral flex items-center justify-between border-t py-4',
        className
      )}
      data-testid={dataTestId}
    >
      <div className="text-neutral-faded text-sm leading-none">{productCount} Products</div>
      <div className="flex-rol flex gap-1">
        <Button
          className="bg-background-elevation-raised text-neutral-strong sm:hover:bg-background-neutral-faded sm:hover:text-neutral sm:focus:bg-background-elevation-raised sm:focus:text-neutral-strong flex shrink-0 flex-row items-center justify-center gap-1 rounded-sm p-2"
          onClick={handleFilterClick}
          variant="invisible"
          data-testid="filter-button"
        >
          <IconFilter />
          <BodyText className="text-sm font-semibold leading-none">Filters</BodyText>
          {filterCount > 0 && (
            <div className="bg-background-primary rounded-sm p-1" data-testid="filter-count">
              <BodyText className="text-xs font-semibold leading-none text-white">
                {filterCount}
              </BodyText>
            </div>
          )}
        </Button>
        <Button
          className="bg-background-elevation-raised text-neutral-strong sm:hover:bg-background-neutral-faded sm:hover:text-neutral sm:focus:bg-background-elevation-raised sm:focus:text-neutral-strong flex shrink-0 flex-row items-center justify-center gap-1 rounded-sm p-2"
          onClick={handleSortByClick}
          variant="invisible"
          data-testid="sort-button"
        >
          <IconSortBy />
          <BodyText className="text-sm font-semibold leading-none">Sort By</BodyText>
        </Button>
      </div>
    </div>
  );
};
