import { cn } from '@virginexperiencedays/tailwind';

import { Button, type ButtonProps } from '@virginexperiencedays/components-v2/src/layout/Button';
import {
  BodyText,
  type BodyTextProps,
} from '@virginexperiencedays/components-v2/src/typography/BodyText';

export const RefineButton = ({ className, children, ...rest }: ButtonProps) => (
  <Button
    className={cn(
      'flex w-full items-baseline rounded-none border-0 bg-transparent p-0 text-left text-base font-normal leading-relaxed text-neutral no-underline',
      className
    )}
    variant="invisible"
    {...rest}
  >
    {children}
  </Button>
);

export const LabelText = ({ children, ...rest }: BodyTextProps) => (
  <BodyText className="leading-none text-neutral hover:cursor-pointer hover:text-link" {...rest}>
    {children}
  </BodyText>
);

export const NumberOfItems = ({ count = 0 }: { count: number }) => (
  <span className="ml-1 leading-none text-neutral-faded md:text-sm md:leading-none">({count})</span>
);
