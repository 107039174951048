import { cn } from '@virginexperiencedays/tailwind';
import { useAspectRatioSupported } from '@virginexperiencedays/hooks';

import { DisplayHeading, type DisplayHeadingProps } from '../../typography/DisplayHeading';
import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { roundPrice } from '../../utils/roundPrice';
import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  ResponsiveAspectRatio,
} from '../../utils/mapAspectRatio';

export type FeaturedProductCardProps = {
  /** Title of the category */
  title: string;
  /** Image src to be displayed in the card */
  src: string;
  /** Href of the card */
  href: string;
  /** Discounted price */
  displayPrice: number;
  /** Aspect Ratio of the card (responsive props) */
  aspectRatio: ResponsiveAspectRatio;
  /** SEO Override */
  titleAs?: DisplayHeadingProps['as'];
  /** contentList */
  contentList?: string[];
  className?: string;
  /** alt of the image */
  alt?: string;
  /** Regular Price */
  rrp?: number;
  /** display find out more text on the card */
  isFindOutMore?: boolean;
  /** Product promotional code */
  promocode?: string;
  /** Product sku */
  sku: string;
  productId?: string;
  hasZoomOnHover?: boolean;
};

export const FeaturedProductCard = ({
  productId,
  title,
  src,
  href,
  displayPrice,
  titleAs,
  contentList,
  className,
  alt,
  rrp,
  isFindOutMore,
  promocode,
  sku,
  aspectRatio = '16/9',
  hasZoomOnHover = true,
}: FeaturedProductCardProps) => {
  const { isSupported } = useAspectRatioSupported();
  const isDescription = contentList?.length > 0;

  return (
    <LinkWrap
      href={href}
      className={cn('group relative flex h-full w-full flex-col gap-2', className)}
      data-testid="featured-product-card"
      data-productid={productId}
    >
      <div className="absolute h-full w-full overflow-hidden rounded">
        <Image
          className={cn(
            'image:object-cover h-full transition-transform',
            hasZoomOnHover && 'group-hover:scale-105',
            isSupported && mapAspectRatio(aspectRatio),
            !isSupported && mapAspectRatioPolyfill(aspectRatio)
          )}
          fill
          dataTestId="featured-product-card-image"
          data-productid={promocode ? `${sku} ${promocode}` : sku}
          src={src}
          alt={alt ?? title}
        />

        <div
          className="bg-background-primary absolute left-0 top-0 rounded-sm px-2 py-0.5 text-xs font-semibold uppercase text-white"
          data-testid="featured-product-card-featured-tag"
        >
          Featured
        </div>
      </div>

      <div
        className="relative mt-auto flex flex-col gap-1 rounded-b bg-gradient-to-b from-transparent to-black/75 p-4 text-white transition-colors"
        data-testid="featured-product-card-content-container"
      >
        <DisplayHeading
          className={cn(
            'mb-2 leading-normal text-white drop-shadow',
            isDescription ? 'text-base' : 'text-sm'
          )}
          as={titleAs}
          data-testid="featured-product-card-title"
        >
          {title}
        </DisplayHeading>
        {rrp && rrp !== displayPrice ? (
          <div className="flex flex-nowrap items-baseline gap-2">
            <DisplayPrice
              data-testid="featured-product-card-new-price"
              isDescription={isDescription}
            >
              {roundPrice(displayPrice)}
            </DisplayPrice>
            <span
              className="text-sm leading-none text-white line-through opacity-80"
              data-testid="featured-product-card-old-price"
            >
              {roundPrice(rrp)}
            </span>
          </div>
        ) : (
          <DisplayPrice data-testid="featured-product-card-price" isDescription={isDescription}>
            {roundPrice(displayPrice)}
          </DisplayPrice>
        )}
        {isFindOutMore && (
          <DisplayHeading size="5" className="mt-2 leading-normal text-white">
            Find out more
            <span className="ml-2.5">
              <ChevronSVG />
            </span>
          </DisplayHeading>
        )}
      </div>
    </LinkWrap>
  );
};

const DisplayPrice = ({ isDescription, children, ...rest }) => {
  return (
    <span
      className={cn(
        'font-semibold leading-none text-white',
        isDescription ? 'text-base' : 'text-xl'
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

const ChevronSVG = () => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.800781 0.800781L4.00078 4.00078L0.800781 7.20078"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
