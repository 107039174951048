import { cn } from '@virginexperiencedays/tailwind';

import { StarRating } from '../../../../StarRating';

type RationOptionProps = {
  item: { value: string; count: number };
  itemIndex: number;
  selectedFilterIndex: number | undefined;
  className?: string;
  showBadge?: boolean;
};

export const getReviewRangeNumber = (value: string) => Number(value.split('-')[0]) || 1;

//@TODO: refactor this, way overcomplicated for such a simple thing
// fe. provide stars via parent or allow composition
export const RatingOption = ({
  item,
  itemIndex,
  selectedFilterIndex,
  className,
  showBadge,
}: RationOptionProps) => {
  const setShadedColor = (index: number) => {
    if (!isNaN(selectedFilterIndex) && selectedFilterIndex === index) {
      return 'data-[value=filled]:fill-brand text-brand';
    } else if (!isNaN(selectedFilterIndex) && selectedFilterIndex !== index) {
      return 'data-[value=filled]:fill-neutral-faded text-neutral-faded';
    } else {
      return 'data-[value=filled]:fill-neutral-strong text-strong';
    }
  };

  const setEmptyColor = (index: number) => {
    if (!isNaN(selectedFilterIndex) && selectedFilterIndex === index) {
      return 'data-[value=empty]:fill-background-primary-faded';
    } else {
      return 'data-[value=empty]:fill-background-disabled';
    }
  };

  const setBadgeColor = (index: number) => {
    if (!isNaN(selectedFilterIndex) && selectedFilterIndex === index) {
      return 'text-primary-100';
    } else if (!isNaN(selectedFilterIndex) && selectedFilterIndex !== index) {
      return 'text-grey-400';
    } else {
      return 'text-neutral';
    }
  };

  return (
    <div className={cn('flex items-center justify-start gap-2 whitespace-nowrap', className)}>
      <StarRating
        rating={getReviewRangeNumber(item.value)}
        // forward classnames as we use group-hover to target and its not worth to explicitly pass as props for each, and we rather target using data-value=filled/empty attr
        shadedColor={cn(setShadedColor(itemIndex), className)}
        emptyColor={cn(setEmptyColor(itemIndex), className)}
      />
      <span
        className={cn(
          'text-base leading-none',
          selectedFilterIndex === itemIndex ? 'font-semibold' : 'font-normal',
          setShadedColor(itemIndex)
        )}
      >
        & Up
      </span>
      {showBadge && (
        <span
          className={cn(
            'rounded px-1 py-0.5 text-xs font-semibold leading-none',
            setBadgeColor(itemIndex),
            selectedFilterIndex === itemIndex
              ? 'bg-background-primary'
              : 'bg-background-neutral-faded'
          )}
        >
          {item.count}
        </span>
      )}
    </div>
  );
};
