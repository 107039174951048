import { indexNames } from '@virginexperiencedays/search/constants';

export const sortingOptions = [
  {
    label: 'Most Popular',
    value: indexNames.DEFAULT,
  },
  {
    label: 'Price (low to high)',
    value: indexNames.PRICE_ASC,
  },
  {
    label: 'Price (high to low)',
    value: indexNames.PRICE_DSC,
  },
  {
    label: 'Newest',
    value: indexNames.NEWEST,
  },
  {
    label: 'Rating',
    value: indexNames.TOP_RATED,
  },
  {
    label: 'Most Reviewed',
    value: indexNames.MOST_REVIEWED,
  },
];
