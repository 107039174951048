import { categoryTypes } from '@virginexperiencedays/search/constants';

export const defaultViews = new Map([
  [1, 'standard'],
  [2, 'detailed'],
  [3, 'detailed'],
]);

export const getHierarchicalViews = (categoryType: categoryTypes) => {
  switch (categoryType) {
    case categoryTypes.CATEGORY: // falls through
    case categoryTypes.LOCATION:
      return defaultViews;
    case categoryTypes.OCCASION:
      return new Map([
        [1, 'standard'],
        [2, 'standard'],
        [3, 'detailed'],
      ]);
    default:
      return defaultViews;
  }
};

export const isProductGridMinimal = ({
  categoryType,
  categoryLevel,
}: {
  categoryType: categoryTypes;
  categoryLevel: number;
}) => {
  const categoryViews = getHierarchicalViews(categoryType);

  return categoryViews.get(categoryLevel) === 'standard';
};

export const getColumnCounts = (isMinimal: boolean, isProductCardsV2?: boolean) => {
  if (isProductCardsV2) {
    return {
      mobile: 2,
      tablet: 2,
      desktop: 3,
      wide: 5,
    } as const;
  }

  return {
    mobile: 1,
    tablet: 2,
    desktop: 3,
    wide: isMinimal ? 5 : 4,
  } as const;
};
