import { cn } from '@virginexperiencedays/tailwind';

import type { HTMLAttributes, ButtonHTMLAttributes } from 'react';
import { StyledRadio } from '../Radio';
import { StyledCheckbox } from '../Checkbox';

// This follows Composition Pattern + Atomic Design
// Usage Reference: See implementation from web/components/pages/search/filters and in layout/Options storybook template mocks

// Parent container for options group and option toggle
export const Options = ({ className, children, ...rest }: HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={cn('flex flex-col gap-4', className)}>
    {children}
  </div>
);

// list of option pills/radio/check
export const OptionsGroup = ({
  className,
  children,
  isColumn,
  ...rest
}: HTMLAttributes<HTMLUListElement> & {
  isColumn?: boolean;
}) => (
  <ul
    {...rest}
    className={cn(
      isColumn ? 'grid grid-cols-1 gap-x-4 gap-y-0' : 'flex flex-wrap gap-2',
      className
    )}
  >
    {children}
  </ul>
);

// toggle for expanding/collapsing options
export const OptionToggle = ({
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLButtonElement>) => (
  <button
    {...rest}
    className={cn(
      'text-neutral select-none text-left text-sm leading-[18px] underline underline-offset-2',
      className
    )}
  >
    {children}
  </button>
);

export type OptionItemProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
};

// list item wrapper for option pills/radio/check
const OptionItem = ({ className, children, checked, disabled, ...rest }: OptionItemProps) => (
  <button
    {...rest}
    disabled={disabled}
    className={cn(
      'text-neutral flex cursor-pointer select-none items-center gap-2',
      checked && 'text-brand',
      disabled && 'cursor-not-allowed opacity-50',
      className
    )}
  >
    {children}
  </button>
);

// actual list item for option pills
export const OptionPillItem = ({
  className,
  children,
  checked,
  disabled,
  ...rest
}: OptionItemProps) => (
  <OptionItem
    {...rest}
    checked={checked}
    className={cn(
      'relative h-10 rounded border border-solid px-2 py-3 text-sm leading-4',
      'border-grey-400 bg-background-page',
      checked && 'border-primary-200 bg-primary-100',
      disabled && 'cursor-not-allowed opacity-50',
      className
    )}
    disabled={disabled}
  >
    {children}
  </OptionItem>
);

// actual list item for option radio/checkboxes
export const OptionListItem = ({
  className,
  children,
  checked,
  isSingleSelect,
  disabled,
  ...rest
}: OptionItemProps & {
  isSingleSelect?: boolean;
}) => (
  <OptionItem
    {...rest}
    className={cn('relative w-full py-2 text-sm leading-none', className)}
    checked={checked}
    disabled={disabled}
  >
    <OptionInput isSingleSelect={isSingleSelect} checked={checked} disabled={disabled} />
    <div
      className={cn('flex flex-1 items-center gap-2', disabled && 'cursor-not-allowed opacity-50')}
    >
      {children}
    </div>
  </OptionItem>
);

type OptionInputProps = {
  checked?: boolean;
  disabled?: boolean;
};

// element for rendering radio if single select options, and checkbox for multi select options
export const OptionInput = ({
  isSingleSelect,
  ...rest
}: OptionInputProps & {
  isSingleSelect?: boolean;
}) => (isSingleSelect ? <StyledRadio {...rest} /> : <StyledCheckbox {...rest} />);

// element for styled text for option, renders in truncated 1 line
export const OptionText = ({ className, children, ...rest }: HTMLAttributes<HTMLSpanElement>) => (
  <span
    {...rest}
    className={cn(
      'line-clamp-1 break-all py-px text-left text-base leading-none md:text-sm md:leading-none',
      className
    )}
  >
    {children}
  </span>
);

// element for styled badge for option pills/radio/check used for count
export const OptionBadge = ({
  checked,
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLSpanElement> & {
  checked?: boolean;
}) => (
  <span
    {...rest}
    className={cn(
      'bg-grey-100 text-persistent-text-dark rounded px-1 py-0.5 text-xs font-semibold leading-none',
      checked && 'bg-background-primary text-white',
      className
    )}
  >
    {children}
  </span>
);
