/*
 * Wrapper around original useSortBy from instantsearch, with swapped logic for `refine` method
 */
import { useRouter } from 'next/compat/router';
import { useSortBy as risUseSortBy, type UseSortByProps } from 'react-instantsearch';

import { queryToString } from '../queryToString';

export const useSortBy = (props: UseSortByProps) => {
  const { refine: risRefine, ...rest } = risUseSortBy(props);
  const router = useRouter();

  const refine = (value: Parameters<typeof risRefine>[0]) => {
    const query = router.query;

    const nextQuery = {
      ...query,
      sort: value,
    };
    const queryString = decodeURIComponent(queryToString(nextQuery));

    router.push(
      {
        query: {
          ...nextQuery,
          page: 1, // do not set to 0 to prevent crashing on paginated clp (page/[page]/[...slug] ) - if page null this will crash -- non visible to the user anyway
        },
      },
      router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
      {
        shallow: true,
      }
    );
  };

  return {
    ...rest,
    refine,
  };
};
