import type { RouteParams } from '@virginexperiencedays/search/types';
import {
  categoriesHierarchyAttributeArray,
  occasionsHierarchyAttributeArray,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
} from '@virginexperiencedays/search/constants';

type QueryParam = 'category' | keyof RouteParams;

export function getQueryParamAttribute(queryParam: QueryParam) {
  switch (queryParam) {
    case 'category':
      return categoriesHierarchyAttributeArray[0];
    case 'occasion':
      return occasionsHierarchyAttributeArray[0];
    case 'feature':
      return featuresHierarchyAttributeArray[0];
    case 'location':
      return locationsHierarchyAttributeArray[0];
  }
}
