/*
 * Wrapper around original useRefienementList from instantsearch, with swapped logic for `refine` method
 */
import { MAX_VALUES_PER_FACET } from '@virginexperiencedays/search/constants';
import { useRouter } from 'next/compat/router';
import {
  useRefinementList as risUseRefinementList,
  type UseRefinementListProps,
} from 'react-instantsearch';
import { queryToString } from '../queryToString';
import { STATE_TO_ROUTE } from '../stateToRoute';

export const useRefinementList = ({
  attribute,
  ...restProps
}: {
  attribute: keyof typeof STATE_TO_ROUTE;
} & UseRefinementListProps) => {
  const { refine: risRefine, ...rest } = risUseRefinementList({
    attribute,
    limit: restProps.limit || MAX_VALUES_PER_FACET,
    ...restProps,
  });

  const router = useRouter();

  const refine = (value: Parameters<typeof risRefine>[0]) => {
    const query = router.query;
    const param = STATE_TO_ROUTE[attribute];
    const valueAtParam = query[param] || [];
    const normalizedValueAtParam = Array.isArray(valueAtParam)
      ? valueAtParam
      : valueAtParam?.split(',');
    // Toggle refinement if already in the list
    const nextValue = normalizedValueAtParam?.includes(value)
      ? normalizedValueAtParam.filter((v) => v !== value)
      : [...normalizedValueAtParam, value];

    const nextQuery = {
      ...query,
      [param]: nextValue,
    };

    const queryString = decodeURIComponent(
      queryToString(
        nextQuery,
        // we remove geoSearch when param is locatoin as we only allow either location or geoSearch not both at the same time.
        param === 'location' ? ['geoSearch', 'geoSearchFormatted', 'page'] : ['page']
      )
    );

    router.push(
      {
        query: {
          ...nextQuery,
          page: 1, // do not set to 0 to prevent crashing on paginated clp (page/[page]/[...slug] ) - if page null this will crash -- non visible to the user anyway
          geoSearchFormatted: param === 'location' ? null : query.geoSearchFormatted,
          geoSearch: param === 'location' ? null : query.geoSearch,
        },
      },
      router.asPath.split('?')[0] + (queryString.length > 0 ? `?${queryString}` : ''),
      {
        shallow: true,
        scroll: false,
      }
    );
  };

  const createURL = () => {
    throw new Error('Not implemented');
  };

  return {
    ...rest,
    refine,
    createURL,
  };
};
