import { EXCLUDED_FACETS, EXCLUDED_SORT_OPTIONS } from '@virginexperiencedays/search/constants';

export const getAttributesToRender = (slug: string, attributes: string[]) => {
  const excluded = EXCLUDED_FACETS?.[slug];

  if (!excluded) return attributes;

  return attributes.filter((attr) => !excluded.includes(attr));
};

export const getSortOptionsToRender = (
  slug: string,
  options: { label: string; value: string }[]
) => {
  const excluded = EXCLUDED_SORT_OPTIONS?.[slug];

  if (!excluded) return options;

  return options.filter(({ value }) => !excluded.includes(value));
};
