import {
  calculateResponsiveValue,
  createLookUpResponsiveArray,
  ResponsiveKeysType,
} from '@virginexperiencedays/header-2024/src/utils/calculateResponsiveValue';
import { getColumnCounts } from '../productGridView';

export const getColumnWidth = (
  view: 'mobile' | 'largeMobile' | 'tablet' | 'desktop' | 'wide',
  isMinimal: boolean,
  isProductCardsV2?: boolean
) =>
  calculateResponsiveValue(
    getColumnCounts(isMinimal, isProductCardsV2),
    createLookUpResponsiveArray(view) as ResponsiveKeysType
  );
