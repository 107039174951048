import { useRouter } from 'next/compat/router';

import { cn } from '@virginexperiencedays/tailwind';
import { categoryTypes } from '@virginexperiencedays/search/constants';

import { Grid } from '@virginexperiencedays/components-v2/src/layout/Grid';

import { mapHitsToProducts } from '../../../../utils/search/mapHitsToProducts';

import useStorageCardDisplay from '../utils/hooks/useCardDisplay';
import { PaginationWidget } from '../PaginationWidget';
import { getColumnCounts, isProductGridMinimal } from './productGridView';
import { GridEmbed, EmbedType } from './GridEmbed';
import { GiftCard } from './GiftCard';
import {
  type ProductCardTrackingProps,
  ProductGridCard as ProductCard,
  useIsProductCardsV2,
} from '../ProductCard';

import type { MutableRefObject } from 'react';

type ProductGridProps = {
  url: string;
  categoryType: categoryTypes;
  hasResults?: boolean;
  numberOfHits?: number;
  refElementToScroll?: MutableRefObject<HTMLElement>;
  embeds?: EmbedType[];
  hasEmbeds: boolean;
  slugs?: string[];
  isFaceted?: boolean;
  priority?: boolean;
  tracking?: ProductCardTrackingProps;
};

const PREFETCH_NEXT_LINK = false;

export const ProductGrid = ({
  url,
  categoryType,
  hasResults = true,
  numberOfHits,
  embeds,
  refElementToScroll,
  hasEmbeds,
  slugs,
  isFaceted,
  priority = false,
  tracking,
}: ProductGridProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  const { cardDisplay } = useStorageCardDisplay(categoryType);

  const normalizedSlugs = Array.isArray(router?.query.slug)
    ? router?.query?.slug?.filter((slug) => slug !== 'plp' && slug !== 'page')
    : router?.query?.slug?.split('/');

  const isMinimal = cardDisplay
    ? cardDisplay === 'standard'
    : isProductGridMinimal({
        categoryType,
        categoryLevel: normalizedSlugs.length ?? 1,
      });

  const productData = mapHitsToProducts(url);
  let featuredProductsAlreadyInGrid = 0;

  // Temporary solution until we get support from Algolia
  if (hasEmbeds) {
    embeds?.forEach((embed) => {
      if (embed.type === 'FeaturedProduct') {
        const index = productData.findIndex(
          (product) =>
            embed.props.sku === `${product.sku} ${product.promocode}` ||
            embed.props.sku === product.sku
        );
        if (index > -1) {
          productData.splice(index, 1);
          featuredProductsAlreadyInGrid++;
        }
      }
    });
  }
  const featuredProductOffsets = calculateFeaturedOffsets(embeds);

  const hasFeaturedProduct = embeds?.some((embed) => embed?.type === 'FeaturedProduct');
  const lastSlug = slugs ? slugs[slugs.length - 1] : null;

  const isV1Minimal = isMinimal && !isProductCardsV2;

  const columnsObj = getColumnCounts(isMinimal, isProductCardsV2);

  const titleClassName = isProductCardsV2 ? 'line-clamp-3' : '';

  return (
    <div className={hasResults ? 'block' : 'hidden'}>
      <Grid
        className={cn(
          isProductCardsV2 ? 'gap-4' : 'gap-6 ',
          'md:gap-y-10',
          isV1Minimal && '-mx-4 grid-cols-none md:mx-0'
        )}
        dataTestId="product-grid"
        elementTagAs="ul"
        columns={columnsObj}
        space={{
          mobile: 'lg',
        }}
        spaceY="xl"
      >
        {productData.map((item, index) => {
          return (
            <li className={cn('md:mx-0', isV1Minimal && 'mx-4')} key={item.key}>
              <ProductCard
                index={index}
                priority={priority}
                isMinimal={isMinimal}
                item={item}
                tracking={{ ...(tracking || {}), module: tracking?.module || 'product_grid' }}
                titleClassName={titleClassName}
                prefetch={PREFETCH_NEXT_LINK}
              />
            </li>
          );
        })}
        {hasEmbeds && !isFaceted && (
          <>
            {embeds?.map((embed) => (
              <GridEmbed
                className="mx-0"
                isMinimal={isV1Minimal}
                key={`${embed.props.sku}-${embed.pos.column.start}-${embed.pos.rowPosition}`}
                embed={embed}
              />
            ))}
            {hasFeaturedProduct && (
              <GiftCard
                lastSlug={lastSlug}
                offsets={{
                  desktop: featuredProductOffsets.desktopOffset,
                  mobile: featuredProductOffsets.mobileOffset,
                }}
                isMinimal={isV1Minimal}
                productsLength={productData.length}
                featuredProductsAlreadyInGrid={featuredProductsAlreadyInGrid}
                titleClassName={titleClassName}
                prefetch={PREFETCH_NEXT_LINK}
              />
            )}
          </>
        )}
      </Grid>
      <div className="my-[72px]">
        <PaginationWidget
          url={url}
          numberOfHits={numberOfHits}
          refElementToScroll={refElementToScroll}
        />
      </div>
    </div>
  );
};

// Calculate the offsets for the featured products excluding those that share the same position due to user error
const calculateFeaturedOffsets = (embeds: EmbedType[]) =>
  embeds
    ?.filter(({ type }) => type === 'FeaturedProduct')
    ?.reduce(
      (
        { mobileOffset, desktopOffset, mobilePos, desktopPos },
        {
          pos: {
            rowPosition,
            mobileRowPosition,
            column: { start },
          },
        }
      ) => {
        const currentDesktopPos = `${rowPosition || 1} ${start || 1}`;
        const currentMobilePos = `${mobileRowPosition || 1}`;

        const newDesktopPos = !desktopPos.includes(currentDesktopPos)
          ? [...desktopPos, currentDesktopPos]
          : desktopPos;
        const newDesktopOffset = !desktopPos.includes(currentDesktopPos)
          ? desktopOffset + 1
          : desktopOffset;

        const newMobilePos = !mobilePos.includes(currentMobilePos)
          ? [...mobilePos, currentMobilePos]
          : mobilePos;
        const newMobileOffset = !mobilePos.includes(currentMobilePos)
          ? mobileOffset + 1
          : mobileOffset;

        return {
          desktopPos: newDesktopPos,
          mobilePos: newMobilePos,
          desktopOffset: newDesktopOffset,
          mobileOffset: newMobileOffset,
        };
      },
      { mobileOffset: 0, desktopOffset: 0, mobilePos: [], desktopPos: [] }
    );
