import type { NextRouter } from 'next/router';
import type { CurrentRefinementsConnectorParamsItem } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';

import { trackMapping, facets, categoryTypes } from '@virginexperiencedays/search/constants';
import type { RouteParams, Refinement } from '@virginexperiencedays/search/types';

import { BodyText } from '@virginexperiencedays/components-v2/src/typography/BodyText';

import { removeQueryStringFromPath } from '../../../../utils/routing/paths';

import { useCategoryPageContext } from '../CategoryListingPage/CategoryPageContext';

import { filter as track } from '../tracking/interactions';

import { formatQuery, formatRefinements } from './utils';

import { ViewProductsCTA } from './ViewProductsCTA';
import { Pill } from './Pill';
import { normalizeSlug } from '../../../../utils/routing/normalizeSlug';

type CurrentRefinementsWidgetProps = {
  hideDrawer: () => void;
  currentRefinements: CurrentRefinementsConnectorParamsItem[];
  refine: any; //@TODO: this should be removed after new filters release
  router: NextRouter;
  nbHits: number;
  categoryType: categoryTypes;
};

export const CurrentRefinementsWidget = ({
  hideDrawer,
  currentRefinements,
  refine,
  router,
  nbHits,
  categoryType,
}: CurrentRefinementsWidgetProps) => {
  const { seoOverridesUrl } = useCategoryPageContext();
  const isSRP = categoryType === categoryTypes.SEARCH;
  const slug = removeQueryStringFromPath(router?.asPath, ' / ');
  const refinements = formatRefinements(currentRefinements);

  const handleClick = (refinement: Refinement, query: Partial<RouteParams>) => {
    const isSlug = Array.isArray(query.slug)
      ? query.slug.find((slug) => slug === refinement.label)
      : query.slug === refinement.label;

    const nextQuery = formatQuery(refinement, query, isSRP); // this is mutating original query object

    // This logic is used to go through next data && middleware, and get back to CLP if in the next query there will be no facets
    const isNextQueryFaceted = facets.some(
      (facet) => Object.keys(nextQuery).includes(facet) && facet !== 'page'
    );
    const isQuery = refinement.attribute === 'query';

    refine({
      query: nextQuery,
      shallow: !(isSlug || !isNextQueryFaceted || isQuery),
      slugRemoved: !!isSlug, // ugly as hell but as go for now we pass it as seperate property to force shallow false when removing facets
    });

    track({
      slug,
      // swaps in region for location - remove if query params are switched
      label: `Remove ${trackMapping[refinement.attribute]}`,
    });
  };

  const clearAllFilters = (query: Partial<RouteParams>) => {
    const normalizedSlugs = normalizeSlug(query.slug);
    refine({
      slug: normalizedSlugs?.[0] ?? 'search',
      paramsToRemove: Object.keys(query),
      shallow: false,
    });

    track({ slug, label: 'Remove All Filters' });
  };

  // if it is seo override url we won't show this widget to the user,
  // SEO Override pages are only landing pages, any actions such as facet clicks should take user away to PLP
  if (router && (refinements.length > 1 || isSRP) && !seoOverridesUrl) {
    const { query } = router;

    return (
      <div
        className="sticky bottom-0 -mt-0.5 border-t border-t-border-neutral bg-background-elevation-raised py-6"
        data-testid="refinements"
      >
        <div className="border-b border-border-neutral">
          <div className="flex flex-wrap gap-2 pb-4">
            {refinements.map((refinement) => {
              const { label, value, attribute } = refinement;
              const normalizedSlugs = normalizeSlug(query.slug);
              const isMain = normalizedSlugs?.[0] === label;

              return (
                <Pill
                  isMain={isMain}
                  key={`${label}/${value}`}
                  label={label}
                  onClick={() => handleClick(refinement, query)}
                  attribute={attribute}
                />
              );
            })}
          </div>
          {refinements.length > 2 && (
            <BodyText
              className="mb-4 w-fit leading-none text-neutral underline hover:cursor-pointer hover:text-link md:text-sm "
              onClick={() => clearAllFilters(query)}
            >
              Clear All
            </BodyText>
          )}
        </div>
        <div className="-mt-0.5 bg-background-page">
          <ViewProductsCTA onClick={hideDrawer} nbHits={nbHits} />
        </div>
      </div>
    );
  }

  return null;
};
