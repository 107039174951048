import React, { FC, createContext, useMemo, ReactNode, useContext, useReducer } from 'react';

type Action = { type: 'SET_SLUG_CARD_DISPLAY_MAP'; payload?: any };

type Dispatch = (action: Action) => void;

type CardDisplayState = {
  slugCardDisplayMap: { [key: string]: 'standard' | 'detailed' };
};

type CardDisplayProviderProps = {
  children: ReactNode;
  initialState: CardDisplayState;
};

const CardDisplayContext = createContext<
  { cardDisplayState: CardDisplayState; dispatch: Dispatch } | undefined
>({ cardDisplayState: { slugCardDisplayMap: null }, dispatch: () => {} });

const cardDisplayReducer = (state: CardDisplayState, action: Action) => {
  switch (action.type) {
    case 'SET_SLUG_CARD_DISPLAY_MAP': {
      return {
        ...state,
        slugCardDisplayMap: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

const CardDisplayContextProvider: FC<CardDisplayProviderProps> = ({ children, initialState }) => {
  const [cardDisplayState, dispatch] = useReducer(cardDisplayReducer, initialState);
  const value = useMemo(() => ({ cardDisplayState, dispatch }), [cardDisplayState, dispatch]);
  return <CardDisplayContext.Provider value={value}>{children}</CardDisplayContext.Provider>;
};

const useCardDisplayContext = () => {
  const context = useContext(CardDisplayContext);
  if (context === undefined) {
    throw new Error('useCardDisplay must be used within CardDisplayContextProvider');
  }
  return context;
};

export { CardDisplayContextProvider, useCardDisplayContext };
