/* eslint-disable no-console */
import {
  attributeMapping,
  categoryPrefixes,
  categorySeparator as separator,
} from '@virginexperiencedays/search/constants';

import type { RouteParams, Refinement } from '@virginexperiencedays/search/types';

/**
 * This function formats the next router query, removing the input refinement.
 * @param refinement Algolia current refinement object
 * @param query Next router query object
 * @returns A formatted query object without the selected refinement
 */

export const formatQuery = (
  refinement: Refinement,
  query: Partial<RouteParams>,
  isSRP: boolean
) => {
  const { attribute, value, label } = refinement;

  const normalizedSlug = Array.isArray(query.slug) ? query?.slug : query?.slug?.split('/');
  const formattedSlugs = normalizedSlug?.filter(
    (slug) => !Object.values(categoryPrefixes).includes(slug as categoryPrefixes) && slug !== 'plp'
  );

  const isSlug = formattedSlugs?.find((slug) => slug === label);

  const filterName = isSlug ? 'slug' : attributeMapping[attribute];

  const refinementLvls: string[] = value.split(separator);

  const remainingRef = refinementLvls.slice(0, refinementLvls.length - 1);

  if (remainingRef.length && !isSRP) {
    query[filterName] = remainingRef;
  } else {
    delete query[filterName];
  }

  return query;
};
