import dynamic from 'next/dynamic';

import { Accordion } from '@virginexperiencedays/components-v2/src/layout/Accordion';

const DynamicGeoSearchFilter = dynamic(
  () => import('./GeoSearchFilter').then((mod) => mod.GeoSearchFilter),
  {
    /**
     * NOTE: Move to Suspense if moving to React 18.
     *
     * No need to display anything while loading.
     */
    loading: () => null,
  }
);

/**
 * Lazy-import the GeoSearchFilter component, as it necessarily brings in
 * connectGeoSearch from instantsearch.js, which is a bit too large for our
 * taste (bloats the bundle size).
 */

export const GeoSearchWidget = () => {
  return (
    <Accordion heading="Location" open dataTestId="accordion-location">
      <DynamicGeoSearchFilter />
    </Accordion>
  );
};
