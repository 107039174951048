import { useState, useRef, useEffect, useCallback } from 'react';
import { useRouter } from 'next/compat/router';
import Script from 'next/script';

import { Location } from '@virginexperiencedays/components-v2/src/pages/search/filters/Location';

import { facets } from '@virginexperiencedays/search/constants';
import { useGeoSearch } from '../../../../libs/algolia/hooks/useGeoSearch';

import { useControllableAutocomplete } from '@virginexperiencedays/hooks';

import { removeQueryStringFromPath } from '../../../../utils/routing/paths';
import { useRouterPush } from '../../../../utils/routing/useRouterPush';
import { filter as track } from '../tracking/interactions';

import { getCurrentPosition, geocode } from './utils';
import useFeatureFlags from '@virginexperiencedays/feature-flags';
import { FilterInteraction } from '../../../../libs/tracking/filters';
import { isDesktopFilter } from '../../../../libs/algolia/isDesktopFilter';

/**
 * This is the Algolia filter component for geosearching (which is distinct from
 * the Locations hierarchy): the user selects a location using Google Places
 * Autocomplete (e.g. "Bourne End, UK"), and the component applies an Algolia
 * filter to only display products within 100 miles of the chosen location.
 *
 * This component has two important ways to input a location:
 *
 * 1. A Google Places Autocomplete input, which we provide using Google's
 *    Javascript API.
 *    {@see https://developers.google.com/maps/documentation/javascript/place-autocomplete}
 * 2. A "Use my current location" button, which uses the browser's built-in
 *    geolocation feature to grab the lat/lng coordinates, then feeds that into
 *    Google's Geocoder API to get a formatted address.
 *    {@see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation}
 *    {@see https://developers.google.com/maps/documentation/javascript/geocoding}
 *
 * Both these inputs provide both the lat/lng coordinates and the formatted
 * address. The lat/lng coordinates are fed into Algolia's geoSearch as the
 * center of a radius search, facilitated by our own custom
 * {@link useGeoSearch}. This also means results are automatically sorted by
 * distance.
 * {@see https://www.algolia.com/doc/guides/managing-results/refine-results/geolocation/#filter-around-a-central-point}
 *
 * The formatted address isn't used for searching at all, but is simply a
 * conveniently human-readable representation for use with the Location text
 * box, as well as anywhere else the location is needed (e.g. the HitsWidget to
 * display Nearest Distance information).
 */

export type GeoSearchFilterProps = {
  onOptionSelect?: ({
    geoSearchFormatted,
    geoSearch,
  }: {
    geoSearchFormatted: string;
    geoSearch: string;
    interactionType: FilterInteraction;
  }) => void;
  onClearLocation?: () => void;
  isSearchIconNew?: boolean;
};

export const GeoSearchFilter = ({
  onOptionSelect,
  onClearLocation,
  isSearchIconNew = false,
}: GeoSearchFilterProps) => {
  const [isEnabled] = useFeatureFlags();
  useGeoSearch();
  const { refine } = useRouterPush();

  // router hooks
  const router = useRouter();

  // remember Autocomplete instance in state, so we only instantiate once
  const [autocomplete, setAutocomplete] = useState(null);
  // remember Geocoder instance in state, so we only instantiate once
  const [geocoder, setGeocoder] = useState(null);

  // hook onto input as a ref
  const inputRef = useRef(null);
  // Container ref for the location component
  const containerRef = useRef<HTMLDivElement>(null);

  // hook some controls to the Autocomplete input ref directly
  const { selectFirstResult } = useControllableAutocomplete(inputRef, {
    selectFirstResultOnEnter: true,
    minimumSearchLength: 3,
    debounceTime: 250,
  });

  const slug = removeQueryStringFromPath(router?.asPath, ' / ');

  /**
   * Keep a loosely-coupled input text in state, so we can render reactively.
   *
   * NOTE: Unfortunately we can't really rely on this value, e.g. with:
   *
   * <input value={searchText} onChange={e => setSearchText(e.target.value)}>
   *
   * because Autocomplete expects to assign input.value directly, bypassing our
   * state. We maintain this strictly only to notify the component when to
   * re-render.
   *
   * TODO: Potentially hook into useControllableAutocomplete to hi-jack
   * Autocomplete internals with our own state? That way we could rely on state
   * completely.
   */
  const [searchText, setSearchText] = useState<string>(
    router?.query?.geoSearchFormatted?.toString() || ''
  );

  // manually set input.value whenever searchText is set
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchText;
    }
  }, [searchText]);

  /*
   * This is a workaround to fix google places autocomplete anchoring
   * issue when used within a drawer, Vaul drawer modifies body styles adding which also google autocomplete makes use of, below effect adds that top subtraction back to the wrapper made around the container itself. Use with care and if ever an issue consider moving to a headless approach altogether
   *
   * We want to trigger this every time searchText is changed because even when atocuomplete can be truthy, the container might not be mounted yet.
   * We then disable re-rendering using state as soon as the code for wrapping is executed
   */

  /********************************
   ** GOOGLE PLACES AUTOCOMPLETE **
   ********************************/

  /**
   * Upon loading the Google API script (with next/script), instantiate
   * Autocomplete exactly once, then save it in state so we remember the
   * singleton -- we don't want several instantiations every time we render,
   * which will cause a memory leak.
   */
  const handleScriptLoad = () => {
    if (autocomplete) return;
    if (!inputRef.current) return;

    /*
     * This needs further check-in to ensure no memory leaks existing. If we have to remove the container before re-mounting, we need to ensure that instance itself is garbage collected too.
     */
    const container = document.querySelector<HTMLDivElement>('.pac-container');
    // remove old container in case of re-render
    container?.remove();

    const instance = new window.google.maps.places.Autocomplete(inputRef.current, {
      // restrict results to UK, for convenience
      componentRestrictions: { country: 'gb' },
      // only request the Place data we need, reducing the api bill
      fields: ['geometry', 'formatted_address'],
    });

    setAutocomplete(instance);
  };

  useEffect(() => {
    if (inputRef.current && isDesktopFilter() && isEnabled('FF_new_filters')) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  /**
   * We *do* want to re-instantiate when we change routes, because next/script
   * won't get loaded a second time, but we want to re-instantiate Autocomplete
   * for a new input.
   */
  useEffect(() => {
    // NOTE: make sure Autocomplete is ready, because this can fire before that
    if (router?.isReady && window?.google?.maps?.places?.Autocomplete) {
      handleScriptLoad();
    }
  }, [router?.isReady, router?.query]);

  /**
   * Set up the listener for Autocomplete output. Upon the user choosing a
   * location, perform the following:
   *
   * 1. Get the lat/lng coordinates and the formatted address of the chosen
   *    location.
   * 2. Refine with the geoSearch and geoSearchFormatted query params,
   *    respectively.
   *
   * The geoSearch query param is handled in {@link routeToState}, to be passed
   * on to Algolia's UI state.
   *
   * NOTE: We have this in an effect because we want to remove and re-add the
   * listener whenever our refine method changes, which it can while moving
   * across the server boundary to the client.
   */
  useEffect(() => {
    if (!autocomplete) return;

    // When used within shadcn Drawer (vaul) - the drawer itself adds `pointer-events: none;` to the body, and only elements inside of the drawer get `pointer-events: auto;` assigned, however google autocomplete API renders outside of the regular html hierarchy which makes it unclickable. This is a workaround to make it clickable again.
    const container = document.querySelector<HTMLDivElement>('.pac-container');
    container?.classList.add('pointer-events-auto');

    // set up the listener
    const listener = autocomplete.addListener('place_changed', () => {
      const { geometry, formatted_address } = autocomplete.getPlace();

      // filter out null results, if user presses Enter on a non-existing place
      if (!geometry) return;

      if (isEnabled('FF_new_filters')) {
        onOptionSelect?.({
          geoSearchFormatted: formatted_address,
          geoSearch: `${geometry.location.lat()},${geometry.location.lng()}`,
          interactionType: FilterInteraction.LocationSearchSuggestionSelected,
        });
        return;
      }

      refine({
        query: {
          geoSearchFormatted: formatted_address,
          geoSearch: `${geometry.location.lat()},${geometry.location.lng()}`,
        },
        // NOTE: remove relevant sort, as it interferes with geolocation ranking
        paramsToRemove: ['sort'],
        shallow: true,
      });

      track({ slug, label: 'Add Location' });
    });

    return () => {
      // clean up listener
      listener.remove();
    };
  }, [autocomplete, refine, onOptionSelect]);

  /**
   * If the geoSearchFormatted exists in the router query, use it to initialize
   * the Autocomplete input's value, so the user is aware if a geosearch
   * refinement is currently applied.
   */
  useEffect(() => {
    // strictly initialize only
    // if the input already has a value, don't clobber it
    if (!inputRef.current) return;
    if (inputRef.current.value) return;
    if (!router?.query?.geoSearchFormatted) return;

    setSearchText(String(router.query.geoSearchFormatted));
  }, [router?.query]);

  // react to effects happening in the router query so that when text is cleared input will be too
  useEffect(() => {
    if (!isEnabled('FF_new_filters')) return;
    if (!inputRef.current) return;

    const value = router?.query?.geoSearchFormatted || '';

    setSearchText(value.toString());
  }, [router?.query]);

  /**
   * On Click: Magnifying Glass Icon
   */
  const handleSearchSubmit = useCallback(() => {
    // dont trigger search when there is already geo search filter
    if (router?.query?.geoSearchFormatted) return;

    selectFirstResult();
  }, [router?.query]);

  /**
   * Allow easily clearing location with the clear icon. There are two actual
   * things to clear here:
   *
   * 1. The input's value, whether it's an in-flight search or an actual
   *    selected location.
   * 2. The actual query params in the route, so Algolia removes the filters
   *    altogether.
   */
  const canClearLocation =
    router?.query?.geoSearchFormatted || router?.query?.geoSearch || inputRef.current?.value;

  const clearLocation = useCallback(() => {
    // clear input
    setSearchText('');

    if (isEnabled('FF_new_filters')) {
      onClearLocation?.();
      return;
    }

    // remove actual location query params
    if (router?.query?.geoSearchFormatted || router?.query?.geoSearch) {
      // detect if we're removing the only facet
      // as we have to go back to CLP with a non-shallow refine in that case
      const isNextQueryFaceted = facets
        // no need to check page, since CLPs can have page
        // no need to check geoSearch, as we'll be removing it
        .filter((facet) => facet !== 'page' && facet !== 'geoSearch')
        .some((facet) => !!router?.query?.[facet]);

      refine({
        paramsToRemove: ['geoSearchFormatted', 'geoSearch'],
        shallow: isNextQueryFaceted,
      });
    }

    track({ slug, label: 'Remove Location' });
  }, [router?.query, refine, onClearLocation]);

  /*****************************
   ** USE MY CURRENT LOCATION **
   *****************************/

  /**
   * Set up the handler for "Use my Current Location". Upon the user clicking
   * the button, perform the following:
   *
   * 1. Request the lat/lng coordinates from the browser's
   *    navigator.geolocation.
   * 2. Reuse the singleton Geocoder instance in state (instantiate if one
   *    doesn't exist yet).
   * 3. Reverse geocode the lat/lng coordinates to get a formatted address.
   * 4. Refine with the geoSearch and geoSearchFormatted query params,
   *    respectively.
   *
   * NOTE: We don't instantiate Geocoder on script load. We can defer the
   * instantiation of the Geocoder instance on-demand, during the first click of
   * the button, because it doesn't need to bind to anything in the DOM to do
   * its job.
   *
   * This is in contrast to the Autocomplete instance, which has to bind to our
   * input as early as it can (otherwise the user will briefly see the input
   * fail to provide autocomplete suggestions the first time they type), which
   * is why we instantiate it on script load.
   */
  const handleCurrentLocation = async () => {
    if (!navigator.geolocation) return;
    // grab lat/lng from browser
    const position = await getCurrentPosition(navigator.geolocation);
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    // reuse the geocoder instance, or instantiate if not existing
    const instance = geocoder ?? new window.google.maps.Geocoder();
    if (!geocoder) setGeocoder(instance);

    // reverse geocode lat/lng to get the formatted address
    const results = await geocode(instance, {
      location: new window.google.maps.LatLng(lat, lng),
    });
    const formattedAddress = results?.[0]?.formatted_address;

    // assign the formatted address to the Autocomplete manually,
    // so the user sees the address change
    setSearchText(formattedAddress);

    if (isEnabled('FF_new_filters')) {
      onOptionSelect?.({
        geoSearchFormatted: formattedAddress,
        geoSearch: `${lat},${lng}`,
        interactionType: FilterInteraction.CurrentLocationSelected,
      });
      return;
    }

    refine({
      query: {
        geoSearchFormatted: formattedAddress,
        geoSearch: `${position.coords.latitude},${position.coords.longitude}`,
      },
      // NOTE: remove relevant sort, as it interferes with geolocation ranking
      paramsToRemove: ['sort'],
      shallow: true,
    });

    track({ slug, label: 'Add Current Location' });
  };

  // Because of how google autocomplete styles the autocomplete element itself (uses fixed positioning),
  // we have to detect when user starts scrolling and blur the input so that autocomplete will disappear rather than just hang on the page
  useEffect(() => {
    const handleScroll = () => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    };

    const container = containerRef.current;
    const drawer = container?.closest('.mega-nav-scrollbar');

    if (drawer) {
      drawer.addEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (drawer) {
        drawer.removeEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [inputRef]);

  return (
    <>
      <Script
        data-testid="google-maps-script"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GMAP_PUBLIC_API_KEY}&libraries=places`}
        strategy="lazyOnload"
        onLoad={handleScriptLoad}
      />
      <Location
        containerRef={containerRef}
        inputRef={inputRef}
        canClearLocation={canClearLocation}
        onClearLocation={clearLocation}
        onSearchType={setSearchText}
        onSearchSubmit={handleSearchSubmit}
        onCurrentLocation={handleCurrentLocation}
        isSearchIconNew={isSearchIconNew}
      />
    </>
  );
};
