import { cn } from '@virginexperiencedays/tailwind';
import { IconCloseNavigation } from '../../../../icons/IconCloseNavigation';

import { type ChangeEvent, type RefObject } from 'react';
import { IconSearch, IconSearchNew } from '../../../../icons/IconSearch';
import { IconLocationArrow } from '../../../../icons/IconLocationArrow';

type LocationProps = {
  className?: string;
  inputRef: RefObject<HTMLInputElement>;
  containerRef: RefObject<HTMLDivElement>;
  canClearLocation: boolean;
  onClearLocation?: () => void;
  onSearchType?: (text: string) => void;
  onSearchSubmit?: () => void;
  onCurrentLocation?: () => void;
  placeholder?: string;
  isSearchIconNew?: boolean;
};

// (Note: this component is just extracted from GeoSearchFilter)
// Location search box- ref is added for google places autocomplete
export const Location = ({
  className,
  inputRef,
  containerRef,
  canClearLocation,
  onClearLocation,
  onSearchType,
  onSearchSubmit,
  onCurrentLocation,
  placeholder = 'Search town, city, or postcode',
  isSearchIconNew = false,
}: LocationProps) => {
  const searchIconProps = {
    className:
      'absolute right-4 top-1/2 h-[15px] w-[15px] -translate-y-1/2 cursor-pointer fill-text-neutral',
    fill: 'currentColor',
    onClick: () => {
      if (!inputRef?.current?.value) return;
      onSearchSubmit();
    },
  };

  return (
    <div className={className} ref={containerRef}>
      <div className="relative">
        <input
          className={cn(
            'text-persistent-text-dark border-border-neutral placeholder:text-neutral-faded h-12 w-full rounded border bg-white p-4 pr-[80px] text-base font-normal leading-none outline-none',
            'dark:border-border-neutral dark:text-neutral dark:bg-transparent'
          )}
          ref={inputRef}
          data-testid="autocomplete-input"
          placeholder={placeholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onSearchType(event.target.value)}
        />
        {canClearLocation && (
          <IconCloseNavigation
            className="fill-text-neutral absolute right-12 top-1/2 h-3 w-3 -translate-y-1/2 cursor-pointer"
            fill="currentColor"
            onClick={onClearLocation}
          />
        )}
        {isSearchIconNew ? (
          <IconSearchNew {...searchIconProps} />
        ) : (
          <IconSearch {...searchIconProps} />
        )}
      </div>
      <button
        className="text-neutral mt-4 flex cursor-pointer items-center gap-2 border-none bg-transparent p-0 text-sm font-normal leading-4"
        data-testid="use-current-location"
        onClick={onCurrentLocation}
      >
        <IconLocationArrow className="h-4 w-3" />
        <span className="underline">Use my current location</span>
      </button>
    </div>
  );
};
