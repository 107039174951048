export const usePagination = (currentPage: number, itemsCount: number, perPage: number) => {
  const totalPages = Math.ceil(itemsCount / perPage);
  const arrayOfPages = Array.from({ length: totalPages }, (v, k) => k + 1);
  let currentPagesArray: (string | number)[] = [];
  // subtract 1 from lastPageIdx because arrayOfPages starts from 0
  const lastPageIdx = arrayOfPages.length - 1;
  const nextButtonDisabled = currentPage === totalPages;
  const prevButtonDisabled = currentPage === arrayOfPages[0];

  // edge cases

  //  less/equal 5 pages
  if (totalPages <= 5) {
    currentPagesArray = arrayOfPages;
  }
  // More than 5 while current is on the first two pages
  if (currentPage <= 2 && totalPages > 5) {
    currentPagesArray = [arrayOfPages.slice(0, 3), '...', arrayOfPages[lastPageIdx]].flat();
  }
  // More than 5 while somewhere else than first/last 2 pages of the array
  if (currentPage > 2 && currentPage < lastPageIdx && totalPages > 5) {
    currentPagesArray = [
      arrayOfPages[0],
      arrayOfPages.slice(currentPage - 2, currentPage + 1),
      arrayOfPages[lastPageIdx],
    ].flat();
  }

  if (currentPage >= lastPageIdx && totalPages > 5) {
    currentPagesArray = [
      arrayOfPages[0],
      '...',
      arrayOfPages.slice(lastPageIdx - 2, lastPageIdx + 1),
    ].flat();
  }
  return { totalPages, currentPagesArray, nextButtonDisabled, prevButtonDisabled };
};
