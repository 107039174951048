import { useRouter } from 'next/compat/router';

import {
  categoriesHierarchyAttributeArray,
  featuresHierarchyAttributeArray,
  categoryTypes,
  occasionsHierarchyAttributeArray,
  refinementList,
  locationsHierarchyAttributeArray,
} from '@virginexperiencedays/search/constants';

import { HierarchicalMenuWidget } from '../../HierarchicalMenuWidget';
import { PriceRangeWidget } from '../../PriceRangeWidget';
import { RatingsWidget } from '../../RatingsWidget';
import { GeoSearchWidget } from '../../GeoSearchWidget';

type DynamicWidgetsFactoryType = {
  attribute: string;
  categoryType: categoryTypes;
};

export const DynamicWidgetsFactory = ({ attribute, categoryType }: DynamicWidgetsFactoryType) => {
  return <DynamicWidgets attribute={attribute} categoryType={categoryType} />;
};

const DynamicWidgets = ({ attribute, categoryType }: DynamicWidgetsFactoryType) => {
  const router = useRouter();
  switch (attribute) {
    case refinementList.CATEGORY:
      return (
        <HierarchicalMenuWidget
          categoryType={categoryType}
          isMain={categoryType === categoryTypes.CATEGORY}
          title="Category"
          queryParam="category"
          isDynamicTitle
          isMaster={categoryType !== categoryTypes.SEARCH}
          attributes={categoriesHierarchyAttributeArray}
          trackingLabel="Add Category"
        />
      );
    case refinementList.OCCASION:
      return (
        <HierarchicalMenuWidget
          categoryType={categoryType}
          title="Occasion"
          isDynamicTitle
          isMain={categoryType === categoryTypes.OCCASION}
          queryParam="occasion"
          attributes={occasionsHierarchyAttributeArray}
          trackingLabel="Add Occasion"
        />
      );
    case refinementList.FEATURE:
      return (
        <HierarchicalMenuWidget
          categoryType={categoryType}
          title="Feature"
          isDynamicTitle
          isMain={categoryType === categoryTypes.FEATURE}
          queryParam="feature"
          attributes={featuresHierarchyAttributeArray}
          trackingLabel="Add Feature"
        />
      );

    case refinementList.PRICE_RANGE:
      return <PriceRangeWidget />;

    case refinementList.RATING:
      return <RatingsWidget />;

    case refinementList.LOCATION:
      return (
        <HierarchicalMenuWidget
          categoryType={categoryType}
          title="Region"
          isDynamicTitle
          isMain={categoryType === categoryTypes.LOCATION}
          //@TODO: refactor to region
          queryParam="location"
          attributes={locationsHierarchyAttributeArray}
          trackingLabel="Add Region"
          open={categoryType === categoryTypes.LOCATION || !!router?.query?.location}
        />
      );

    case refinementList.GEO_SEARCH:
      return <GeoSearchWidget />;

    default:
      return null;
  }
};
