/**
 * Function that takes an object and array of lookup keys and return the first occurence of the key value (order matters) if none of those exists, returns null
 * @param obj - The object to look in
 * @param lookUpValues - String array of keys to look for
 * @returns The first value found or null
 */

export type ResponsiveKeysType = ['mobile' | 'largeMobile' | 'tablet' | 'desktop' | 'wide'];

export const createLookUpResponsiveArray = (
  lookup: 'mobile' | 'largeMobile' | 'tablet' | 'desktop' | 'wide'
) => {
  switch (lookup) {
    case 'mobile':
      return ['mobile', 'largeMobile', 'tablet', 'desktop', 'wide'];
    case 'largeMobile':
      return ['largeMobile', 'mobile', 'tablet', 'desktop', 'wide'];
    case 'tablet':
      return ['tablet', 'largeMobile', 'mobile', 'desktop', 'wide'];
    case 'desktop':
      return ['desktop', 'tablet', 'largeMobile', 'mobile', 'wide'];
    case 'wide':
      return ['wide', 'desktop', 'tablet', 'largeMobile', 'mobile'];
  }
};

export const calculateResponsiveValue = (
  obj: Record<string, unknown> | string | number,
  lookUpValues: ResponsiveKeysType
) => {
  if (typeof obj !== 'object') {
    return obj;
  }
  // find first applicable value (to be able to define it's order to look up)
  const firstOccurrence = lookUpValues.find((value: string) => obj[value]);

  if (!firstOccurrence) return null;
  return obj[firstOccurrence] ?? null;
};
