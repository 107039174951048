import { type MouseEvent, type ReactNode, useCallback } from 'react';
import { useRouter } from 'next/compat/router';
import { useDynamicWidgets } from 'react-instantsearch-core';
import { cn } from '@virginexperiencedays/tailwind';

import type { Override } from '@virginexperiencedays/categories/src/types';
import {
  categoriesHierarchyAttributeArray,
  categoryTypes,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  MAX_VALUES_PER_FACET,
  occasionsHierarchyAttributeArray,
  refinementList,
} from '@virginexperiencedays/search/constants';

import { OptionBadge, OptionToggle } from '@virginexperiencedays/components-v2/src/layout/Options';
import { Heading } from '@virginexperiencedays/components-v2/src/pages/search/filters/Heading';

import { usePagination } from '../../../../../libs/algolia/hooks/usePagination';
import { isDesktopFilter } from '../../../../../libs/algolia/isDesktopFilter';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';

import {
  FilterSource,
  filter as track,
  FilterInteraction,
} from '../../../../../libs/tracking/filters';
import { mapPathnameToPageType } from '../../../../../libs/tracking/mapPathnameToPageType';

import { FilterModal } from '../FilterOverlay/FilterModal';
import { FilterDrawer } from '../FilterOverlay/FilterDrawer';
import {
  CurrentRefinementPillItem,
  CurrentRefinementsWidget,
} from '../Widgets/CurrentRefinementsWidget';
import { DynamicWidgetsFactory } from '../Widgets/DynamicWidgetsFactory';
import { useMenu } from '../../../../../libs/algolia/hooks/useMenu';
import {
  filterItemLabels,
  sortItemLabels,
  transformLabels,
} from '../../../../../libs/algolia/transformLabels';
import { useRefinementList } from '../../../../../libs/algolia/hooks/useRefinementList';
import { useCurrentRefinements } from '../../../../../libs/algolia/hooks/useCurrentRefinements';

// This is rendered when modal/drawer is opened
export const AllFiltersContent = ({
  text,
  serverSlug,
  categoryType,
  overridesSlugInfo,
  attribute,
  validAttributes,
  currentCategoryAttributes,
  onReset,
  open,
  setOpen,
  filters,
  currentRefinements,
  totalActiveCount,
  onRemoveCurrentRefinement,
  onOptionClick,
}: {
  text: string;
  serverSlug: string[];
  categoryType: categoryTypes;
  overridesSlugInfo: Override['slugInfo'];
  attribute: AlgoliaAttribute;
  validAttributes: (AlgoliaAttribute | refinementList)[];
  currentCategoryAttributes: AlgoliaAttribute[];
  onReset: (attribute: string) => void;
  onOptionClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  setOpen: (id: string, value: boolean) => void;
  filters: Record<string, { text: string; activeCount: number }>;
  currentRefinements?: { attribute: AlgoliaAttribute }[];
  totalActiveCount: number;
  onRemoveCurrentRefinement: (data: {
    attribute: AlgoliaAttribute;
    value: string;
    count: number;
    position: number;
  }) => void;
}) => {
  const router = useRouter();
  const pathname = router?.pathname;
  const { nbHits: nbOfHits } = usePagination();

  const trackData = {
    title: text,
    type: null, // N/A
    pageType: mapPathnameToPageType(pathname),
    position: 1,
    source: FilterSource.AllFilters,
    qfTotalProductCount: nbOfHits,
  };

  if (isDesktopFilter()) {
    return (
      <FilterModal
        attribute={attribute}
        open={open}
        setOpen={(attribute, open) => {
          setOpen(attribute, open);

          track({
            ...trackData,
            interaction: open ? FilterInteraction.ClickAllFilters : FilterInteraction.FilterClosed,
          });
        }}
        text={text}
        resetText="Reset All"
        disabledReset={totalActiveCount === 0}
        onReset={() => {
          onReset?.('all_filters');

          track({
            ...trackData,
            interaction: FilterInteraction.ClickReset,
          });
        }}
        disabledApply={totalActiveCount === 0}
        applyText={`Apply Filter (${nbOfHits} results)`}
        onApply={(attribute) => {
          setOpen(attribute, false);

          track({
            ...trackData,
            interaction: FilterInteraction.ClickApplyFilter,
          });
        }}
      >
        <AllFiltersContentItems
          serverSlug={serverSlug}
          categoryType={categoryType}
          overridesSlugInfo={overridesSlugInfo}
          validAttributes={validAttributes}
          filters={filters}
          currentRefinements={currentRefinements}
          totalActiveCount={totalActiveCount}
          onReset={() => onReset?.('all_filters')}
          onRemoveCurrentRefinement={onRemoveCurrentRefinement}
          min={8}
          className="[&>ul]:grid-cols-2"
          currentCategoryAttributes={currentCategoryAttributes}
          onOptionClick={onOptionClick}
        />
      </FilterModal>
    );
  }

  return (
    <FilterDrawer
      open={open}
      setOpen={(attribute, open) => {
        setOpen(attribute, open);

        track({
          ...trackData,
          interaction: open ? FilterInteraction.ClickAllFilters : FilterInteraction.FilterClosed,
        });
      }}
      text={text}
      attribute={attribute}
      activeCount={totalActiveCount}
      resetText="Reset All"
      onReset={() => {
        onReset?.('all_filters');

        track({
          ...trackData,
          interaction: FilterInteraction.ClickReset,
        });
      }}
      onApply={(attribute) => {
        setOpen(attribute, false);

        track({
          ...trackData,
          interaction: FilterInteraction.ClickApplyFilter,
        });
      }}
    >
      <AllFiltersContentItems
        currentCategoryAttributes={currentCategoryAttributes}
        serverSlug={serverSlug}
        categoryType={categoryType}
        overridesSlugInfo={overridesSlugInfo}
        validAttributes={validAttributes}
        filters={filters}
        currentRefinements={currentRefinements}
        totalActiveCount={totalActiveCount}
        onReset={() => onReset?.('all_filters')}
        onRemoveCurrentRefinement={onRemoveCurrentRefinement}
        min={5}
      />
    </FilterDrawer>
  );
};

const AllFiltersContentItems = ({
  serverSlug,
  categoryType,
  overridesSlugInfo,
  filters,
  currentRefinements,
  totalActiveCount,
  validAttributes,
  currentCategoryAttributes,
  onReset,
  onRemoveCurrentRefinement,
  min,
  className,
  onOptionClick,
}: {
  serverSlug: string[];
  categoryType: categoryTypes;
  overridesSlugInfo: Override['slugInfo'];
  filters: Record<string, { text: string; activeCount: number }>;
  currentRefinements?: { attribute: AlgoliaAttribute }[];
  totalActiveCount: number;
  validAttributes: (AlgoliaAttribute | refinementList)[];
  currentCategoryAttributes: AlgoliaAttribute[];
  onReset: () => void;
  onRemoveCurrentRefinement: (data: {
    attribute: AlgoliaAttribute;
    value: string;
    count: number;
    position: number;
  }) => void;
  // min number of options to show before showing "see more"
  min?: number;
  className?: string;
  onOptionClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
}) => {
  const router = useRouter();
  // use it only for geosearch as its a single-select
  const { refine: clearRefinement } = useCurrentRefinements();
  const { attributesToRender } = useDynamicWidgets({
    maxValuesPerFacet: MAX_VALUES_PER_FACET,
    transformItems: useCallback(
      (attributes) =>
        attributes.filter((attribute: AlgoliaAttribute | refinementList) => {
          if (
            attribute.startsWith('facets.regionHierarchies') &&
            validAttributes.includes(attribute)
          ) {
            return true;
          }

          return (
            validAttributes.includes(attribute) &&
            attribute !== refinementList.GEO_SEARCH &&
            !currentCategoryAttributes.includes(attribute as AlgoliaAttribute)
          );
        }),
      []
    ),
  });

  const geoSearchRefinement = router?.query?.geoSearchFormatted;
  const hasGeoSearchRefinement = !!geoSearchRefinement && router?.query?.geoSearch;
  const totalRefinements = currentRefinements?.length + (hasGeoSearchRefinement ? 1 : 0);

  return (
    <>
      {totalRefinements > 0 && (
        <div className={cn(styles.divider, 'after:mt-0 md:after:my-4')}>
          <Heading className="flex gap-2">
            Filters applied
            <OptionBadge checked>{totalActiveCount}</OptionBadge>
          </Heading>
          <ul className="flex flex-wrap gap-2">
            {hasGeoSearchRefinement && (
              <CurrentRefinementPillItem
                label={geoSearchRefinement}
                onClick={() => {
                  clearRefinement({ attribute: 'geoSearch' });
                }}
              />
            )}
            {currentRefinements.map(({ attribute }) => {
              return (
                <CurrentRefinementsWidget
                  key={`current-refinements-${attribute}`}
                  attribute={attribute}
                  serverSlug={serverSlug}
                  categoryType={categoryType}
                  overridesSlugInfo={overridesSlugInfo}
                  onClick={(data) => {
                    onRemoveCurrentRefinement(data);
                    onOptionClick?.();
                  }}
                />
              );
            })}
          </ul>
          <OptionToggle className="py-4 md:py-2" data-testid="clear-all-filters" onClick={onReset}>
            Clear all filters
          </OptionToggle>
        </div>
      )}

      {attributesToRender.map((_attribute: AlgoliaAttribute, index) => {
        const attributeText = filters?.[_attribute]?.text;
        const attributeActiveCount = filters?.[_attribute]?.activeCount;

        return (
          <AllFiltersItem
            overridesSlugInfo={overridesSlugInfo}
            serverSlug={serverSlug}
            categoryType={categoryType}
            key={_attribute}
            attribute={_attribute}
            attributeActiveCount={attributeActiveCount}
            attributeText={attributeText}
            className={styles.divider}
          >
            <DynamicWidgetsFactory
              currentCategoryAttributes={currentCategoryAttributes}
              filterIndex={index}
              categoryType={categoryType}
              serverSlug={serverSlug}
              attribute={_attribute as AlgoliaAttribute}
              text={attributeText}
              renderType={isDesktopFilter() ? 'list' : 'pill'}
              min={min}
              className={className}
              onClick={onOptionClick}
              attributeActiveCount={attributeActiveCount}
              overridesSlugInfo={overridesSlugInfo}
            />
          </AllFiltersItem>
        );
      })}
    </>
  );
};

type AllFiltersItemProps = {
  attribute: AlgoliaAttribute;
  serverSlug: string[];
  categoryType: categoryTypes;
  attributeText: string;
  attributeActiveCount: number;
  children: ReactNode;
  className?: string;
  overridesSlugInfo: Override['slugInfo'];
};

const AllFiltersItem = ({
  attribute,
  children,
  overridesSlugInfo,
  ...rest
}: AllFiltersItemProps) => {
  switch (attribute) {
    case categoriesHierarchyAttributeArray[0]: // falls through
    case categoriesHierarchyAttributeArray[1]: // falls through
    case categoriesHierarchyAttributeArray[2]:
      return (
        <AllFiltersMultiselectItem
          attribute={attribute}
          widgetCategoryType={categoryTypes.CATEGORY}
          overridesSlugInfo={overridesSlugInfo}
          {...rest}
        >
          {children}
        </AllFiltersMultiselectItem>
      );

    case occasionsHierarchyAttributeArray[0]: // falls through
    case occasionsHierarchyAttributeArray[1]: // falls through
    case occasionsHierarchyAttributeArray[2]:
      return (
        <AllFiltersMultiselectItem
          attribute={attribute}
          widgetCategoryType={categoryTypes.OCCASION}
          overridesSlugInfo={overridesSlugInfo}
          {...rest}
        >
          {children}
        </AllFiltersMultiselectItem>
      );

    case featuresHierarchyAttributeArray[0]: // falls through
    case featuresHierarchyAttributeArray[1]: // falls through
    case featuresHierarchyAttributeArray[2]:
      return (
        <AllFiltersMultiselectItem
          attribute={attribute}
          widgetCategoryType={categoryTypes.FEATURE}
          overridesSlugInfo={overridesSlugInfo}
          {...rest}
        >
          {children}
        </AllFiltersMultiselectItem>
      );

    case locationsHierarchyAttributeArray[0]: // falls through
    case locationsHierarchyAttributeArray[1]: // falls through
    case locationsHierarchyAttributeArray[2]:
      return (
        <AllFiltersLocationItem
          attribute={attribute}
          overridesSlugInfo={overridesSlugInfo}
          {...rest}
        >
          {children}
        </AllFiltersLocationItem>
      );

    case refinementList.PRICE_RANGE:
      return (
        <AllFiltersMultiselectItem
          attribute={attribute}
          widgetCategoryType={categoryTypes.PRICE_RANGE}
          overridesSlugInfo={overridesSlugInfo}
          {...rest}
        >
          {children}
        </AllFiltersMultiselectItem>
      );

    case refinementList.RATING:
      return (
        <AllFiltersSingleselectItem
          attribute={attribute}
          widgetCategoryType={categoryTypes.RATING}
          overridesSlugInfo={overridesSlugInfo}
          {...rest}
        >
          {children}
        </AllFiltersSingleselectItem>
      );
  }
};

const AllFiltersSingleselectItem = ({
  attribute,
  serverSlug,
  categoryType,
  widgetCategoryType,
  attributeText,
  attributeActiveCount,
  children,
  className,
  overridesSlugInfo,
}: AllFiltersItemProps & {
  widgetCategoryType: categoryTypes;
  overridesSlugInfo: Override['slugInfo'];
}) => {
  const { items } = useMenu({
    attribute,
    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);

      if (categoryType !== widgetCategoryType) {
        if (widgetCategoryType === categoryTypes.RATING) {
          return transformLabels(sortedItems, attribute);
        }

        return items;
      }

      if (widgetCategoryType === categoryTypes.RATING) {
        return transformLabels(sortedItems, attribute);
      }

      const filtered = filterItemLabels(items, '', serverSlug, overridesSlugInfo);

      return transformLabels(filtered, attribute);
    }, []),
  });

  if (!items?.length) return null;

  return (
    <div key={attribute} className={className}>
      <Heading className="flex gap-2">
        {attributeText}{' '}
        {attributeActiveCount > 0 && <OptionBadge checked>{attributeActiveCount}</OptionBadge>}
      </Heading>
      {children}
    </div>
  );
};

const AllFiltersMultiselectItem = ({
  attribute,
  serverSlug,
  categoryType,
  widgetCategoryType,
  attributeText,
  attributeActiveCount,
  children,
  className,
  overridesSlugInfo,
}: AllFiltersItemProps & {
  widgetCategoryType: categoryTypes;
  overridesSlugInfo: Override['slugInfo'];
}) => {
  const { items } = useRefinementList({
    attribute,
    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);

      if (categoryType !== widgetCategoryType) {
        if (widgetCategoryType === categoryTypes.RATING) {
          return transformLabels(sortedItems, attribute);
        }

        return items;
      }

      if (widgetCategoryType === categoryTypes.RATING) {
        return transformLabels(sortedItems, attribute);
      }

      const filtered = filterItemLabels(items, '', serverSlug, overridesSlugInfo);

      return transformLabels(filtered, attribute);
    }, []),
  });

  if (!items?.length) return null;

  return (
    <div key={attribute} className={className}>
      <Heading className="flex gap-2">
        {attributeText}{' '}
        {attributeActiveCount > 0 && <OptionBadge checked>{attributeActiveCount}</OptionBadge>}
      </Heading>
      {children}
    </div>
  );
};

const AllFiltersLocationItem = ({
  attribute,
  attributeText,
  children,
  className,
}: AllFiltersItemProps) => {
  const router = useRouter();
  const hasGeoSearch = !!router?.query?.geoSearchFormatted && !!router?.query?.geoSearch;
  return (
    <div key={attribute} className={className}>
      <Heading className="peer flex gap-2">
        {attributeText} {hasGeoSearch && <OptionBadge checked>1</OptionBadge>}
      </Heading>
      {children}
    </div>
  );
};

const styles = {
  divider:
    'after:my-4 after:block after:h-2 after:border-t-0 after:bg-background-neutral-faded last:after:hidden md:after:h-0 md:after:border-t md:after:border-border-disabled',
};
