// Core
import { useHits, usePagination } from 'react-instantsearch';
import { useRouter } from 'next/compat/router';

// Search
import { AlgoliaHit } from '@virginexperiencedays/search/types';
import { pageMax } from './constants';

// Internal
import { PromoService, vedPromosConfig } from '@virginexperiencedays/promos';
import { handleAlgoliaFlags } from '@virginexperiencedays/search/utils/handleAlgoliaFlags';
import { metersToMiles } from '../convert';
import { removeQueryStringFromPath } from '../routing/paths';

// Tracking
import { hit as trackClick } from '../../components/pages/search/tracking/interactions';
import useHitsTracking from '../../components/pages/search/tracking/useHitsTracking';
import { useAlgoliaQueryId } from '../algolia/tracking';

// Types
import type { GridProduct } from '@virginexperiencedays/search/types';

export const mapHitsToProducts = (url: string): GridProduct[] => {
  const router = useRouter();

  const { hits, sendEvent } = useHits();
  const { saveToLocalStorage } = useAlgoliaQueryId();
  const { currentRefinement: page } = usePagination();

  // 40 = default results per page
  const paginationOffset = page + 1 > 1 ? page * pageMax : 0;

  useHitsTracking({
    url,
    positionOffset: paginationOffset,
    hits,
  });

  const promoService = new PromoService({
    activePromos: process.env.NEXT_PUBLIC_ACTIVE_PROMOS as string,
    promosConfiguration: vedPromosConfig,
  });

  return hits.map((algoliaHit, index) => {
    const hit = algoliaHit as unknown as AlgoliaHit;
    const {
      title,
      media,
      slug,
      price,
      reviews,
      shortDescription,
      sku,
      promocode,
      locations,
      isCollectionProduct,
      isExclusive,
      categories,
    } = hit;

    const { isNew, isOnSale, rrp } = handleAlgoliaFlags(hit);

    const { percentOff, displayPrice } = price;
    const { averageRating, count: totalReviews } = reviews;

    // display nearest location if ranked as such
    const nearestLocation = algoliaHit._rankingInfo?.matchedGeoLocation
      ? {
          name: router?.query?.geoSearchFormatted && String(router.query.geoSearchFormatted),
          // NOTE: Algolia distance is in meters
          distance: metersToMiles(algoliaHit._rankingInfo.matchedGeoLocation.distance),
        }
      : null;

    return {
      key: `${slug}-${price}`,
      title,
      slug,
      price,
      reviews,
      src: media.mainImage.url,
      shortDescription,
      aspectRatio: '4/3',
      percentOff,
      displayPrice,
      rrp,
      isExclusive,
      isNew,
      isOnSale,
      categories, // for new product card's location text override
      sku,
      promocode,
      averageRating,
      totalReviews,
      locations,
      media,
      nearestLocation,
      isCollectionProduct,
      productPromo: promoService.getProductPromo(hit),
      onClick: (e: any) => {
        e.preventDefault();

        let list = removeQueryStringFromPath(router?.asPath, ' / ');

        // custom tracking
        trackClick({ list, position: paginationOffset + index + 1, hit });
        // Algolia tracking
        sendEvent('click', algoliaHit, '[INSIGHTS] Hits Widget clicked');

        saveToLocalStorage(hit.__queryID);
        let productUrl: string;
        if (isCollectionProduct) {
          productUrl = `/collection/${slug}`;
        } else {
          productUrl = `/product/${slug}`;
        }
        router.push(productUrl);
      },
    };
  });
};
