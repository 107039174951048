import { indexNames } from '@virginexperiencedays/search/constants';

export function mapIndexToRoute(currentIndex: string): string {
  switch (currentIndex) {
    case indexNames.DEFAULT:
      return undefined;
    case indexNames.PRICE_ASC:
      return 'price-asc';
    case indexNames.PRICE_DSC:
      return 'price-desc';
    case indexNames.MOST_REVIEWED:
      return 'most-reviewed';
    case indexNames.NEWEST:
      return 'newest';
    case indexNames.TOP_RATED:
      return 'top-rated';
    default:
      return undefined;
  }
}

export function mapRouteToIndex(sortOption: string): string {
  switch (sortOption) {
    case 'price-asc':
      return indexNames.PRICE_ASC;
    case 'price-desc':
      return indexNames.PRICE_DSC;
    case 'most-reviewed':
      return indexNames.MOST_REVIEWED;
    case 'newest':
      return indexNames.NEWEST;
    case 'top-rated':
      return indexNames.TOP_RATED;
    default:
      return indexNames.DEFAULT;
  }
}
