import { categoryPrefixes, facets } from '@virginexperiencedays/search/constants';
import { RouteParams } from '@virginexperiencedays/search/types';

export function getAsPathnameValue({
  slugs,
  query,
  currentPrefix,
  isNewURL,
  isPLP,
  slugToPush,
}: {
  slugs: string[];
  query: Partial<RouteParams>;
  currentPrefix: categoryPrefixes;
  isNewURL: boolean;
  isPLP: boolean;
  slugToPush: string;
}): string {
  const normalizedSlugs = slugs.filter(
    (slug: string) =>
      !Object.values(categoryPrefixes).includes(slug as categoryPrefixes) && slug !== 'plp'
  );

  // If its legacy URL we let the middleware orchestrate
  if (!isNewURL) {
    return `/${normalizedSlugs[normalizedSlugs.length - 1]}`;
  }

  // If there is query sent it means we're pushing a facet so go to PLP / or if we are already on PLP and we want to jump into subcategory whilst keeping the facet
  if (
    // If there is facet given in the query
    (query && facets.some((facet) => Object.keys(query).includes(facet) && facet !== 'page')) ||
    // If its a PLP, slug to push, and slug we want to push is not already existing in the slugs (it is due to current refinements clear all, in general clearing and pushing should be seperate hooks. but there is no time for it hence need for such weird checks..)
    (isPLP && slugs.some((slug) => slug !== slugToPush))
  ) {
    return `/${currentPrefix}/plp/${normalizedSlugs.join('/')}`;
  }

  return `/${currentPrefix}/${normalizedSlugs.join('/')}`;
}
