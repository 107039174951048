import type { ReactNode } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import {
  MenuDropdown,
  MenuDropdownContent,
  MenuDropdownFooter,
} from '@virginexperiencedays/components-v2/src/layout/PillMenu';
import { Divider } from '@virginexperiencedays/components-v2/src/layout/Divider';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';

export const FilterDropdown = ({
  open,
  setOpen,
  children,
  attribute,
  activeCount,
  className,
  onReset,
  resetText,
}: {
  open: boolean;
  setOpen: (attribute: AlgoliaAttribute, value: boolean) => void;
  children: ReactNode;
  attribute: AlgoliaAttribute;
  activeCount: number;
  className?: string;
  onReset?: (attribute: AlgoliaAttribute) => void;
  resetText?: string;
}) => {
  return (
    <MenuDropdown
      id="filter-dropdown"
      className={cn(
        'absolute left-0 top-full z-10 w-[300px] max-w-full md:max-w-none',
        open ? 'block' : 'hidden',
        className
      )}
      onMouseEnter={() => {
        setOpen(attribute, true);
      }}
      onMouseLeave={() => setOpen(attribute, false)}
    >
      <MenuDropdownContent>{children}</MenuDropdownContent>
      <MenuDropdownFooter>
        {!!onReset && (
          <div>
            <Divider className="my-4" />

            <Button
              data-testid="dropdown-reset-button"
              className="disabled:border-0 disabled:bg-background-disabled disabled:text-[#9CA3AF]"
              disabled={!activeCount}
              variant="outline"
              onClick={() => {
                onReset(attribute);
              }}
            >
              {resetText}
            </Button>
          </div>
        )}
      </MenuDropdownFooter>
    </MenuDropdown>
  );
};
