import { PRODUCT_DAYS_NEW } from '@virginexperiencedays/products/src/constants';
import { type ProductPromo, PromoService, vedPromosConfig } from '@virginexperiencedays/promos';

import { getDateFromDaysByUnix, getUnixTimeByDate } from './dates';

import type { AlgoliaHit } from '../types';

type Flags = {
  isNew: boolean;
  isOnSale: boolean;
  productPromo: ProductPromo;
  rrp: number | null;
};
/**
 * Extracts and returns the flags for a given Algolia hit.
 * @param hit - The Algolia hit to extract flags from.
 * @returns An object containing the extracted flags.
 */
export function handleAlgoliaFlags(hit: AlgoliaHit): Flags {
  const timeNow = getUnixTimeByDate();
  const { price, dateActivated } = hit as unknown as AlgoliaHit;

  // product is tagged as new for 30 days after date activation
  const newUntil = getDateFromDaysByUnix(dateActivated, PRODUCT_DAYS_NEW);
  const isNew = timeNow <= getUnixTimeByDate(newUntil);

  const isOnSale = price.displayPrice !== price.rrp;

  const promoService = new PromoService({
    activePromos: process.env.NEXT_PUBLIC_ACTIVE_PROMOS as string,
    promosConfiguration: vedPromosConfig,
  });

  const productPromo = promoService.getProductPromo(hit);

  const rrp = price.displayPrice !== price.rrp ? price.rrp : null;

  return {
    isNew,
    isOnSale,
    productPromo,
    rrp,
  };
}
