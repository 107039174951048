import { type CSSProperties, type HTMLAttributes, type ReactNode, useCallback } from 'react';
import { useRouter } from 'next/compat/router';
import { cn } from '@virginexperiencedays/tailwind';

import { FeaturedProductCard } from '@virginexperiencedays/components-v2/src/cards/FeaturedProduct';
import { PromoBanner } from '@virginexperiencedays/components-v2/src/banners/Promo';

import { addToDataLayer } from '../../../tracking/gtm/utils';

import { getColumnWidth } from './utils/getColumnWidth';
import { getEmbedWidth } from './utils/getEmbedWidth';
import { BREAKPOINTS } from './types';
import { useIsProductCardsV2 } from '../ProductCard';

export type EmbedType = {
  type: string;
  props: any;
  pos: {
    rowPosition: string;
    column: { start: string; end: string };
    mobileRowPosition?: number;
  };
};
type EmbedProps = {
  embed: EmbedType;
  /**
   * isMinimal - applies standard view on product card if true
   */
  isMinimal: boolean;
  /**
   * Optional className - applies className on both FeaturedProduct and inlinePromo
   */
  className?: string;
};
export const GridEmbed = ({ embed, isMinimal, className }: EmbedProps) => {
  const { type, props, pos } = embed;

  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();

  const normalizedSlugs = Array.isArray(router?.query.slug)
    ? router?.query?.slug?.filter((slug) => slug !== 'plp' && slug !== 'page')
    : router?.query?.slug?.split('/');

  const lastSlug = normalizedSlugs ? normalizedSlugs[normalizedSlugs.length - 1] : '';

  const handleFeaturedTracking = useCallback(
    () =>
      addToDataLayer({
        event: 'userInteraction',
        eventCategory: 'Featured Product',
        eventAction: `Clicked Featured Product | ${lastSlug}`,
        eventLabel: props.title,
      }),
    [lastSlug, props.title]
  );

  const normalizedPosition = {
    row: parseInt(pos.rowPosition, 10) || 1,
    columnStart: parseInt(pos.column.start, 10),
    columnEnd: parseInt(pos.column.end, 10),
  };

  switch (type) {
    case 'FeaturedProduct': {
      return (
        <EmbedProduct
          style={
            {
              '--mobileRowPosition': pos.mobileRowPosition || 1,
            } as CSSProperties
          }
          className={cn(
            isProductCardsV2
              ? 'max-[575px]:col-span-full max-[575px]:row-start-[--mobileRowPosition] max-[575px]:row-end-auto max-[575px]:h-[40vh]'
              : 'max-[767px]:col-span-full max-[767px]:row-start-[--mobileRowPosition] max-[767px]:row-end-auto max-[767px]:h-[40vh]',
            className
          )}
          position={normalizedPosition}
          isMinimal={isMinimal}
          onClick={handleFeaturedTracking}
        >
          <FeaturedProductCard
            productId={props?.id}
            title={props.title}
            rrp={props.rrp}
            displayPrice={props.displayPrice}
            src={props.src}
            href={props.href}
            aspectRatio={props.aspectRatio}
            promocode={props.promocode}
            sku={props.sku}
            hasZoomOnHover={!isProductCardsV2}
          />
        </EmbedProduct>
      );
    }
    case 'InlinePromo':
      return (
        <EmbedProduct className={className} position={normalizedPosition} isMinimal={isMinimal}>
          <PromoBanner
            variation={props.variation}
            title={props.title}
            body={props.body}
            ctaText={props.ctaText}
            url={props.url}
            image={props.image}
            mobileImage={props.mobile_image}
            htmlOverride={props.htmlOverride}
          />
        </EmbedProduct>
      );
    default:
      return null;
  }
};

type EmbedProductProps = {
  children: ReactNode;
  isMinimal: boolean;
  position: {
    row: number;
    columnStart: number;
    columnEnd: number;
  };
  style?: CSSProperties;
} & HTMLAttributes<HTMLLIElement>;

const EmbedProduct = ({
  children,
  position,
  isMinimal,
  className,
  style,
  ...rest
}: EmbedProductProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const columnWidths = BREAKPOINTS.map(
    (breakpoint) => getColumnWidth(breakpoint, isMinimal, isProductCardsV2) as number
  );
  const [embedWidth, embedWidthLargeMobile, embedWidthTablet, embedWidthDesktop, embedWidthWide] =
    columnWidths.map((columnWidth) =>
      getEmbedWidth(position.columnStart, position.columnEnd, columnWidth)
    );

  return (
    <li
      style={
        {
          '--row': `${position.row} / auto`,
          '--ec': `${embedWidth.start} / ${embedWidth.end}`,
          '--ec-lm': `${embedWidthLargeMobile.start} / ${embedWidthLargeMobile.end}`,
          '--ec-tablet': `${embedWidthTablet.start} / ${embedWidthTablet.end}`,
          '--ec-desktop': `${embedWidthDesktop.start} / ${embedWidthDesktop.end}`,
          '--ec-wide': `${embedWidthWide.start} / ${embedWidthWide.end}`,
          ...style,
        } as CSSProperties
      }
      className={cn(
        'row-[--row]',
        'col-[--ec]',
        'sm:col-[--ec-lm]',
        'md:col-[--ec-tablet]',
        'lg:col-[--ec-desktop]',
        'xl:col-[--ec-wide]',
        className
      )}
      {...rest}
    >
      {children}
    </li>
  );
};
