import type { IconProps as Props } from './types';

export const IconCloseNavigation = ({
  className,
  width = '1em',
  height = '1em',
  fill = '#121826',
  ...rest
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.152 1.151a1.2 1.2 0 011.696 0L8 6.303l5.152-5.151a1.2 1.2 0 111.696 1.696L9.697 8l5.151 5.152a1.2 1.2 0 01-1.696 1.696L8 9.697l-5.152 5.151a1.2 1.2 0 01-1.696-1.697L6.303 8 1.152 2.848a1.2 1.2 0 010-1.696z"
        fill={fill}
      />
    </svg>
  );
};
