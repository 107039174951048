import type { MouseEvent } from 'react';

import {
  categoriesHierarchyAttributeArray,
  categoryTypes,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  occasionsHierarchyAttributeArray,
  refinementList,
} from '@virginexperiencedays/search/constants';
import type { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import { LocationWidget } from './LocationWidget';
import { MultiSelectWidget } from './MultiselectWidget';
import { SingleSelectWidget } from './SingleSelectWidget';
import type { Override } from '@virginexperiencedays/categories/src/types';

export const DynamicWidgetsFactory = ({
  categoryType,
  serverSlug,
  attribute,
  text,
  renderType,
  filterIndex,
  min = 5,
  className,
  onClick,
  attributeActiveCount,
  currentCategoryAttributes,
  overridesSlugInfo,
}: {
  categoryType: categoryTypes;
  serverSlug: string[];
  attribute: AlgoliaAttribute;
  text: string;
  renderType: 'pill' | 'list';
  filterIndex: number;
  min?: number;
  className?: string;
  onClick: (e?: MouseEvent<HTMLButtonElement>) => void;
  attributeActiveCount?: number;
  currentCategoryAttributes: AlgoliaAttribute[];
  overridesSlugInfo: Override['slugInfo'];
}) => {
  const sharedProps = {
    className,
    filterIndex,
    renderType,
    attribute,
    categoryType,
    serverSlug,
    label: text,
    min,
    overridesSlugInfo,
    onClick,
  };

  switch (attribute) {
    case categoriesHierarchyAttributeArray[0]: // falls through
    case categoriesHierarchyAttributeArray[1]: // falls through
    case categoriesHierarchyAttributeArray[2]:
      return <MultiSelectWidget {...sharedProps} widgetCategoryType={categoryTypes.CATEGORY} />;

    case occasionsHierarchyAttributeArray[0]: // falls through
    case occasionsHierarchyAttributeArray[1]: // falls through
    case occasionsHierarchyAttributeArray[2]:
      return <MultiSelectWidget {...sharedProps} widgetCategoryType={categoryTypes.OCCASION} />;

    case featuresHierarchyAttributeArray[0]: // falls through
    case featuresHierarchyAttributeArray[1]: // falls through
    case featuresHierarchyAttributeArray[2]:
      return <MultiSelectWidget {...sharedProps} widgetCategoryType={categoryTypes.FEATURE} />;

    case locationsHierarchyAttributeArray[0]: // falls through
    case locationsHierarchyAttributeArray[1]: // falls through
    case locationsHierarchyAttributeArray[2]:
      return (
        <LocationWidget
          currentCategoryAttributes={currentCategoryAttributes}
          attributeActiveCount={attributeActiveCount}
          {...sharedProps}
        />
      );

    case refinementList.PRICE_RANGE:
      return <MultiSelectWidget {...sharedProps} widgetCategoryType={categoryTypes.PRICE_RANGE} />;

    case refinementList.RATING:
      return (
        <SingleSelectWidget
          {...sharedProps}
          widgetCategoryType={categoryTypes.RATING}
          listItemIcon="star"
          showIcon
        />
      );

    default:
      return null;
  }
};
