import { IconStar } from '@virginexperiencedays/components-v2/src/icons/IconStar';

import { getReviewRangeNumber } from '@virginexperiencedays/components-v2/src/pages/search/filters/SingleSelectMenu/RatingOption';

export const RatingsFilterLabel = ({ value }: { value: string }) => {
  const rating = getReviewRangeNumber(value);
  return (
    <div className="flex items-center gap-1" data-testid="ratings-filter-label">
      <IconStar className="h-3 w-3 [&>path]:-translate-x-[3px] [&>path]:-translate-y-[3px] [&>path]:scale-[0.8] [&>path]:fill-[var(--color-persistent-white)]" />
      <span>{rating} Star</span>
      {rating < 5 && <span>& Up</span>}
    </div>
  );
};
