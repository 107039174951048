import { useCallback } from 'react';
import { useConnector, useSortBy } from 'react-instantsearch';
import { useRouter } from 'next/compat/router';

import { indexNames } from '@virginexperiencedays/search/constants';

import { displayOptions } from '@virginexperiencedays/components-v2/src/drawers/SortingDrawer/defaultOptionGroups';
import { SortingDrawer as Component } from '@virginexperiencedays/components-v2/src/drawers/SortingDrawer';

import { useRouterPush } from '../../../utils/routing/useRouterPush';
import { removeQueryStringFromPath } from '../../../utils/routing/paths';

import { getSortOptionsToRender } from '../../../libs/algolia/optionsToRender';
import useCardDisplay from './utils/hooks/useCardDisplay';
import { useIsProductCardsV2 } from './ProductCard';

import { mapIndexToRoute } from '../../../libs/algolia/sortMapping';
import { sortingOptions } from '../../../libs/algolia/constants/sortingOptions';
import { connectRadiusGeoSearch } from '@virginexperiencedays/search/src/hooks/useGeoSearch/connectRadiusGeoSearch';

type DrawerOption = {
  title: string;
  key: string;
  options: { label: string; value: string }[];
  checkedValue: string;
  getDisabled?: (value: string) => boolean;
};

export const SortingDrawer = ({
  categoryType,
  isSortingDrawerOpen,
  toggleSortingDrawer,
  onSubmit = null,
}) => {
  const { refine } = useRouterPush();
  const { options: defaultOptions, currentRefinement: currentSortRefinement } = useSortBy({
    items: sortingOptions,
  });
  const { currentRefinement: currentGeoSearchRefinement } = useConnector(
    connectRadiusGeoSearch as any
  );

  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  const { cardDisplay, setCardDisplay } = useCardDisplay(categoryType);

  const slug = removeQueryStringFromPath(router?.asPath, ' / ');
  const options = getSortOptionsToRender(slug, defaultOptions);

  const displayOption: DrawerOption = {
    title: 'Display',
    key: 'card_layout',
    options: displayOptions,
    checkedValue: cardDisplay ?? displayOptions[0].value,
  };

  const drawerOptions: DrawerOption[] = [
    {
      title: 'Sort',
      key: 'sortBy',
      // add virtual "Nearest" sorting option if geosearching
      options: currentGeoSearchRefinement
        ? options.map(({ label, value }) => ({
            value,
            label: value === indexNames.DEFAULT ? 'Nearest' : label,
          }))
        : options,
      checkedValue: currentSortRefinement ?? options[0].value,
      getDisabled(value) {
        // only disable options if a geosearch filter is applied
        if (!currentGeoSearchRefinement) return false;

        return value !== indexNames.DEFAULT;
      },
    },
  ];

  if (!isProductCardsV2) {
    drawerOptions.push(displayOption);
  }

  const clearGeoSearch = () => {
    refine({
      paramsToRemove: ['geoSearchFormatted', 'geoSearch'],
      shallow: true,
    });
  };

  const handleOnSubmit = useCallback(
    (value: { sortBy: string; card_layout: string; perPage: number }) => {
      const sort = mapIndexToRoute(String(value.sortBy));

      if (value.sortBy !== currentSortRefinement) {
        refine({
          query: {
            sort,
          },
        });
        // trigger analytics
        onSubmit?.(sort || 'most-popular');
      }

      // handle sort display
      setCardDisplay(value.card_layout);
      toggleSortingDrawer();
    },
    [refine, onSubmit]
  );

  return (
    <Component
      isOpen={isSortingDrawerOpen}
      toggleDrawer={toggleSortingDrawer}
      optionGroups={drawerOptions}
      showGeoSearchMessage={!!currentGeoSearchRefinement}
      onSubmit={handleOnSubmit}
      clearGeoSearch={clearGeoSearch}
    />
  );
};
