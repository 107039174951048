import type {
  ConstructorParams,
  IPromoService,
  ProductPromo,
  PromoConfig,
  ValidProduct,
} from '../types';

export class PromoService implements IPromoService {
  protected activePromos: string[] = [];
  protected promosConfig: Map<string, PromoConfig> = new Map();

  constructor({ activePromos, promosConfiguration }: ConstructorParams) {
    this.promosConfig = promosConfiguration;
    this.activePromos = this.formatActivePromos(activePromos);
  }

  /**
   *  Return the promo object for the product
   *
   * @param product
   * @returns
   */
  public getProductPromo(product: unknown): ProductPromo | null {
    if (!this.isValidProduct(product)) return null;

    const promosFromProduct = this.getCurrentPromosFromProduct(product);
    return this.generateProductPromo(promosFromProduct);
  }

  /**
   * Generate the ProductPromo values for the given promos array
   *
   * @param promosFromProduct - The promos array taken from the product object
   * @returns
   */
  protected generateProductPromo(promosFromProduct: string[]): ProductPromo | null {
    const currentPromoKey = this.getPriorityPromo(promosFromProduct);
    const currentPromoConfig = this.getPromoConfig(currentPromoKey);

    return currentPromoKey && currentPromoConfig
      ? { key: currentPromoKey, config: currentPromoConfig }
      : null;
  }

  /**
   * Get the current promos from the product object
   * * // ! I think this is ordering using the config, not the environment variable
   *
   * @param product
   * @returns
   */
  private getCurrentPromosFromProduct(product: ValidProduct): string[] {
    const currentPromos: string[] = [];

    for (const [key, config] of this.promosConfig.entries()) {
      const facetIdentifiers = [key, ...(config.facetIdentifiers ?? [])];
      const facetsArr = config.facetPaths.flatMap((facetPath) =>
        this.accessObjKeyByPath(product, facetPath)
      );

      if (!Array.isArray(facetsArr) || !facetsArr.length) continue;

      for (const identifier of facetIdentifiers) {
        if (facetsArr.includes(identifier)) {
          currentPromos.push(key);
        }
      }
    }

    return currentPromos;
  }

  /**
   *  Get the promo key with the highest priority from the activePromos array
   *
   * @param promosFromProduct - The promos array taken from the product object
   * @returns
   */
  private getPriorityPromo(promosFromProduct: string[]): string | null {
    let result: string | null = null;

    // Reverse a copy of the activePromos array to get the highest priority promo, i.e. lowest index
    [...this.activePromos].reverse().forEach((promo) => {
      if (promosFromProduct.includes(promo)) {
        result = promo;
      }
    });

    return result;
  }

  /**
   *  Get the promo configuration object by the promo key
   *
   * @param promo
   * @returns
   */
  private getPromoConfig(promo: string | null): PromoConfig | null {
    if (typeof promo !== 'string' || !this.promosConfig.has(promo)) return null;
    return this.promosConfig.get(promo) as PromoConfig;
  }

  /**
   *  Format the activePromos string or array into an array of strings
   *
   * @param activePromos
   * @returns
   */
  private formatActivePromos(activePromos: string[] | string): string[] {
    if (typeof activePromos === 'string') {
      return activePromos.split(',').map((promo) => promo.trim());
    } else if (Array.isArray(activePromos)) {
      return activePromos;
    } else {
      return [];
    }
  }

  /**
   * Access any nested object key by a dot-separated path string
   *
   * @param obj - The object to access
   * @param path - The dot-separated path to the key
   * @returns The value of the key if it exists, otherwise null
   * @example
   * const obj = { a: { b: { c: 'value' } } };
   * accessObjKeyByPath(obj, 'a.b.c'); // 'value'
   */
  private accessObjKeyByPath(obj: Record<string, unknown>, path: string): unknown | null {
    const keys = path.split('.');

    function recursiveAccess(currentObj: unknown, remainingKeys: string[]): unknown | null {
      if (!currentObj || typeof currentObj !== 'object') return null;

      const [firstKey, ...restKeys] = remainingKeys;
      if (!firstKey || !(firstKey in currentObj)) return null;

      const nextObj = (currentObj as Record<string, unknown>)[firstKey];
      if (restKeys.length === 0) return nextObj;

      return recursiveAccess(nextObj, restKeys);
    }

    return recursiveAccess(obj, keys);
  }

  /**
   * Check if the product is a valid product object based on the ValidProduct type
   *
   * @param product
   * @returns
   */
  private isValidProduct(product: unknown): product is ValidProduct {
    return (
      typeof product === 'object' &&
      product !== null &&
      'facets' in product &&
      typeof (product as any).facets === 'object' &&
      (product as any).facets !== null
    );
  }
}
