import { useRouter } from 'next/compat/router';
import { useCurrentRefinements, useMenu } from 'react-instantsearch';

import { Accordion } from '@virginexperiencedays/components-v2/src/layout/Accordion';
import { getReviewRangeNumber } from '@virginexperiencedays/components-v2/src/pages/search/filters/SingleSelectMenu/RatingOption';
import { refinementList } from '@virginexperiencedays/search/constants';

import { useRouterPush } from '../../../../utils/routing/useRouterPush';
import { removeQueryStringFromPath } from '../../../../utils/routing/paths';

import { RatingsFilter } from './RatingsFilter';

// Tracking
import { filter as track } from '../tracking/interactions';

export const RatingsWidget = () => {
  const { refine } = useRouterPush();
  const { items: currentRefinements } = useCurrentRefinements();

  const { items, sendEvent } = useMenu({
    attribute: refinementList.RATING,
    sortBy: ['name:desc'],
  });

  const router = useRouter();

  const slug = removeQueryStringFromPath(router?.asPath, ' / ');
  const filterApplied = !!currentRefinements.find(
    ({ attribute }) => attribute === refinementList.RATING
  );
  // Remove 1 & 2 star ratings
  const filteredFacetItems = items.filter(({ label }) => getReviewRangeNumber(label) > 2);

  const handleClick = (value: string) => {
    track({ slug, label: 'Add Rating' });
    sendEvent('click', value, `[INSIGHTS] Rating Facet Clicked`);

    refine({
      query: {
        rating: value,
      },
    });
  };

  if (filterApplied || !filteredFacetItems.length) return null;

  return (
    <Accordion heading="Rating" open dataTestId="rating-accordion">
      <RatingsFilter items={filteredFacetItems} onClick={handleClick} />
    </Accordion>
  );
};
