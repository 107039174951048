import { type ReactNode, useCallback } from 'react';
import {
  categoriesHierarchyAttributeArray,
  categoryTypes,
  featuresHierarchyAttributeArray,
  locationsHierarchyAttributeArray,
  occasionsHierarchyAttributeArray,
  refinementList,
} from '@virginexperiencedays/search/constants';
import { useMenu } from '../../../../../libs/algolia/hooks/useMenu';
import { useRefinementList } from '../../../../../libs/algolia/hooks/useRefinementList';
import { AlgoliaAttribute } from '../../../../../libs/algolia/stateToRoute';
import {
  filterItemLabels,
  sortItemLabels,
  transformLabels,
} from '../../../../../libs/algolia/transformLabels';

import { FilterPill } from '../FilterTriggers/FilterPill';
import type { Override } from '@virginexperiencedays/categories/src/types';

type FilterProps = {
  categoryType: categoryTypes;
  widgetCategoryType: categoryTypes;
  serverSlug: string[];
  attribute: AlgoliaAttribute;
  children: ReactNode;
  text: string;
  value: string;
  icon: ReactNode;
  hasArrow: boolean;
  activeCount: number;
  onMouseEnter: (attribute: AlgoliaAttribute) => void;
  onMouseLeave: (attribute: AlgoliaAttribute) => void;
  onClick: (attribute: AlgoliaAttribute) => void;
  overridesSlugInfo: Override['slugInfo'];
};

// This component is a wrapper around filter trigger & filter content, its built to prevent filter trigger from rendering if filter content has no items
// there are potential future improvements to build this component calling hooks less often -- question is if that's crticial? or if we're okay re-calling webhooks as that's what they're for.

export const Filter = ({ attribute, children, ...rest }: FilterProps) => {
  switch (attribute) {
    case categoriesHierarchyAttributeArray[0]: // falls through
    case categoriesHierarchyAttributeArray[1]: // falls through
    case categoriesHierarchyAttributeArray[2]:
      return (
        <FilterMultiselect
          attribute={attribute}
          {...rest}
          widgetCategoryType={categoryTypes.CATEGORY}
        >
          {children}
        </FilterMultiselect>
      );

    case occasionsHierarchyAttributeArray[0]: // falls through
    case occasionsHierarchyAttributeArray[1]: // falls through
    case occasionsHierarchyAttributeArray[2]:
      return (
        <FilterMultiselect
          attribute={attribute}
          {...rest}
          widgetCategoryType={categoryTypes.OCCASION}
        >
          {children}
        </FilterMultiselect>
      );

    case featuresHierarchyAttributeArray[0]: // falls through
    case featuresHierarchyAttributeArray[1]: // falls through
    case featuresHierarchyAttributeArray[2]:
      return (
        <FilterMultiselect
          attribute={attribute}
          {...rest}
          widgetCategoryType={categoryTypes.FEATURE}
        >
          {children}
        </FilterMultiselect>
      );

    case locationsHierarchyAttributeArray[0]: // falls through
    case locationsHierarchyAttributeArray[1]: // falls through
    case locationsHierarchyAttributeArray[2]:
      return (
        <FilterLocation attribute={attribute} {...rest}>
          {children}
        </FilterLocation>
      );

    case refinementList.PRICE_RANGE:
      return (
        <FilterMultiselect
          attribute={attribute}
          {...rest}
          widgetCategoryType={categoryTypes.PRICE_RANGE}
        >
          {children}
        </FilterMultiselect>
      );

    case refinementList.RATING:
      return (
        <FilterSingleselect
          attribute={attribute}
          {...rest}
          widgetCategoryType={categoryTypes.RATING}
        >
          {children}
        </FilterSingleselect>
      );

    default:
      throw new Error(`Unknown attribute: ${attribute}`);
  }
};

const FilterSingleselect = ({
  attribute,
  value,
  children,
  categoryType,
  widgetCategoryType,
  serverSlug,
  overridesSlugInfo,
  ...rest
}: FilterProps) => {
  const { items } = useMenu({
    attribute,

    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);

      if (categoryType !== widgetCategoryType) {
        if (widgetCategoryType === categoryTypes.RATING) {
          return transformLabels(sortedItems, attribute);
        }

        return items;
      }

      if (widgetCategoryType === categoryTypes.RATING) {
        return transformLabels(sortedItems, attribute);
      }

      const filtered = filterItemLabels(items, '', serverSlug, overridesSlugInfo);

      return transformLabels(filtered, attribute);
    }, []),
  });

  if (!items?.length) return null;

  return (
    <li className="relative" key={value}>
      <FilterPill attribute={attribute} {...rest}>
        {children}
      </FilterPill>
    </li>
  );
};

const FilterMultiselect = ({
  attribute,
  value,
  children,
  categoryType,
  widgetCategoryType,
  serverSlug,
  overridesSlugInfo,
  ...rest
}: FilterProps) => {
  const { items } = useRefinementList({
    attribute,

    transformItems: useCallback((items) => {
      const sortedItems = sortItemLabels(items, attribute);

      if (categoryType !== widgetCategoryType) {
        return transformLabels(sortedItems, attribute);
      }

      const filtered = filterItemLabels(sortedItems, attribute, serverSlug, overridesSlugInfo);
      return transformLabels(filtered, attribute);
    }, []),
  });

  if (!items?.length) return null;
  return (
    <li className="relative" key={value}>
      <FilterPill attribute={attribute} {...rest}>
        {children}
      </FilterPill>
    </li>
  );
};

const FilterLocation = ({ value, attribute, children, ...rest }: FilterProps) => {
  return (
    <li className="relative" key={value}>
      <FilterPill
        attribute={attribute}
        textClassName="dark:md:group-hover/pill:text-brand"
        {...rest}
      >
        {children}
      </FilterPill>
    </li>
  );
};
