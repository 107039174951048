import type { Override } from '@virginexperiencedays/categories/src/types';
import { categorySeparator, refinementList } from '@virginexperiencedays/search/constants';
import { slugToName } from '@virginexperiencedays/search/utils/category';

type TransformLabel = {
  label: string;
  value: string | number;
};

export const transformRatingLabel = <T extends TransformLabel>(item: T): T => {
  switch (item.value) {
    case '1-star':
      return { ...item, label: '1 Star & Up' };
    case '2-stars':
      return { ...item, label: '2 Stars & Up' };
    case '3-stars':
      return { ...item, label: '3 Stars & Up' };
    case '4-stars':
      return { ...item, label: '4 Stars & Up' };
    case '5-stars':
      return { ...item, label: '5 Stars' };
    default:
      return item;
  }
};

export const transformHierarchicalLabel = <T extends TransformLabel>(item: T): T => {
  return { ...item, label: slugToName(String(item.value).split(categorySeparator).pop()) };
};

export const transformPriceLabel = <T extends TransformLabel>(item: T): T => {
  switch (item.label) {
    case '0-50':
      return { ...item, label: 'Less than £50' };
    case '50-150':
      return { ...item, label: '£50 to £150' };
    case '150-250':
      return { ...item, label: '£150 to £250' };
    case '250-500':
      return { ...item, label: '£250 to £500' };
    case '500-plus':
      return { ...item, label: '£500+' };
    default:
      return item;
  }
};

export const transformLabels = <T extends TransformLabel>(items: T[], attribute: string): T[] => {
  switch (attribute) {
    case refinementList.RATING:
      return items.map(transformRatingLabel);
    case refinementList.PRICE_RANGE:
      return items.map(transformPriceLabel);
    default:
      return items.map(transformHierarchicalLabel);
  }
};

export const filterItemLabels = <T extends TransformLabel>(
  items: T[],
  attribute: string,
  serverSlug: string[],
  overridesSlugInfo: Override['slugInfo']
): T[] => {
  switch (attribute) {
    case refinementList.RATING: // falls through
      return items.filter(({ value }) => Number((value as string).split('-')[0]) > 2);
    case refinementList.PRICE_RANGE:
      return items.filter((item) => !!item.value);
    default:
      return items.filter((item) => {
        if (overridesSlugInfo?.length) {
          return overridesSlugInfo?.some(({ hierarchy }) =>
            String(item.value).startsWith(joinSlugHierarchy(hierarchy))
          );
        }

        return String(item.value).startsWith(joinSlugHierarchy(serverSlug));
      });
  }
};

export const sortItemLabels = <T extends TransformLabel>(items: T[], attribute: string): T[] => {
  switch (attribute) {
    case refinementList.RATING:
      return items.reverse();
    case refinementList.PRICE_RANGE: {
      const getRangeStart = (value: string) => parseInt(value.split('-')[0]) || 0;
      return items.sort((a, b) => {
        return getRangeStart(String(a.value)) - getRangeStart(String(b.value));
      });
    }
    default:
      return items;
  }
};

const joinSlugHierarchy = (hierarchy?: string[]) => {
  return hierarchy?.length <= 1
    ? `${hierarchy?.[0] || ''}${categorySeparator}`
    : hierarchy?.join(categorySeparator) || '';
};
