export const transformItems = (ranges) => {
  const sortedRanges = [];

  ranges.forEach((range) => {
    switch (range.label) {
      case '0-50':
        sortedRanges[0] = { ...range, label: 'Less than £50' };
        break;
      case '50-150':
        sortedRanges[1] = { ...range, label: '£50 to £150' };
        break;
      case '150-250':
        sortedRanges[2] = { ...range, label: '£150 to £250' };
        break;
      case '250-500':
        sortedRanges[3] = { ...range, label: '£250 to £500' };
        break;
      case '500+':
        sortedRanges[4] = { ...range, label: '£500+' };
        break;
      default:
        break;
    }
  });

  return sortedRanges.length ? sortedRanges.filter((range) => range != undefined) : ranges;
};
