import { IconPlus } from '@virginexperiencedays/components-v2/src/icons/IconPlus';
import { IconMinus } from '@virginexperiencedays/components-v2/src/icons/IconMinus';
import { BodyText } from '@virginexperiencedays/components-v2/src/typography/BodyText';

type ShowMoreButtonProps = {
  onClick: () => void;
  title: string;
  isShowMore?: boolean;
};

export const ShowMoreButton = ({ onClick, title, isShowMore = false }: ShowMoreButtonProps) => (
  <div
    className="mt-6 flex items-center gap-1 hover:cursor-pointer"
    onClick={onClick}
    data-testid="show-more"
  >
    <BodyText className="text-sm leading-none text-neutral underline">
      See {isShowMore ? 'less' : 'more'} {title}
    </BodyText>
    {isShowMore ? <IconMinus /> : <IconPlus />}
  </div>
);
