import { useState } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { Button } from '../../layout/Button';
import { Radio, type RadioOption } from '../../layout/Radio';
import { SideDrawer } from '../../layout/SideDrawer';
import { LinkWrap } from '../../navigation/LinkWrap';
import { VisualHeading } from '../../typography/VisualHeading';

import { defaultOptionGroups } from './defaultOptionGroups';

export type SortValues = {
  [key: string]: string | number;
};

export type DefaultOptionsProps = {
  title: string;
  key: string;
  options: RadioOption[];
  checkedValue: string | number;
  getDisabled?: (value: string | number) => boolean;
};

export type SortingDrawerProps = {
  className?: string;
  /**
   * list of sort option groups
   */
  optionGroups?: DefaultOptionsProps[];
  /**
   * callback method when Apply Sorting button is clicked
   */
  onSubmit: (value: SortValues) => void;
  /**
   * flag whether the drawer is open or close (`isOpen` of `useDrawer()`)
   */
  isOpen: boolean;
  /**
   * flag whether to show a message that sorting is disabled because a
   * geosearch filter is applied
   * @TODO: This should be generic prop, like showMessage
   */
  showGeoSearchMessage?: boolean;
  /**
   * method to toggle the drawer between open and close (`toggleDrawer` of `useDrawer()`)
   */
  toggleDrawer: () => void;
  /**
   * method to clear geosearch filter, displayed from the geosearch message
   */
  clearGeoSearch?: () => void;
};

export const SortingDrawer = ({
  className,
  onSubmit,
  isOpen,
  showGeoSearchMessage,
  toggleDrawer,
  clearGeoSearch,
  optionGroups = defaultOptionGroups,
}: SortingDrawerProps) => {
  const defaultValues = Object.fromEntries(
    optionGroups.map(({ key, checkedValue }) => [key, checkedValue])
  );
  const [sortValues, setSortValues] = useState<SortValues>(defaultValues);

  const onClearGeoSearch = (event) => {
    event.preventDefault();
    if (clearGeoSearch) clearGeoSearch();
  };

  return (
    <SideDrawer
      className={className}
      dataTestId="sorting-drawer"
      isOpen={isOpen}
      isNarrow
      hideDrawer={toggleDrawer}
      drawerTitle={() => (
        <VisualHeading
          as="h2"
          size="4"
          leading="snug"
          className={cn('text-[1.75rem] sm:text-[2.125rem]')}
        >
          Sort By
        </VisualHeading>
      )}
      headerAlign="sticky"
    >
      <div>
        {optionGroups.map(({ title, key, options, checkedValue, getDisabled }) => (
          <div
            key={key}
            className="border-border-neutral mt-4 border-t pt-6 first:mt-0 first:border-0 first:p-0"
          >
            <Radio
              title={title}
              options={options}
              checked={checkedValue}
              getDisabled={getDisabled}
              onChange={(value) =>
                setSortValues((prevValue) => ({
                  ...prevValue,
                  [key]: value,
                }))
              }
            />
            {showGeoSearchMessage && key === 'sortBy' && (
              <div className="text-neutral bg-background-neutral-faded mt-2 p-2 text-sm leading-tight">
                Your results are currently sorted by nearest as you&apos;re using a location search.
                To sort by another way, clear your location.{' '}
                <LinkWrap className="text-neutral" href="#" onClick={onClearGeoSearch}>
                  Clear now
                </LinkWrap>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="bg-background-page sticky bottom-0 py-6">
        <Button fullWidth onClick={() => onSubmit(sortValues)} size="large" variant="primary">
          Apply
        </Button>
      </div>
    </SideDrawer>
  );
};
