// Core
import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/compat/router';
// utils
import { removeQueryStringFromPath } from '../../../../../utils/routing/paths';
import { getStorageItem, setStorageItem } from '../../../../../utils/storage';
// search
import { getHierarchicalViews } from '../../ProductGrid/productGridView';
// hooks
import { useCardDisplayContext } from '../contexts/CardDisplayContext';

const useStorageCardDisplay = (categoryType) => {
  const router = useRouter();
  const slug = removeQueryStringFromPath(router?.asPath);
  const {
    cardDisplayState: { slugCardDisplayMap },
    dispatch,
  } = useCardDisplayContext();
  /**
   * Initialize the value of slugCardDisplayMap from session storage. This
   * should only be run if slugCardDisplayMap does not have any value, to avoid
   * clobbering existing state.
   */
  useEffect(() => {
    if (Object.keys(slugCardDisplayMap).length) return;
    const storageItem = getStorageItem('card_display', { sessionStorage: true });
    if (!storageItem) return;
    const updatedSlugCardDisplayMap = {
      ...slugCardDisplayMap,
      ...JSON.parse(storageItem),
    };

    dispatch({
      type: 'SET_SLUG_CARD_DISPLAY_MAP',
      payload: updatedSlugCardDisplayMap,
    });
  }, []);
  /**
   * Sets the cardDisplay value for the current slug. This is backed by session
   * storage.
   */
  const setCardDisplay = useCallback(
    (cardDisplay: string) => {
      // build new state, for setting and saving to storage
      // NOTE: router?.asPath is an important dependency, to make sure slug isn't stale
      const updatedSlugCardDisplayMap = { ...slugCardDisplayMap, [slug]: cardDisplay };
      dispatch({
        type: 'SET_SLUG_CARD_DISPLAY_MAP',
        payload: updatedSlugCardDisplayMap,
      });
      // save to storage only on set
      setStorageItem('card_display', JSON.stringify(updatedSlugCardDisplayMap), {
        sessionStorage: true,
      });
    },
    [router?.asPath, slugCardDisplayMap, dispatch]
  );
  /**
   * Prepare the cardDisplay value for the current page, for convenience.
   */
  const cardDisplay =
    slugCardDisplayMap?.[slug] ??
    getHierarchicalViews(categoryType).get(
      Array.isArray(router?.query?.slug) ? router.query.slug.length : 1
    );

  return {
    cardDisplay,
    setCardDisplay,
  };
};

export default useStorageCardDisplay;
