import { useHierarchicalMenu } from 'react-instantsearch';

import { categorySeparator, categoryTypes } from '@virginexperiencedays/search/constants';
import { findDeepest } from '@virginexperiencedays/search/utils/findDeepest';
import { slugToName } from '@virginexperiencedays/search/utils/category';
import type { RouteParams } from '@virginexperiencedays/search/types';

import { Accordion } from '@virginexperiencedays/components-v2/src/layout/Accordion';

import { HierarchicalMenu } from './HierarchicalMenu';

type HierarchicalMenuWidgetProps = {
  /**
   * title to display.
   */
  title: string;
  /**
   *  set HierarchyAttributeArray
   */
  attributes: string[];
  /**
   * Indicates if the title is dynamic.
   */
  isDynamicTitle?: boolean;
  /**
   * This property is used to highlight master category (such as driving/afternoon-tea, etc)
   * */
  isMaster?: boolean;
  /**
   * This property is used to distinguish whether the hierarchical menu should push value as query or slug
   * */
  isMain?: boolean;
  /**
   * Query string value for the given attributes property (used for non href facets, means isMain is true for that property to be empty)
   */
  open?: boolean;
  /**
   * This property is used to set whether the accordian should be open initially
   */
  queryParam?: keyof RouteParams;
  categoryType: categoryTypes;
  trackingLabel: string;
};
/**
 * This widget handles displaying hierarchical menu of main category (URLs) and facet hierarchical menu (non-href)
 */
export const HierarchicalMenuWidget = ({
  title,
  isDynamicTitle = false,
  attributes,
  isMain = false,
  isMaster,
  queryParam,
  categoryType,
  trackingLabel,
  open = true,
}: HierarchicalMenuWidgetProps) => {
  const { items, sendEvent } = useHierarchicalMenu({
    attributes,
    separator: categorySeparator,
    // Default list set to 5 on HierarchicalMenu
    limit: 99,
    sortBy: ['count:desc'],
  });
  const currentFacet = findDeepest(items);
  const isTopLevel = !items.some(({ isRefined }) => isRefined);
  const isData = !!currentFacet?.data?.length;

  if (!items?.length || (!isData && !isTopLevel)) return null;

  return (
    <Accordion
      heading={isDynamicTitle && isData ? slugToName(currentFacet.label) : title}
      open={open}
      dataTestId={`accordion-${queryParam ?? 'hierarchicalMenu'}`}
    >
      <HierarchicalMenu
        sendEvent={sendEvent}
        data={currentFacet?.data ?? items}
        categoryType={categoryType}
        isMaster={isMaster}
        isMain={isMain}
        title={isDynamicTitle && isData ? slugToName(currentFacet.label) : title}
        queryParam={queryParam}
        trackingLabel={trackingLabel}
      />
    </Accordion>
  );
};
